import React from 'react';

import likeIcon from'../images/like.svg';
import dislikeIcon from '../images/dislike.svg';
import irrelevantIcon from '../images/irrelevant.svg';
import unlockIcon from '../images/unlock.svg';
import referenceLogo from '../images/reference_logo.svg';


function AboutPage({setPageInfo, fullSize, userInfo}) {
    console.log('rendering about page')
    
    React.useEffect(() => {
        setPageInfo({header_text:null, show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    return (
        <div className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
            + userInfo.color_scheme.background_color_2 + ')'}}>
            <div className={'about-container'.concat(fullSize? '': ' about-container-compressed') }
            style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                <div className='about-summary-text'>
                    <div className='about-header'>Welcome to drop a take!</div>
                    Your <b>take</b> is any opinion or thought that you might have on a <b>subject</b>. 
                    Each day, a new subject becomes available for you to drop a take on. 
                    Once the new subject appears, the previous day’s subject will become unavailable–so make sure to submit before time runs out!
                </div>

                <div className='about-header'>How to Use</div>
                <div className='about-subheader'>
                Submitting a Take
                <br/>
                </div>
                <ul className='about-text'>
                    <li>Each take consists of a <b>rating out of 5 stars</b> and a small <b>blurb</b> about why you rated it as such.</li>
                    <li>Write as much or as little as you want. If you've written something, but you're not sure you want to submit it yet, you can save it using the <b>save</b> button and come back later.</li>
                    <li>If you're not sure what the subject is or want to learn more, hit the reference button 
                        &nbsp;<img src={referenceLogo} className='about-example-logo' alt='reference-logo'/>&nbsp;.</li>
                    <li>Once you submit your take on today's subject, it will become visible to everyone else who has dropped a take today. You’ll also be able to see and interact with their takes too!</li>
                    <li>You can always view everyone's takes from previous days.</li>
                </ul>

                <div className='about-subheader'>Droplets<br/></div>
                <ul className='about-text'>
                    <li>
                        Each time you submit a take you will get 1 droplet 
                        &nbsp;<img src={unlockIcon} className='about-example-logo' alt='unlock-logo'/>&nbsp;.
                    </li>
                    <li>
                        With 5 droplets, you can unlock a previous subject and drop a take on it!
                    </li>
                    <li>
                        Each user starts out with 25 droplets, so you can immediately go back and drop some takes on whichever past subjects you find most intriguing.
                    </li>
                </ul>

                <div className='about-subheader'>Voting, Commenting, and Following<br/>
                </div>
                <ul className='about-text'>
                    <li>
                        The fun doesn’t stop there—you can interact with other users and their takes in a few ways: voting, commenting, and following.
                    </li>
                    <li>
                        There are <b>vote</b> buttons on each take. You can vote whether its a <b>good</b> take 
                        &nbsp;<img src={likeIcon} className='about-like-dislike-logo' alt='like-logo'/>&nbsp;
                        or a <b>bad</b> take 
                        &nbsp;<img src={dislikeIcon} className='about-like-dislike-logo' alt='dislike-logo'/>&nbsp;.
                    </li>
                    <li>
                        Drop a <b>comment</b> on someone else’s take to get a discussion going, or simply articulate how much you love/hate their opinion.
                    </li>
                    <li>
                        If you want to stay updated on the takes of a certain user, press the <b>follow</b> button next to their username.
                    </li>
                    <li>
                        If you want to shout out a specific user on a take or comment, you can <b>mention</b> them by typing “@” and selectingtheir username.
                    </li>


                    <li>
                        If you feel that a take or a comment is spam, offensive, or unrelated to the subject, please press the irrelevant button
                        &nbsp;<img src={irrelevantIcon} className='about-example-logo' alt='irrelevant-logo'/>&nbsp;.
                    </li>
                </ul>

                {/*<div className='about-subheader'>
                    <b>Voting</b> <br/>
                </div>
                <ul className='about-text'>
                    <li>
                        There are "vote" buttons on each take. You can vote whether its a good take 
                        &nbsp;<img src={likeIcon} className='about-like-dislike-logo' alt='like-logo'/>&nbsp;
                        or a bad take 
                        &nbsp;<img src={dislikeIcon} className='about-like-dislike-logo' alt='dislike-logo'/>&nbsp;.
                    </li>
                    <li>
                    If a take is spam, offensive, or unrelated to the subject please use the irrelevant vote 
                    &nbsp;<img src={irrelevantIcon} className='about-example-logo' alt='irrelevant-logo'/>&nbsp;
                    and other people will be less likely to see that take.
                    </li>
</ul>*/}
                

                <div className='about-header'>Stats!</div>

                <div className='about-subheader'>Polarization
                <br/>
                </div>
                <ul className='about-text'>
                    <li>The polarization score (aka standard deviation) measures the variations between ratings of a subject.</li>
                    <li>If a subject has been given mostly 1 and 5 star ratings, its polarization score would be high.</li>
                    <li>If a subject’s ratings are clustered heavily in the 2-2.5 range, the polarization score would be much lower.</li>
                </ul>

                <div className='about-subheader'>Similarity Rating
                <br/>
                </div>
                <ul className='about-text'>
                    <li>The rating similarity (aka correlation coefficient) measures how similarly you and another user rate things. View this stat by navigating to another user’s profile.</li>
                    <li>If the rating similarity is <b>greater than zero</b>, that means when you rate a subject <b>highly</b>, they are also likely to rate it <b>highly</b>.</li>
                    <li>If the rating similarity is <b>less than zero</b>, that means when you rate a subject <b>highly</b>, they are likely to rate it <b>lowly</b> and vice versa.</li>
                </ul>
            </div>
        </div>
  
    ) 
}

export {AboutPage}