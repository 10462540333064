import React from "react";

import { PrivacyPolicy } from "../components/privacy_policy.jsx";

function PrivacyPolicyPage({setPageInfo, userInfo}) {
    React.useEffect(() => {
        setPageInfo({header_text:null, show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    return (
        <div 
            className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        >
            <PrivacyPolicy/>
        </div>
    ) 
}

export {PrivacyPolicyPage}