import React from 'react';

import TakeLogo from '../images/star-half.svg';

function ErrorPage({setPageInfo, userInfo}) {
    console.log('rendering error page')

    React.useEffect(() => {
        setPageInfo({header_text:'Error', show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    return (
        <div 
            className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        >
            <div className='error-container' 
            style={{backgroundColor: userInfo.color_scheme.main_accent}}>
                <img src={TakeLogo}  className='logo-error' alt='take_logo'/>
                <p>this page does not exist</p>
            </div>
        </div>
    ) 
}

export {ErrorPage}