import React from 'react';
import {MentionsInput, Mention} from 'react-mentions';
import {Link} from 'react-router-dom';

import {supabase} from './supabase_auth_initialize.js'

import likeIcon from'../images/like.svg';
import dislikeIcon from '../images/dislike.svg';
import irrelevantIcon from '../images/irrelevant.svg';
import likeCommentIcon from '../images/like-comment.svg';
import dislikeCommentIcon from '../images/dislike-comment.svg';
import starFull from '../images/star-full.svg';
import starEmpty from '../images/star-empty.svg';
import starHalf from '../images/star-half.svg';
import loadingIcon from '../images/loading-dark.svg';
import commentIcon from '../images/comment.svg';

function hexToRgb(hex) {
    var bigint = parseInt(hex, 16);
    var r = (bigint >> 16) & 255;
    var g = (bigint >> 8) & 255;
    var b = bigint & 255;

    return r + "," + g + "," + b;
}

const months = {'01':'January', '02':'February', '03':'March', '04':'April', '05':'May', '06':'June', 
    '07':'July', '08':'August', '09':'September', '10':'October', '11':'November', '12':'December'}

function ReviewStarsSrc({star, review_stars}) {
    if(review_stars >= star || review_stars === '') {
        return starFull
    } else if((star - review_stars) >= 1){
        return starEmpty
    } else {
        return starHalf
    }
}

//useful regex
const all_regex =  /(@\([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\))|(<br\/>)|(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const url_regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const mention_regex = /(@\([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\))/g
const display_mention_regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/

async function getUsername({word, setMentionUsernames, mentionUsernames}) {
    if (!Object.keys(mentionUsernames).includes(word)) {
        const {data:username_response, error} = await supabase.rpc('get_username', {req_user_id:word.match(display_mention_regex)[0]})
        if (error) {
            console.log(error)
        } else {
            mentionUsernames[word] = username_response
            setMentionUsernames({...mentionUsernames})
        }
    }
}
    

function StaticRatingStars({rating, fullSize=true}) {

    return([...Array(5).keys()].map(i => i + 1).map((star) => {
        return(<img 
            className={'subject-tile-entry-star'.concat((fullSize)? '' : ' subject-tile-entry-star-compressed')}
            src={ReviewStarsSrc({star:star, review_stars:rating})} 
            alt='star' 
            key={star}
        />)
    }))
}

function DateStringFormatted({submitted_date_str}) {
    const datesplit = submitted_date_str.split('-')
    const date_string = `${months[datesplit[1]]} ${parseInt(datesplit[2].split(' ')[0])}, ${datesplit[0]}`
    return( date_string )
}

function SubmittedDate({submitted_date_str}) {
    const submitted_date = new Date(submitted_date_str)
    const date_string = submitted_date.toLocaleString('en-US', {hour12:false}).replace(',', '')

    return (
        <div className='submitted-time'>{date_string.substring(0, 5) + date_string.substring(7, date_string.length - 3)}</div>
    )
}

function CommentSubmittedDate({date_str}) {
    const submitted_date = new Date(date_str)
    const date_string = submitted_date.toLocaleString('en-US', {hour12:false}).replace(',', '')
    
    return (date_string.substring(0, 5) + date_string.substring(7, date_string.length - 3))
}

function HiddenReview({review, updateFunc}) {
    
    async function undoIrrelevant() {
        const {data:vote_response, error:vote_error} = await supabase.rpc('vote_3', {req_vote:null, req_review_id:review.review_id})
        if (vote_error) {
            console.log(vote_error)
        } else if (vote_response === 'voted') {
            review.vote = null
            updateFunc(review)
        } else {
            console.log(vote_response)
        }
    }
        
    return(
        <div className='hidden-review'>
            <div>Reported as Spam/Offensive/Irrelevant</div>
            <div>You won't see this take again and others will be less likely to see it</div>
            <div onClick={undoIrrelevant}><b className='clickable'>Undo</b></div>
        </div>
    )
}

function VoteIcons({review, updateFunc, userInfo, fullSize, setHideReviewPopup=null, popup=false}) {
    
    //works fine without this but waits for mouse to move to rerender so it looks laggy
    //maybe theres a smarter way to do this but whatever
    const [voteChoice, setVoteChoice] = React.useState(review.vote)

    //hover stuff
    /*const [likeExplain, setLikeExplain] = React.useState(false);
    const [likeDelay, setLikeDelay] = React.useState(false);
    const [dislikeExplain, setDislikeExplain] = React.useState(false);
    const [dislikeDelay, setDislikeDelay] = React.useState(false);
    const [irrelevantExplain, setIrrelevantExplain] = React.useState(false);
    const [irrelevantDelay, setIrrelevantDelay] = React.useState(false);

    function LikeHover() {
        return(
            (likeExplain && likeDelay) ?
                <div className='explain-container'>
                    <div className='vote-icon-explain like-explain'>This is a good take</div>
                </div>
            :
                <div className='explain-container'></div>
        )
    }

    function DislikeHover() {
        return(
            (dislikeExplain && dislikeDelay) ?
                <div className='explain-container'>
                    <div className='vote-icon-explain dislike-explain'>This is a bad take</div>
                </div>
            :
                <div className='explain-container'></div>
        )
    }

    function IrrelevantHover() {
        return(
            (irrelevantExplain && irrelevantDelay) ?
                <div className='explain-container'>
                    <div className='vote-icon-explain irrelevant-explain'>This is unrelated to the topic, spam, or offensive</div>
                </div>
            :
                <div className='explain-container'></div>
        )
    }*/
    
    async function vote(vote_choice) {
        const {data:vote_response, error:vote_error} = await supabase.rpc('vote_3', {req_vote:vote_choice, req_review_id:review.review_id})
        if (vote_error) {
            console.log(vote_error)
        } else if (vote_response === 'voted') {
            console.log('voted'.concat(vote_choice))
            if (review.vote === 'like') {
                review.like_count = (review.like_count - 1)
                updateFunc({...review, })
            } else if (review.vote === 'dislike') {
                review.dislike_count = (review.dislike_count - 1)
            } 
            
            if (vote_choice === 'like') {
                review.like_count = (review.like_count + 1)
            } else if (vote_choice === 'dislike') {
                review.dislike_count = (review.dislike_count + 1)
            } 

            setVoteChoice(vote_choice)
            review.vote = vote_choice
            updateFunc(review)
        } else {
            console.log(vote_response)
        }
    }
    
    return(

        <div className={'vote-icons'}>          
            {/*<div>
                <LikeHover/>
                <DislikeHover/>
                <IrrelevantHover/>
            </div>*/} 
            <div className={'like-group counter-group'.concat((review.vote === 'like') ? ' liked-group' : '')}
                onClick={() => {
                    if (userInfo.username) {
                        vote((review.vote === 'like') ? null : 'like')
                    }
                }}
                /*onMouseEnter={() => {
                    setLikeExplain(false)
                    setLikeDelay(true);
                    setTimeout(() => { setLikeExplain(true)  }, 1500);
                }} 
                onMouseLeave={() => {
                    setLikeDelay(false);
                    setLikeExplain(false)
                }}*/>
                <b className={'vote-counter'.concat((((!fullSize) && popup)? ' vote-counter-compressed' : ''))}>+{review.like_count}</b>
                <img className={'like-dislike-icon'.concat((fullSize) ? '' : ' like-dislike-icon-compressed')} src={likeIcon}   alt='like-icon'/>  
            </div>   

            <div className={'dislike-group counter-group'.concat((review.vote === 'dislike') ? ' disliked-group' : '')}
                onClick={() => {
                    if (userInfo.username) {
                        vote((review.vote === 'dislike') ? null : 'dislike')
                    }
                }}
                /*onMouseEnter={() => {
                    setDislikeExplain(false);
                    setDislikeDelay(true);
                    setTimeout(() => {setDislikeExplain(true)}, 1500);
                }} 
                onMouseLeave={() => {
                    setDislikeDelay(false);
                    setDislikeExplain(false);
                }}*/>
                <b className={'vote-counter'.concat((((!fullSize) && popup)? ' vote-counter-compressed' : ''))}>-{review.dislike_count}</b>
                <img className={'like-dislike-icon'.concat((fullSize) ? '' : ' like-dislike-icon-compressed')}
                    src={dislikeIcon}   alt='dislike-icon'/>   
            </div>   

            
            <div className={'irrelevant-group counter-group'.concat((review.vote === 'irrelevant') ? ' irrelevanted-group' : '')}
                onClick={() => {
                    if (setHideReviewPopup) {
                        setHideReviewPopup(true)
                    } else {
                        vote((review.vote === 'irrelevant') ? null : 'irrelevant')} 
                    }
                }
                /*onMouseEnter={() => {
                    setIrrelevantExplain(false);
                    setIrrelevantDelay(true);
                    setTimeout(() => { setIrrelevantExplain(true)  }, 1500);
                }} 
                onMouseLeave={() => {
                    setIrrelevantDelay(false);
                    setIrrelevantExplain(false);
                }}*/>
                <img 
                    className={'irrelevant-icon'.concat((fullSize) ? '' : ' irrelevant-icon-compressed')}
                    src={irrelevantIcon}  
                    alt='irrelevant-icon'
                />  
            </div>  
              
        </div>
    )
}

function SubmittedReviewText({input_text, fullSize, userInfo, mentionUsernames, allSubjects, setMentionUsernames, subject=null}) {
    //get whether there is a url or a mention
    const url_matches = input_text.match(url_regex)? input_text.match(url_regex) : []
    const mention_matches = input_text.match(mention_regex)

    //break apart text and 
    var review_words = []
    
    input_text.split(all_regex).forEach((word) => {
        if (url_matches.includes(word)) {
            review_words.push({word:word, type:'url'})
        } else if (mention_matches && mention_matches.includes(word)) {
            getUsername({
                word:word, 
                setMentionUsernames:setMentionUsernames, 
                mentionUsernames:mentionUsernames
            })
            review_words.push({word:word, type:'mention'})
        } else if (word === '<br/>') {
            review_words.push({word:word, type:'line-break'})
        } else if (word) {
            review_words.push({word:word})
        }
    })

    if (allSubjects) {
        //loop through titles
        allSubjects.subjects_order.forEach((title) => {
            const temp_review_words = []
            review_words.forEach((review_word) => {

                //see if there are any matches for the title
                const matches = review_word.word.match(new RegExp("(\\s|^)" + title +  "(\\s|$)", 'gi'))

                // if there are matches and it isn't already a url or mention split and add
                if (!review_word.type && matches && (!subject || title !== subject)) {
                    console.log(title)
                    //add words and if they are the the title add that info
                    review_word.word.split(new RegExp("(\\s|^)(" + title +  ")(\\s|$)", 'gi')).forEach((word) => {
                        if (word.toLowerCase() === title.toLowerCase()) {
                            temp_review_words.push({word:word, type:'title', subject_url:allSubjects.subjects[title].url_name})
                        } else {
                            temp_review_words.push({word:word})
                        }
                    })
                //if nothing just add the regular word
                } else {
                    temp_review_words.push(review_word)
                }
            })
            review_words = temp_review_words
        })
    }

    return (
        <p className={'submitted-review-text'.concat((fullSize)? '' : ' submitted-review-text-compressed')}>
            {review_words.map((word, index) => {
                if (word.type === 'url') {
                    return(<Link className='link' key={index} to={word.word}><u>{String.raw`${word.word}`}</u></Link>)
                } else if (word.type === 'line-break') {
                    return(<br key={index}/>)
                } else if (mention_matches && word.type === 'mention') {
                    if (mentionUsernames[word.word]) {
                        return(<Link className='link' key={index} to={'/user/'+mentionUsernames[word.word]}><b>
                            @{String.raw`${mentionUsernames[word.word]}`}
                        </b></Link>)
                    } else {
                        return(null)
                    }  
                } else if (word.type === 'title') {
                    return(
                        <Link 
                            style={{color:userInfo.color_scheme.base_color}} 
                            className='review-title-link' 
                            key={index} 
                            to={'/subject/'+word.subject_url}
                        >
                            <b>{String.raw`${word.word}`}</b>
                        </Link>
                    )
                }
                else if (word.word) {
                    return( String.raw`${word.word}` )
                } else {
                    return(null)
                }
            })}
        </p>   
    )
}

async function hideReview({review, waiting, setWaiting, setHideReviewPopup, updateFunc}) {
    if (!waiting) {
        setWaiting(true)

        const {error} = await supabase.rpc('hide_review', {req_review_id:review.review_id, req_value: !review.hidden_by_user})
        if (error) {
            console.log(error)
        } else {
            review.hidden_by_user = !review.hidden_by_user
            updateFunc(review)
            setHideReviewPopup(false)
            setWaiting(false)
        }
    }
}

function HideReviewPopup({ userInfo, review, updateFunc, setHideReviewPopup}) {
    const [waiting, setWaiting] = React.useState(false)

    return(
        <div className='popup-overlay' onClick={(event) => {
        if (event.target !== event.currentTarget) {
            return false;
        }
        setHideReviewPopup(false)
        }}>
            <div className='unlock-prompt' style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                {(review.hidden_by_user)?
                    <p>Unhide your take?</p>
                :
                    <p>Hide your take?</p>
                }
                <div 
                    className='header-sign-out-button clickable'
                    onClick={() =>{
                        hideReview({
                            review:review, 
                            waiting:waiting, 
                            setWaiting:setWaiting, 
                            setHideReviewPopup:setHideReviewPopup, 
                            updateFunc:updateFunc})
                    }}
                    style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                >
                    {waiting? <img src={loadingIcon} className='loading-icon' alt='loading-icon'/> : null}
                    {(review.hidden_by_user)?
                        <b>Unhide</b>
                    :
                        <b>Hide</b>
                    }
                </div>
            </div>     
        </div> 
    )
}

function SubmittedReview({review, allSubjects, screen_width_to_compress='950', updateFunc, userInfo, subject=null, popup=false}) {
    const [fullSize, setFullSize] = React.useState(window.matchMedia(`(min-width: ${screen_width_to_compress}px)`).matches)
    const [mentionUsernames, setMentionUsernames] = React.useState({})
    const [hideReviewPopup, setHideReviewPopup] = React.useState(false)

    //when viewport is smaller than 1000px get rid of right side banner
    const matches = window.matchMedia(`(min-width: ${screen_width_to_compress}px)`)
    matches.addEventListener('change', (e) => {
        setFullSize(e.matches)
    })

    return(
        <div 
            className={'submitted-review'.concat((fullSize)? '' : ' submitted-review-compressed')} 
            style={{backgroundColor:userInfo.color_scheme.main_accent}}
        > 
            {review.hidden_by_user? 
                <div 
                    style={{backgroundColor:userInfo.color_scheme.base_color}} 
                    className='hidden-tag'>Hidden: Other users can't see this take
            </div> 
            : null}
            {(fullSize) ? // when the width of the screen shrinks, stack stars and votes with votes on top
                <div className='review-top-row'>
                    <div className='subject-tile-entry-stars'>
                        <StaticRatingStars rating={review.rating}/>
                    </div>
                    <VoteIcons 
                        userInfo={userInfo} 
                        review={review} 
                        fullSize={fullSize} 
                        updateFunc={updateFunc} 
                        popup={popup}
                        setHideReviewPopup={(review.following === 'same' || userInfo.username === review.username) ? setHideReviewPopup : null}
                    />
                </div>
            :
                <div className='review-top-row'>
                    <div className='subject-tile-entry-stars subject-tile-entry-stars-compressed'>
                        <StaticRatingStars rating={review.rating} fullSize={false}/>
                    </div>
                    <VoteIcons 
                        userInfo={userInfo} 
                        review={review} 
                        updateFunc={updateFunc} 
                        fullSize={fullSize} 
                        setHideReviewPopup={(review.following === 'same' || userInfo.username === review.username) ? setHideReviewPopup : null}
                        popup={popup}/>
                </div>
            }
            <SubmittedReviewText 
                fullSize={fullSize} 
                input_text={review.review_text}
                mentionUsernames={mentionUsernames}
                setMentionUsernames={setMentionUsernames}
                allSubjects={allSubjects}
                userInfo={userInfo}
                subject={subject}
            />
            <SubmittedDate submitted_date_str={review.submitted_at}/>
            {hideReviewPopup?
                <HideReviewPopup userInfo={userInfo} review={review} updateFunc={updateFunc} setHideReviewPopup={setHideReviewPopup}/>
            :
                null
            }
        </div>
    )
}

function setReviewStarsFromButton(star, review_info, setStateFunc, direct=false) {
    const star_difference = star - review_info.rating;
    //if it shows a full star when clicked change to half otherwise alway set to full
    if (direct  || star_difference === 0.5) {
        review_info.rating = Math.max(star, 0.5)
        setStateFunc(review_info)
    } else {
        review_info.rating = Math.max(star - 0.5, 0.5)
        setStateFunc(review_info)
    }
}

function ClickableStars({review_info, userInfo, setStateFunc, setPageInfo}) {
    const [showingStars, setShowingStars] = React.useState(null)
    const [clickStart, setClickStart] = React.useState(null)
    const starsRef = React.useRef(null);

    return(
        <div 
            ref = {starsRef}
            className={'subject-tile-entry-stars subject-tile-entry-stars-not-submitted'}
            style={{backgroundColor:userInfo.color_scheme.main_accent}}
            onPointerUp={ () => {
                if (showingStars) { 
                    if (showingStars === clickStart && showingStars === review_info.rating) {
                        //alert('should change --- showingStars ' + String(showingStars) + '   clickStart: ' + String(clickStart))
                        setReviewStarsFromButton(showingStars, review_info, setStateFunc) 
                    } else {
                        //alert('shouldnt change --- showingStars ' + String(showingStars) + '   clickStart: ' + String(clickStart))
                        setReviewStarsFromButton(showingStars, review_info, setStateFunc, true) 
                    }
                    setShowingStars(null)
                    setPageInfo((init_info) => ({...init_info, allowSwipe:true}))
                    setClickStart(null)
                }
            }}        
            onPointerMove = {(e) => {
                //calculate number of stars based on distance from start of stars
                if (showingStars) {
                    setShowingStars(Math.min(Math.max(0.5, Math.ceil((e.screenX - starsRef.current.getBoundingClientRect().x) / 15) / 2), 5))
                    setPageInfo((init_info) => ({...init_info, allowSwipe:false}))
                }
                //setCoord({ x: e.screenX, y: e.screenY });
            }}
        >    
            {showingStars? 
                <div className='stars-popup-overlay'/>
            :
                null
            }     
            <div className='stars-container'>
                {[...Array(5).keys()].map(i => i + 1).map((star) => {
                    return(
                        <div className='star-container' key={star}>
                            <img 
                                className={
                                    (review_info.rating !== '' || showingStars) ? 
                                        'subject-tile-entry-star clickable' 
                                    :
                                        'subject-tile-entry-star clickable unrated-review'
                                }
                                src={ReviewStarsSrc({star:star, review_stars: showingStars? showingStars : review_info.rating})} 
                                alt='star'   
                            />
                            <div className='star-cover'>
                                <div 
                                    className='star-half-cover'
                                    onPointerDown = {(e) => {
                                        e.target.releasePointerCapture(e.pointerId)
                                        setShowingStars(star-0.5)
                                        setClickStart(star-0.5)
                                        setPageInfo((init_info) => ({...init_info, allowSwipe:false}))
                                    }}
                                    /*onPointerMove = {(e) => {
                                        if (showingStars) {
                                            setShowingStars(star-0.5)
                                            setPageInfo((init_info) => ({...init_info, allowSwipe:false}))
                                        }
                                    }}*/
                                    
                                ></div>
                                <div 
                                    className='star-half-cover'
                                    onPointerDown = {(e) => {
                                        e.target.releasePointerCapture(e.pointerId)
                                        setShowingStars(star)
                                        setPageInfo((init_info) => ({...init_info, allowSwipe:false}))
                                        setClickStart(star)
                                    }}
                                    /*onPointerMove = {(e) => {
                                        if (showingStars) {
                                            setShowingStars(star)
                                            setPageInfo((init_info) => ({...init_info, allowSwipe:false}))
                                        }
                                    }}*/
                                ></div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <b className={(review_info.rating==='') ? 'review-star-counter unrated-review' : 'review-star-counter'}>
                {review_info.rating}
            </b>
        </div>
    ) 
}

async function deleteComment({comment_id, waiting, setWaiting, downloadComments}) {
    if (!waiting) {
        setWaiting(true)
        console.log(comment_id)

        const {error} = await supabase.rpc('delete_comment', {req_comment_id:comment_id})
        if (error) {
            console.log(error)
        } else {
            downloadComments()
            setWaiting(false)
        }
    }
}

function DeleteCommentPopup({comment, userInfo, downloadComments, setDeletePopup}) {
    const [waiting, setWaiting] = React.useState(false)

    return(
        <div className='popup-overlay' onClick={(event) => {
        if (event.target !== event.currentTarget) {
            return false;
        }
        setDeletePopup(false)
        }}>
            <div className='unlock-prompt' style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                <p>Delete this comment?</p>
                <div 
                    className='header-sign-out-button clickable'
                    onClick={() =>{
                        //function to delete comment
                        deleteComment({
                            comment_id:comment.comment_id, 
                            waiting:waiting, 
                            setWaiting:setWaiting, 
                            downloadComments:downloadComments
                        })
                        setDeletePopup(false)
                    }}
                    style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                >
                    {waiting? <img src={loadingIcon} className='loading-icon' alt='loading-icon'/> : null}
                    <b>Delete</b>
                </div>
            </div>     
        </div> 
    )
}

function Comment({comment, light_text, userInfo, updateFunc, fullSize, allSubjects, subject, mentionUsernames, setMentionUsernames, downloadComments}) {
    const [voteChoice, setVoteChoice] = React.useState(comment.vote)
    const [deletePopup, setDeletePopup] = React.useState(false)
    const input_text = comment.comment_text

    /*async function reportToggle() {
        const { data:report_response, error:report_error } = await supabase.rpc('toggle_report_comment', {req_comment_id:comment.comment_id})
        if (report_error) {
            console.log(report_error)
        } else {
            comment.reported = (report_response==='reported') ? true : null
            setReported((report_response==='reported') ? true : null)
            updateFunc(comment)
        }
    }*/
    
     //get whether there is a url or a mention
     const url_matches = input_text.match(url_regex)? input_text.match(url_regex) : []
     const mention_matches = input_text.match(mention_regex)
 
     //break apart text and 
     var review_words = []
     
     input_text.split(all_regex).forEach((word) => {
         if (url_matches.includes(word)) {
            review_words.push({word:word, type:'url'})
         } else if (mention_matches && mention_matches.includes(word)) {
            getUsername({
                word:word, 
                setMentionUsernames:setMentionUsernames, 
                mentionUsernames:mentionUsernames
            })
            review_words.push({word:word, type:'mention'})
         } else if (word === '<br/>')
            review_words.push({word:word, type:'line-break'})
         else if (word) {
            review_words.push({word:word})
         }
    })
    
    if (allSubjects) {
        //loop through titles
        allSubjects.subjects_order.forEach((title) => {
            const temp_review_words = []
            review_words.forEach((review_word) => {

                //see if there are any matches for the title
                const matches = review_word.word.match(new RegExp("(\\s|^)(" + title +  ")(\\s|$)", 'gi'))

                // if there are matches and it isn't already a url or mention split and add
                if (!review_word.type && matches && (!subject || title !== subject)) {
                    console.log(title)
                    //add words and if they are the the title add that info
                    review_word.word.split(new RegExp("(\\s|^)(" + title +  ")(\\s|$)", 'gi')).forEach((word) => {
                        if (word.toLowerCase() === title.toLowerCase()) {
                            temp_review_words.push({word:word, type:'title', subject_url:allSubjects.subjects[title].url_name})
                        } else {
                            temp_review_words.push({word:word})
                        }
                    })
                //if nothing just add the regular word
                } else {
                    temp_review_words.push(review_word)
                }
            })
            review_words = temp_review_words
        })
    }

    return(
        <div>
            {voteChoice === 'irrelevant'?
                <div className='hidden-comment'>
                    <div>Reported as Spam/Offensive/Irrelevant</div>
                    <div onClick={() => voteComment(comment, null, updateFunc, setVoteChoice)}><u><b className='clickable'>Undo</b></u></div>
                </div>
            :
                <div className={'comment'.concat(fullSize? '' : ' comment-compressed')}>
                    {(deletePopup)? 
                        <DeleteCommentPopup 
                            downloadComments={downloadComments} 
                            userInfo={userInfo} 
                            setDeletePopup={setDeletePopup} 
                            comment={comment}
                        /> 
                    : null}
                    <div className='comment-info' >
                        <div className='commenter-name'><Link className={light_text ? 'light-link' : 'link'}
                            to={`/user/${comment.username}`}>{comment.username}</Link></div>
                            <div className='comment-vote-icons' style={{
                                backgroundColor: light_text? 'rgba(' + hexToRgb(userInfo.color_scheme.main_accent.replace('#', '')) + ', 0.2)' : userInfo.color_scheme.main_accent, 
                            }}>
                                <div 
                                    className={'comment-counter-group '.concat(
                                        comment.vote === 'like' && light_text? 'comment-liked-group' 
                                        :comment.vote === 'like'? 'liked-group'
                                        :light_text? 'image-to-white'
                                        : ''
                                    )}
                                    onClick={() => voteComment(comment, (comment.vote === 'like') ? null : 'like', updateFunc, setVoteChoice)}
                                >
                                    <div className='comment-vote-counter'><b>+{comment.like_count}</b></div>
                                    <img 
                                        className='like-dislike-icon-comment'
                                        src={likeCommentIcon}  
                                        alt='like-icon'
                                    /> 
                                </div>
                                <div 
                                    className={'comment-counter-group '.concat(
                                        comment.vote === 'dislike' && light_text? 'comment-disliked-group' 
                                        :comment.vote === 'dislike'? 'disliked-group'
                                        :light_text? 'image-to-white'
                                        : ''
                                    )}
                                    onClick={() => voteComment(comment, (comment.vote === 'dislike') ? null : 'dislike', updateFunc, setVoteChoice)} 
                                >
                                    <div className='comment-vote-counter'><b>-{comment.dislike_count}</b></div>
                                    <img 
                                        className='like-dislike-icon-comment'
                                        src={dislikeCommentIcon}  
                                        alt='dislike-icon'
                                    /> 
                                </div>
                                <img 
                                    className={'irrelevant-icon-comment'.concat(light_text? ' image-to-white' : '')}
                                    src={irrelevantIcon}  
                                    onClick={(userInfo.username === comment.username) ?
                                        () => setDeletePopup(true)
                                    :
                                        () => voteComment(comment, 'irrelevant', updateFunc, setVoteChoice)
                                    } 
                                    alt='irrelevant-icon'
                                /> 
                            </div>
                            <div className='commented-date'>{CommentSubmittedDate({date_str:comment.created_at})}</div>
                    </div>

                    <div className='comment-text'>
                        {review_words.map((word, index) => {
                            if (word.type === 'url') {
                                return(<Link className={light_text ? 'light-link' : 'link'} key={index} to={word.word}><u>{String.raw`${word.word}`}</u></Link>)
                            } else if (word.type === 'line-break') {
                                return(<br key={index}/>)
                            } else if (mention_matches && word.type === 'mention') {
                                if (mentionUsernames[word.word]) {
                                    return(<Link className={light_text ? 'light-link' : 'link'} key={index} to={'/user/'+mentionUsernames[word.word]}><b>
                                        @{String.raw`${mentionUsernames[word.word]}`}
                                    </b></Link>)
                                } else {
                                    return(null)
                                }  
                            } else if (word.type === 'title') {
                                return(
                                    <Link 
                                        style={{color:userInfo.color_scheme.main_accent}} 
                                        className='review-title-link' 
                                        key={index} 
                                        to={'/subject/'+word.subject_url}
                                    >
                                        <b>{String.raw`${word.word}`}</b>
                                    </Link>
                                )
                            }
                            else if (word.word) {
                                return( String.raw`${word.word}` )
                            } else {
                                return(null)
                            }
                        })}
                    </div>
                </div>
            }
        </div>
    )
}

async function downloadComments({review, setCommentList, setShowComments, updateFunc}) {
    console.log('downloading comments')

    const {data:comments_data, error:comments_error} = await supabase.rpc('get_comments', {req_review_id:review.review_id})
    if (comments_error) {
        console.log(comments_error)
    } else {
        review.comments_list = comments_data
        review.comments_list.sort((a,b) => {
            if (a.created_at > b.created_at)
                return(1);
            else {
                return(-1);
            }
        })
        review.comment_count = comments_data.length
        setCommentList(review.comments_list)
        setShowComments(true)
        updateFunc(review)
    }
}

async function getSuggestedMentions(query, callback) {
    if (!query) return;
    const {data:suggestions, error:suggestions_error} = await supabase.rpc('get_suggested_mentions', {req_text:query})
    if (suggestions_error) {
        console.log(suggestions_error)
    } else {
        callback(suggestions)
    }
};

function cleanSubmissionWithMentions(input_text) {
    const clean_submission_regex = /(@\[[0-9a-zA-Z_]*\]\([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\))/g
    const mention_matches = input_text.match(clean_submission_regex)
    const review_words = input_text.split(clean_submission_regex)

    if (mention_matches) {
        review_words.forEach((word, index) => {
            if (mention_matches.includes(word)) {
                review_words[index] = word.replace(/(\[[a-zA-Z0-9_]*\])/, '')
            }
        })
    } 
    return(review_words.join(''))
}

async function voteComment(comment, req_vote, updateFunc, setVoteChoice) {
    const {data:vote_response, error:vote_error} = await supabase.rpc('vote_comment', {req_vote:req_vote, req_comment_id:comment.comment_id})
    if (vote_error) {
        console.log(vote_error)
    } else if (vote_response === 'voted') {
        console.log('voted '.concat(req_vote))

        //change counts based on removal of previous vote
        if (comment.vote === 'like') {
            comment.like_count = (comment.like_count - 1)
        } else if (comment.vote === 'dislike') {
            comment.dislike_count = (comment.dislike_count - 1)
        } 
        
        //change counts based on addition of current vote
        if (req_vote === 'like' && comment.vote !== 'like') {
            comment.like_count = (comment.like_count + 1)
        } else if (req_vote === 'dislike' && comment.vote !== 'dislike') {
            comment.dislike_count = (comment.dislike_count + 1)
        } 

        comment.vote = (req_vote === comment.vote)? null : req_vote
        setVoteChoice(comment.vote)
        updateFunc(comment)
    } else {
        console.log(vote_response)
    }
}

function CommentSection({review, userInfo, updateFunc, allSubjects, subject=null, light_text=true}) {
    const [showComments, setShowComments] = React.useState(review.show_comments)
    const [showAddComment, setShowAddComment] = React.useState(review.show_add_comment)
    const [commentText, setCommentText] = React.useState('')
    const [commentList, setCommentList] = React.useState(review.comments_list)
    const [waiting, setWaiting] = React.useState(false)
    const [mentionUsernames, setMentionUsernames] = React.useState({})

    const [fullSize, setFullSize] = React.useState(window.matchMedia(`(min-width: 1000px)`).matches)
    const matches = window.matchMedia(`(min-width: 1000px)`)
    matches.addEventListener('change', (e) => {
        setFullSize(e.matches)
    })

    React.useEffect(() => {
        setCommentList(review.comments_list)
    }, [review])

    function updateComment(comment) {
        review.comments_list.forEach((list_comment, index) => {
            if (list_comment.comment_id === comment.comment_id) {
                review.comments_list[index] = comment
            }
        })
        updateFunc(review)
    }
   
    async function submitComment({e, userInfo, waiting, setWaiting}) {
        e.preventDefault() // used to prevent page refresh on submit
        if (!waiting) {
            setWaiting(true)

            //checking if there are mentions and cleaning it up a little bit
            const output_text = cleanSubmissionWithMentions(commentText).replace(/\n/g, '<br/>')

            const {data:comment_data, error:submit_info_error } = await supabase.rpc('submit_comment_3', {
                req_review_id:review.review_id, 
                req_comment_text: output_text
            })
            if (submit_info_error || comment_data === 0) {
                console.log(submit_info_error)
                setWaiting(false)
            } else {
                review.comments_list.push({
                    username:userInfo.username, 
                    comment_text: output_text,
                    created_at: new Date().toISOString(),
                    comment_id:comment_data,
                    like_count:0,
                    dislike_count:0
                })
                review.comment_count = review.comments_list.length
                setCommentList(review.comments_list)
                setShowComments(true)
                review.show_comments = true
                setShowAddComment(false)
                setCommentText('')
                updateFunc(review)
                setWaiting(false)
            }
        }
    }

    return(
        <div className={'comment-section'.concat((light_text)? ' light-text' : '')}>
            {//show the list of comments
            (showComments) ?
                <div className='comment-list'>
                    {review.comments_list.map((comment, index) => {
                        return(<Comment 
                            light_text={light_text}
                            userInfo={userInfo}
                            comment={comment} 
                            allSubjects={allSubjects}
                            subject={subject  }
                            key={index} 
                            updateFunc={updateComment} 
                            fullSize={fullSize}
                            mentionUsernames={mentionUsernames}
                            setMentionUsernames={setMentionUsernames}
                            downloadComments={() => {
                                downloadComments({review:review, setCommentList:setCommentList, setShowComments:setShowComments, updateFunc:updateFunc})}
                            }
                        />)
                    })}
                </div>
            :   
                null
            }

            {//if its in the following feed show the most recent comment
            (!showComments && review.comment_text) ?
                <Comment 
                    userInfo={userInfo}
                    comment={{
                        username:review.commenter_username, 
                        comment_text:review.comment_text, 
                        created_at:review.comment_created_at,
                        comment_id:review.comment_id,
                        like_count:review.comment_like_count,
                        dislike_count:review.comment_dislike_count,
                        vote:review.comment_vote
                    }}
                    light_text={light_text}
                    updateFunc={(comment) => {
                        review.comment_like_count = comment.like_count
                        review.comment_dislike_count = comment.dislike_count
                        review.comment_vote = comment.vote
                        updateFunc(review)
                    }} 
                    fullSize={fullSize}
                    mentionUsernames={mentionUsernames}
                    setMentionUsernames={setMentionUsernames}
                />
            :
                null
            }
            
            {//if they haven't clicked to add comment and there aren't comments, or they viewed comments but haven' clicked to add
            (!showAddComment && ((review.comment_count === 0) || showComments)) ?
                <div>
                    <div 
                        className='comment-button'
                        style={{
                            backgroundColor:userInfo.color_scheme.main_accent
                        }}
                        onClick={() => {
                            setShowAddComment(true)
                            updateFunc(review)}
                        }
                    >
                        <img src={commentIcon} className='comment-icon' alt='comment-icon'/>
                    </div>
                </div>

            : (!showAddComment && review.comment_count) ?
                <div>
                    <div 
                        className='comment-button'
                        style={{backgroundColor:userInfo.color_scheme.main_accent}}
                        onClick={() => {
                            downloadComments({review:review, setCommentList:setCommentList, setShowComments:setShowComments, updateFunc:updateFunc})
                            //review.show_comments = true
                            updateFunc(review)
                        }}
                    >
                        <div><b>{review.comment_count}&nbsp;</b></div>
                        <img src={commentIcon} className='comment-icon' alt='comment-icon'/>
                    </div>
                </div>
            
            : (showAddComment)?
                
                <form className='comment-input-row' onSubmit={(e) => {submitComment({e:e, userInfo:userInfo, waiting:waiting, setWaiting:setWaiting})}}>
                    
                    <MentionsInput 
                        maxLength='500'
                        className='comment-input'
                        value={commentText}
                        onChange={(e) => {
                            setCommentText(e.target.value)
                        }} 
                        spellCheck={false}
                        forceSuggestionsAboveCursor={true}
                        style = {{
                            input:{
                                border: 0,
                                outline: 0,
                                padding: "5px 0px 0px 5px",
                            },
                            suggestions: {
                                list: {
                                    backgroundColor: "whitesmoke",
                                    border: "1px solid #c1c1c1",
                                    fontSize: 15,
                                    borderRadius:'3px'
                                },
                                item: {
                                    padding: "5px 15px",
                                    color: "#000000",
                                    borderBottom: "1px solid #c1c1c1",
                                    "&focused": {
                                        backgroundColor: "#cee4e5",
                                    },
                                },
                            },
                        }}
                    >
                        <Mention 
                            data={getSuggestedMentions} 
                            displayTransform={(id, display) => "@" + (display)}
                            style={{backgroundColor: "#cee4e5", borderRadius: "5px"}}
                        ></Mention>
                    </MentionsInput>

                    <button type='submit' className='add-review-button'
                        style = {{backgroundColor:userInfo.color_scheme.main_accent}}>
                        {waiting? <img src={loadingIcon} className='loading-icon' alt='loading-icon'/> : null}
                        Submit
                    </button>
                </form>
            : 
                null
            }
        </div>
    )
}


export {
    ReviewStarsSrc, 
    StaticRatingStars, 
    SubmittedReview, 
    SubmittedDate, 
    DateStringFormatted, 
    ClickableStars, 
    CommentSection, 
    HiddenReview,
    downloadComments,
    getSuggestedMentions,
    getUsername,
    cleanSubmissionWithMentions
};