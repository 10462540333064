import React from 'react';
import {useParams} from 'react-router-dom'
import {Navigate} from 'react-router-dom';

import {downloadComments} from '../components/submitted_review';
import {supabase} from '../components/supabase_auth_initialize.js';
import {SummaryDetails, SummaryStats, RatingDistribution, summarize, SortTileSubject} from '../components/summary.jsx';
import {USMap} from '../components/map.jsx';
import { ReviewTile } from '../components/review_tile.jsx';
import { PopupReviewPopup, SubmitPopup, SubmittedPopup, UnlockPopup } from '../components/popups.jsx';

function ReviewTileList({reviewList, userInfo, fullSize, lastReview, reviews, selection, setReviews, allSubjects, subjectInfo, setSubjectInfo}) {

    return (
        <div className={'review-list'.concat((fullSize) ? '' : ' review-list-compressed')}>

            {(reviewList.length > 0) ? 
                reviewList.map((review, index) => {
                    return(
                        <ReviewTile 
                            userInfo={userInfo}
                            review={review} 
                            fullSize={fullSize} 
                            subject={subjectInfo.title}
                            add_ref={(index + 1 === reviewList.length) ? lastReview : null} 
                            allSubjects={allSubjects}
                            updateFunc= {(review) => {
                                reviews[selection.id].list[index] = {...reviewList[index], 
                                    like_count:review.like_count,
                                    dislike_count:review.dislike_count,
                                    vote:review.vote,
                                    show_comments:review.show_comments,
                                    comments_list:review.comments_list,
                                    comment_count:review.comment_count,
                                    hidden_by_user:review.hidden_by_user,
                                }
                                setReviews({...reviews})
                        
                                if (subjectInfo && (review.review_id === subjectInfo.review_id)) {
                                    setSubjectInfo({...subjectInfo, 
                                        like_count:review.like_count,
                                        dislike_count:review.dislike_count,
                                        vote:review.vote,
                                        show_comments:review.show_comments,
                                        comments_list:review.comments_list,
                                        comment_count:review.comment_count,
                                        hidden_by_user:review.hidden_by_user,
                                    })
                                }
                            }}
                            key={review.review_id}
                        />
                    )
                })
            :
                null
            }
        </div>
    )
}

async function getStateSummary({params, setStateSummary}) {
    console.log('getting state summary')
    const { data:state_info, error:state_info_error } = await supabase.rpc('get_state_counts',{req_url_name:params.subject_url_name})
    if (state_info_error) {
        console.log(state_info_error)
    } else {
        const formatted_state_summary = {}
        state_info.forEach((row) => {
            formatted_state_summary[row.us_state] = {avg_rating: row.avg_rating, count:row.count}
        })
        setStateSummary(formatted_state_summary)
    }
}

async function getSubjectInfo({params, userInfo, setSubjectInfo, setPageInfo, setSendToError, setPopupChoice, subjectInfoLoading, setSubjectInfoLoading, setSelection=null}) {
    console.log('getting subject info ' + params.subject_url_name)
    if (!subjectInfoLoading) {
        setSubjectInfoLoading(true)
        const { data:subject, error:subject_error } = await supabase.rpc('get_subject_info_2', {req_url_name:params.subject_url_name})
        const release_date_cutoff = new Date((new Date()).setDate((new Date()).getDate() - 1))

        //check if there was an error
        if (subject_error) {
            console.log(subject_error)
        } else if (subject){ //if not get together the stuff for the page

        
            summarize(subject)
            if (subject.review_status === 'unlocked') {
                subject.rating = ''
            } else if (!subject.rating) {
                subject.rating = ''
            }
            if (subject.review_text === null) {
                subject.review_text = ''
            }
            subject.show_comments = false
            subject.comment_count = (subject.comment_count) ? subject.comment_count : 0
            subject.comments_list = [];
            subject.username = userInfo.username;
            subject.url_name = params.subject_url_name;
            subject.date = new Date(subject.release_date)

            //update info for the page to relay to header
            setPageInfo({
                header_text:subject.title, 
                show_login:true, 
                logout_redirect:false, 
                rating: (subject.review_status === 'submitted' || new Date(subject.release_date) < release_date_cutoff)? 
                    subject.average_rating.toFixed(2)
                : null, 
                allowSwipe:true
            })

            //open submit popup if its todays take and it isnt submitted 
            if (subject.review_status === null &&  userInfo.username !== null && (new Date(subject.release_date) > release_date_cutoff)) {
                setPopupChoice('submit')
            }

            //sort by best if it isn't today and there's more than 50 reviews
            if (setSelection && (new Date(subject.release_date) < release_date_cutoff) && subject.review_count > 50) {
                setSelection({type:'good', rating:'null', follow_only:false, id:'good-null-false'})
            }

            //if the url says to open popup, then open the popup
            if (params.show_popup === 'true') {
                downloadComments({
                    review:subject, 
                    setCommentList: (comments_list) => {subject.comments_list = comments_list},
                    setShowComments: (show_comments) => {subject.show_comments = show_comments}, 
                    updateFunc: (review) => {setSubjectInfo(review)}
                })
                setPopupChoice('submitted')
            } 

            setSubjectInfo(subject)  
        } else {
            setSendToError(true)
        }
        setSubjectInfoLoading(false)
    }
}

async function getReviews({reviews, selection, setLoading, setReviews, params}) {
    console.log('getting_reviews')
   
    //if the selection hasn't been gotten yet, get it
    if (!reviews[selection.id]) {
        reviews[selection.id] = {list:[], has_more:true}
    }
    
    //read in all reviews
    const { data:new_reviews, error:reviews_error } = await supabase.rpc('get_subject_reviews', {
        already_have_indices: reviews[selection.id].list.map((review) => review.review_id),
        follow_only: selection.follow_only,
        req_url_name:params.subject_url_name,
        req_rating: (selection.rating === 'null') ? null : parseFloat(selection.rating),
        selection_type: selection.type
    })

    if (reviews_error) {
        console.log(reviews_error)
    } else {
        //prevent it from hitting the api if it's already gotten everything
        if (new_reviews.length < 15){
            console.log('set has more to false')
            reviews[selection.id].has_more = false
        } 

        //add info that doesn't need to me gotten by the database
        new_reviews.forEach((review) => {
            review.show_comments = false;
            review.comment_count = (review.comment_count) ? review.comment_count : 0
            review.comments_list = []
        })
        
        //add to the list if the review isn't already there
        const review_list_ids = reviews[selection.id].list.map((review) => {return(review.review_id)})
        new_reviews.forEach((review) => {
            if (!review_list_ids.includes(review.review_id)) {
                reviews[selection.id].list.push(review)
            } 
        })

        //loop through review lists
        Object.keys(reviews).forEach((selection_id) => {
            reviews[selection_id].list.forEach((old_review, index) => {
                //loop through new reviews and update where necessary
                new_reviews.forEach((new_review) => {
                    if (new_review.review_id === old_review.review_id) {
                        reviews[selection_id].list[index] = new_review
                    }
                })
            })
        })
        setReviews({...reviews})
        setLoading(false)
    }
}

//get user information to track including follow buttons
async function getPopupInfo({params, setPopupReview, setPopupChoice}) {
    if (params.username) {
        const { data:review, error:review_info_error } = await supabase.rpc('get_subject_popup_review', {
            req_username:params.username,
            req_url_name:params.subject_url_name,
            req_comment_id:(params.comment_id)? params.comment_id : null,
        })
        if (review_info_error) {
            console.log(review_info_error)
        } else {
            review.following = 'same';
            review.show_comments = false;
            review.comment_count = (review.comment_count) ? review.comment_count : 0
            if (review.comment_text) {
                review.comment_count = review.comment_count - 1
            }
            review.comments_list = []

            setPopupReview(review)
            setPopupChoice('popup_review')
        }
    }
}

function SubjectPage({fullSize, userInfo, setUserInfo, setPageInfo, popupChoice, setPopupChoice, allSubjects, setAllSubjects}) {
    const [stateSummary, setStateSummary] = React.useState(false);
    const [selection, setSelection] = React.useState({type:'new', rating:'null', follow_only:false, id:'new-null-false'})
    const [reviews, setReviews] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [submitLoading, setSubmitLoading] = React.useState(false)
    const [subjectInfoLoading, setSubjectInfoLoading] = React.useState(false)
    
    const [showMap, setShowMap] = React.useState(false);
    const [popupReview, setPopupReview] = React.useState({})
    const [sendToError, setSendToError] = React.useState(null);

    const release_date_cutoff = new Date((new Date()).setDate((new Date()).getDate() - 1))

    //is a state because when menu today's take is clicked in the header the navigation doesn't work
    const watchParams = useParams()
    const [params, setParams] = React.useState({
        subject_url_name:useParams().subject_url_name,
        comment_id:useParams().comment_id,
        username:useParams().username
    })

    //how to update allsubjects
    function setSubjectInfo(subjectInfo) {
        /*allSubjects.subjects[subjectInfo.title] = subjectInfo
        if (!allSubjects.active_subject || allSubjects.active_subject !== subjectInfo.title) {
            allSubjects.active_subject = subjectInfo.title
        } */
        //console.log((!allSubjects.active_subject || allSubjects.active_subject !== subjectInfo.title)?  allSubjects.active_subject = subjectInfo.title : null) 
        setAllSubjects((allSubjects) => ({...allSubjects, 
            active_subject: (!allSubjects.active_subject || allSubjects.active_subject !== subjectInfo.title)?  allSubjects.active_subject = subjectInfo.title : allSubjects.active_subject,
            subjects: {...allSubjects.subjects, [subjectInfo.title]: subjectInfo}
        }))
    }

    /*React.useEffect(() => {
        console.log(allSubjects)
    }, [allSubjects])*/


    React.useEffect(() => {
        if (params.subject_url_name !== watchParams.subject_url_name  || params.username !== watchParams.username || params.comment_id !== watchParams.comment_id) {
            setParams({
                subject_url_name:watchParams.subject_url_name,
                comment_id:watchParams.comment_id,
                username:watchParams.username
            })
            setShowMap(false)
        }
    }, [setParams, watchParams])

    // stuff for infinite scrolling
    const observer = React.useRef()
    const lastReview = React.useCallback((node) => {
        //once its loaded
        if (!loading) {
            if (observer.current) {observer.current.disconnect()}
            observer.current = new IntersectionObserver(entries => {
                //check if the last review is on the screen
                if (entries[0].isIntersecting && reviews[selection.id].has_more) {
                    //console.log('getting reviews')
                    setLoading(true)
                    getReviews({
                        reviews:reviews, 
                        selection:selection, 
                        setLoading:setLoading, 
                        setReviews:setReviews, 
                        params:params, 
                    })
                }
            })
            if (node) {observer.current.observe(node)}
        }
    }, [loading, reviews, selection])

    React.useEffect(() => {
        if (!reviews[selection.id]) {
            getReviews({
                reviews:reviews, 
                selection:selection, 
                setLoading:setLoading, 
                setReviews:setReviews, 
                params:params, 
                subjectInfo:allSubjects.subjects[allSubjects.active_subject],
                setSubjectInfo:setSubjectInfo
            })
        }
    }, [selection])

    React.useEffect(() => {
        getPopupInfo({params:params, setPopupReview:setPopupReview, setPopupChoice:setPopupChoice})
        setReviews({})
        getReviews({
            reviews:{}, 
            selection:selection, 
            setLoading:setLoading, 
            setReviews:setReviews, 
            params:params
        })
        getSubjectInfo({
            params:params, 
            userInfo:userInfo,
            setSubjectInfo:setSubjectInfo, 
            setSendToError:setSendToError, 
            setPopupChoice:setPopupChoice,
            setPageInfo:setPageInfo,
            setSelection:setSelection,
            subjectInfoLoading:subjectInfoLoading,
            setSubjectInfoLoading:setSubjectInfoLoading
        })
        setStateSummary(false)
    }, [params])
    
    return (
        <div className={(fullSize) ? 'center-content center-content-subject' : 'center-content'}
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
            + userInfo.color_scheme.background_color_2 + ')'}}
        >
            {(sendToError) ? <Navigate to='/error'/> : null}   

            {//popup to show unlock option
            popupChoice === 'unlock'? 
                <UnlockPopup 
                    subjectInfo={allSubjects.subjects[allSubjects.active_subject]} 
                    setSubjectInfo={setSubjectInfo} 
                    userInfo={userInfo} 
                    setUserInfo={setUserInfo} 
                    setPopupChoice={setPopupChoice}
                />
            
            //popup to show the user's previously submitted review
            : (popupChoice === 'submitted' && allSubjects.active_subject && userInfo) ? 
                <SubmittedPopup 
                    setPopupChoice={setPopupChoice} 
                    allSubjects={allSubjects}
                    subjectInfo={allSubjects.subjects[allSubjects.active_subject]} 
                    userInfo={userInfo}
                    fullSize={fullSize}
                    updateFunc= {(popup_review) => {
                        console.log('theoretically updating vote counts')
                        //check if submitted review is in the review list
                        setSubjectInfo(popup_review);

                        Object.keys(reviews).forEach((selection_id) => {
                            reviews[selection_id].list.forEach((old_review, index) => {
                                //loop through new reviews and update where necessary
                                if (old_review.username === popup_review.username) {
                                    console.log(old_review)
                                    old_review.like_count = popup_review.like_count
                                    old_review.dislike_count = popup_review.dislike_count
                                    old_review.vote = popup_review.vote
                                    old_review.comment_count = popup_review.comment_count
                                    old_review.comments_list = popup_review.comments_list
                                    old_review.hidden_by_user = popup_review.hidden_by_user
                                }
                            })
                        })
                        setReviews(reviews)
                    }}
                />
            
            //popup by user as specified by url
            : (popupChoice === 'popup_review') ?
                <PopupReviewPopup  
                    setPopupChoice={setPopupChoice} 
                    allSubjects={allSubjects}
                    popupReview={popupReview} 
                    fullSize={fullSize}
                    userInfo={userInfo}
                    updateFunc={(popup_review) => {
                        console.log('theoretically updating vote counts')
                        //check if submitted review is in the review list
                        setPopupReview(popup_review);
                    
                        Object.keys(reviews).forEach((selection_id) => {
                            reviews[selection_id].list.forEach((old_review, index) => {
                                //loop through new reviews and update where necessary
                                if (old_review.user_id === popup_review.user_id) {
                                    old_review.like_count = popup_review.like_count
                                    old_review.dislike_count = popup_review.dislike_count
                                    old_review.vote = popup_review.vote
                                    old_review.comment_count = popup_review.comment_count
                                    old_review.comments_list = popup_review.comments_list
                                    old_review.hidden_by_user = popup_review.hidden_by_user
                                }
                            })
                        })
                        setReviews(reviews)
                    }}
                />
            
            //popup for user to submit their take
            : (popupChoice === 'submit') ?
                <SubmitPopup 
                    userInfo={userInfo}
                    setUserInfo={setUserInfo}
                    fullSize={fullSize}
                    setPopupChoice={setPopupChoice}
                    subjectInfo={allSubjects.subjects[allSubjects.active_subject]}
                    setSubjectInfo={setSubjectInfo}
                    submitLoading={submitLoading}
                    setSubmitLoading={setSubmitLoading}
                    setPageInfo={setPageInfo}
                    followupFunc={() => {
                        setReviews({})
                        getReviews({
                            reviews:{}, 
                            selection:selection, 
                            setLoading:setLoading, 
                            setReviews:setReviews, 
                            params:params, 
                        })
                        getSubjectInfo({
                            params:params, 
                            userInfo:userInfo,
                            setSubjectInfo:setSubjectInfo, 
                            setSendToError:setSendToError, 
                            setPopupChoice:setPopupChoice,
                            setPageInfo:setPageInfo,
                            subjectInfoLoading:subjectInfoLoading,
                            setSubjectInfoLoading:setSubjectInfoLoading
                        })
                        setStateSummary(false)
                        setSubmitLoading(false)
                        setPopupChoice(null)
                    }}
                />
            :
                null
            }

            {showMap? 
                <USMap 
                    state_summary={stateSummary} 
                    full_size={fullSize}
                    showMapFunc={setShowMap}
                    userInfo={userInfo}
                />
            :
                <div className={fullSize? 'flex-row' : null}>
                    {(allSubjects 
                        && allSubjects.active_subject 
                        && !(allSubjects.subjects[allSubjects.active_subject].review_status !== 'submitted' 
                        && new Date(allSubjects.subjects[allSubjects.active_subject].release_date) > release_date_cutoff)
                    ) ? 
                        <div className={'summary-container'.concat((fullSize)? '' : ' summary-container-compressed')}>
                            <SummaryDetails 
                                summary_info={allSubjects.subjects[allSubjects.active_subject]}
                                setPopupChoice={setPopupChoice}
                                showMapFunc={(in_value) => {
                                    if (!stateSummary) {
                                        getStateSummary({params:params, setStateSummary:setStateSummary})
                                    }
                                    setShowMap(in_value)
                                }}
                                user_info={userInfo}
                                full_size={fullSize}
                            />
                            {fullSize ?
                                <SummaryStats 
                                    summary_info={allSubjects.subjects[allSubjects.active_subject]} 
                                    full_size={fullSize}
                                    userInfo={userInfo}
                                />
                            :
                                null
                            }
                            {<div className='sort-and-dist'>
                                {fullSize ? null : <SortTileSubject
                                    selection={selection}
                                    setSelection={setSelection}
                                    userInfo={userInfo}
                                />}
                                <RatingDistribution 
                                    userInfo={userInfo}
                                    summary_info={allSubjects.subjects[allSubjects.active_subject]} 
                                    filter={parseFloat(selection.rating)} 
                                    filterFunc={(in_rating) => {
                                        setSelection({...selection, 
                                            rating:String(in_rating), 
                                            id: selection.type + '-' + String(in_rating) + '-' + String(selection.following_only)
                                        })
                                    }}
                                    full_size={fullSize}
                                />
                                </div>}
                        </div>
                    : (allSubjects && allSubjects.active_subject) ?
                        <div className='submit-todays-subject'>
                            <SummaryDetails 
                                summary_info={allSubjects.subjects[allSubjects.active_subject]}
                                setPopupChoice={setPopupChoice}
                                user_info={userInfo}
                                full_size={fullSize}
                            />
                        </div>
                    :
                        null
                    }

                    {(allSubjects 
                        && allSubjects.active_subject
                        && !showMap
                        && !(allSubjects.subjects[allSubjects.active_subject].review_status !== 'submitted' 
                        && new Date(allSubjects.subjects[allSubjects.active_subject].release_date) > release_date_cutoff)
                    )?
                        <div className={fullSize ? 'reviews-and-sort-container' : 'review-list-compressed'}>
                            {fullSize ? <SortTileSubject selection={selection} setSelection={setSelection} userInfo={userInfo}/> : null }
                            <ReviewTileList 
                                userInfo={userInfo}
                                reviewList={(reviews[selection.id]) ? reviews[selection.id].list : []} 
                                setReviews={setReviews} 
                                fullSize={fullSize} 
                                lastReview={lastReview}
                                reviews={reviews} 
                                selection={selection}
                                subjectInfo={allSubjects.subjects[allSubjects.active_subject]}
                                setSubjectInfo={setSubjectInfo}
                                allSubjects={allSubjects}
                            />
                        </div>
                    :
                        null
                    } 
                </div>
       
            }
        </div>                  
    ) 
}

export {SubjectPage}