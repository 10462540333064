import React from 'react';
import {MentionsInput, Mention} from 'react-mentions';
import {Link, Navigate} from 'react-router-dom';

import {supabase} from '../components/supabase_auth_initialize.js'
import {SendToHome} from '../components/send_to_home.jsx';
import {
    SubmittedReview, 
    CommentSection, 
    DateStringFormatted, 
    getSuggestedMentions,
    ClickableStars
} from '../components/submitted_review.jsx';
import { submitReview, unlockReview, saveReview } from '../components/change_review_status.js';

import referenceLogo from '../images/reference_logo.svg';
import unlockIcon from '../images/unlock.svg';
import loadingIcon from '../images/loading-dark.svg';

var current_datetime = new Date();
const release_date_cutoff = new Date(current_datetime.setDate(current_datetime.getDate() - 1))

function SubjectTile({subject, index, userInfo, setUserInfo, setPageInfo, fullSize, subjectsList, setSubjectsList, setToSubjectPage, allSubjects, add_ref}) {

    const [unlockPopupStatus, setUnlockPopupStatus] = React.useState(false)
    const [subjectInfo, setSubjectInfo] = React.useState(subject)
    const [loading, setLoading] = React.useState(false)

    function updateFunc(review) {
        subjectsList[index] = {...subjectsList[index],
            like_count: review.like_count,
            dislike_count: review.dislike_count,
            vote: review.vote,
            show_comments: review.show_comments,
            comments: review.comments,
            comment_count: review.comment_count,
            comments_list: review.comments_list,
            hidden_by_user: review.hidden_by_user,
            rating: review.rating
        }
        setSubjectsList(subjectsList)
    }

    const switchViewStatus = () => {
        if(subjectsList[index].view_status) {
            subjectsList[index].view_status = false                  
        } else{
            subjectsList[index].view_status = true
        }
        setSubjectInfo({...subjectInfo, view_status:subjectsList[index].view_status})
        setSubjectsList(subjectsList)
    }

    return (
        <div className='subject-tile' ref={add_ref} key={subject.subject_id} style={{backgroundColor:userInfo.color_scheme.main_accent}}>
            {(unlockPopupStatus) ?
                <div className='popup-overlay' onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                        return false;
                    }
                    setUnlockPopupStatus(false)}}>
                    <div className='unlock-prompt' style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                        <div>{+unlockPopupStatus}</div>
                        <p>Unlock <b>{subject.title}</b>?</p>
                        <div className='unlock-prompt-button clickable' 
                        style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                        onClick={(event) => 
                            unlockReview({
                                review_info:subjectInfo, 
                                setReviewFunc:setSubjectInfo, 
                                user_info:userInfo, 
                                setUserFunc:setUserInfo, 
                                setPopupChoice:setUnlockPopupStatus
                            })
                        }>
                            <b>-5</b><img src={unlockIcon} className='unlock-icon-popup' alt='unlock-icon'/>
                        </div>
                    </div>
                </div>
            :
                null
            }
            <div className='subject-tile-top-row'>
                <Link className='subject-tile-title' to={'/subject/'.concat(subject.url_name)}><b>{subject.title}</b></Link>
                <Link to={subject.reference} target="_blank" rel="noopener noreferrer">
                    <img src={referenceLogo} className='reference-logo' alt='reference-logo'/>
                </Link>
            </div>
            <div className='subject-tile-bottom-row'>
                <p 
                    className='subject-tile-date'
                    style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                >{DateStringFormatted({submitted_date_str:subject.release_date})}</p>
                {//this is the button to show review entry
                (subjectInfo.review_status === 'submitted') ?
                    null
                : (subjectInfo.unlocked_at || (new Date(subjectInfo.release_date) > release_date_cutoff))?
                    <p className='subject-tile-enter-button clickable' id={subject.subject_id + '-add-button'} 
                        onClick={switchViewStatus}
                        style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                    ><b>drop a take!</b></p>
                :
                    <p className='subject-tile-enter-button clickable' 
                        id={subject.subject_id + '-add-button'}
                        onClick={(userInfo.tokens >= 5) ?
                            () => setUnlockPopupStatus(true)
                        :
                            null
                    }
                    style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                    >Unlock</p>
                    /*? -5&nbsp;<img src={unlockIcon} className='unlock-icon-user-home' alt='unlock-logo'/>&nbsp;*/
                }
            </div>
            {((subjectInfo.view_status) && (subjectInfo.review_status === 'submitted')) ?
                <div className={'subject-tile-entry-row'.concat((fullSize)? '' : ' subject-tile-entry-row-compressed')}
                style={{backgroundColor:userInfo.color_scheme.secondary_accent}}>
                    <SubmittedReview 
                        userInfo={userInfo} 
                        review={subject}  
                        allSubjects={allSubjects} 
                        updateFunc={updateFunc} 
                        subject={subject.title}
                        screen_width_to_compress='600'
                    />
                    <CommentSection userInfo={userInfo} review={subjectInfo} updateFunc= {updateFunc} light_text={false}/>
                </div>
            : subjectInfo.view_status ?
                <div className={'subject-tile-entry-row'.concat((fullSize)? '' : ' subject-tile-entry-row-compressed')}
                style={{backgroundColor:userInfo.color_scheme.secondary_accent}}>
                    <ClickableStars userInfo={userInfo} review_info={subjectInfo} setPageInfo={setPageInfo} setStateFunc={updateFunc}/>
                    <MentionsInput 
                        placeholder="What's your take?"
                        maxLength = '500'
                        className={'add-review-input'.concat((fullSize)? '' : ' add-review-input-compressed')}
                        value={subjectInfo.review_text}
                        onChange={(e) => {
                            setSubjectInfo({...subjectInfo, review_text:e.target.value})
                            subjectsList[index].review_text = e.target.value
                        }}
                        spellCheck={false}
                        forceSuggestionsAboveCursor={true}
                        style = {{
                            input:{
                                border: 0,
                                outline: 0,
                                padding: "5px 0px 0px 5px",
                            },
                            suggestions: {       
                                list: {
                                    position:'fixed',
                                    backgroundColor: "whitesmoke",
                                    border: "1px solid #c1c1c1",
                                    fontSize: 15,
                                    borderRadius:'3px'
                                },
                                item: {
                                    padding: "5px 15px",
                                    borderBottom: "1px solid #c1c1c1",
                                    "&focused": {
                                        backgroundColor: "#cee4e5",
                                    },
                                },
                            }
                        }}
                    >
                        <Mention 
                            data={getSuggestedMentions} 
                            
                            displayTransform={(id, display) => "@" + (display)}
                            style={{backgroundColor: "#cee4e5", borderRadius: "5px"}}
                        ></Mention>
                    </MentionsInput>

                    <div className='button-row-popup' >
                        {(subjectInfo.rating !== '') ?
                            <button className= 'add-review-button' 
                                style={{backgroundColor:userInfo.color_scheme.main_accent}} 
                                onClick={() => {
                                    if (subjectInfo.rating !== '') {
                                        saveReview({review_info:subjectInfo, setSavedFunc:setSubjectInfo})
                                    }
                                }}
                            >Save</button>
                        : null}
                        
                        {(subjectInfo.rating !== '') ? 
                            <button className= 'add-review-button' style={{backgroundColor:userInfo.color_scheme.main_accent}} onClick={() => {
                                if (subjectInfo.rating !== '') {
                                    setLoading(true)
                                    submitReview({
                                        review_info:subjectInfo, 
                                        setReviewFunc:setSubjectInfo, 
                                        setUserFunc:setUserInfo, 
                                        followupFunc:() => setToSubjectPage(subjectInfo.url_name)
                                    })
                                }
                            }}>
                                {loading? <img src={loadingIcon} className='loading-icon' alt='loading-icon'/> : null}
                                Submit
                            </button>
                        : null}
                    </div>
                       { /*<div className='button-row-popup'>
                            <button className= 'add-review-button' style={{backgroundColor:userInfo.color_scheme.main_accent}} onClick={() => 
                                saveReview({
                                    review_info:subjectInfo, 
                                    setSavedFunc:setSubjectInfo
                                })
                            }>Save</button>
                            <button className= 'add-review-button' style={{backgroundColor:userInfo.color_scheme.main_accent}} onClick={() => {
                                setLoading(true)
                                submitReview({ 
                                    review_info:subjectInfo, 
                                   
                                    setReviewFunc:setSubjectInfo, 
                                    setUserFunc:setUserInfo, 
                                    followupFunc:() => setToSubjectPage(subjectInfo.url_name)
                                })
                                setLoading(false)
                            }}>
                                {loading? <img src={loadingIcon} className='loading-icon' alt='loading-icon'/> : null}
                                Submit
                            </button>
                        </div>*/ }
                </div>
            :
                null
            }
        </div>
    );
  }

function SubjectsFeed({fullSize, userInfo, setUserInfo, setPageInfo, setToSubjectPage, allSubjects, add_ref, reviewRenderIndex}){  
    console.log('rendering subject feed')
    const [subjectsList, setSubjectsList] = React.useState([]); 

    React.useEffect(() => {
        if (userInfo.username) {
            console.log('getting subject data')
            async function getSubjectData() {
                console.log('getting data')
                const {data:subjects, error:subjects_error} = await supabase.rpc('get_subjects_w_user_review')
                if (subjects_error || subjects.length === 0) {
                    console.log(subjects_error)
                } else {
                    subjects.forEach((subject) => {
                        subject.view_status = subject.review_status === 'submitted'
                        subject.review_text = (subject.review_text) ? subject.review_text : ''
                        subject.rating = (subject.rating && (subject.review_status === 'saved' || subject.review_status === 'submitted')) ? subject.rating : ''
                        subject.show_comments = false;
                        subject.comment_count = (subject.comment_count) ? subject.comment_count : 0
                        subject.comments_list = [];
                        subject.following = 'same'
                    })
                    
                    setSubjectsList(subjects)
                }
            } 

            getSubjectData()
        }
    }, [userInfo.username])   

    

   return (
        <div className={'home-feed'.concat((fullSize)? '' : ' home-feed-compressed')}>
            {(subjectsList.length > 0) ?
                subjectsList.map((subject, index) => {
                    return(
                        (index <= reviewRenderIndex) ?
                            <SubjectTile 
                                subject={subject} 
                                key={subject.subject_id} 
                                index={index}
                                userInfo={userInfo}
                                setUserInfo={setUserInfo}
                                fullSize={fullSize}
                                subjectsList={subjectsList}
                                setSubjectsList={setSubjectsList}
                                setToSubjectPage={setToSubjectPage}
                                setPageInfo={setPageInfo}
                                allSubjects={allSubjects}
                                add_ref={(index === reviewRenderIndex)? add_ref : null}
                            />
                        :
                            null
                    )
                })
            :
                null
        }
        </div>             
    )  
}


const UserHomePage = ({fullSize, setPageInfo, userInfo, setUserInfo, allSubjects}) => {  
    const [toSubjectPage, setToSubjectPage] = React.useState(null);
    const [reviewRenderIndex, setReviewRenderIndex] = React.useState(20);

    React.useEffect(() => {
        setPageInfo({header_text:'My Takes', show_login:true, logout_redirect:'/', rating:null, allowSwipe:true})
    }, []);

    // stuff for infinite scrolling
    const observer = React.useRef()
    const lastReview = React.useCallback((node) => {

        if (observer.current) {observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries => {
            //check if the last review is on the screen
            if (entries[0].isIntersecting) {
                console.log('intersecting')
                setReviewRenderIndex(reviewRenderIndex + 20)
            }
        })
        if (node) {observer.current.observe(node)}
        
    }, [reviewRenderIndex])

    return (
        <div 
            className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        >
            <SendToHome/>
            {(toSubjectPage) ?
                <Navigate to={'/subject/'.concat(toSubjectPage)}/>
            :
                null
            }
            {SubjectsFeed({
                fullSize:fullSize, 
                userInfo:userInfo,
                setUserInfo:setUserInfo,
                setToSubjectPage:setToSubjectPage,
                setPageInfo:setPageInfo,
                add_ref:lastReview,
                reviewRenderIndex:reviewRenderIndex,
                allSubjects:allSubjects
            })}
        </div>
    )  
}

export {UserHomePage};

