import React from 'react';
import {Link} from 'react-router-dom';

import { DateStringFormatted } from '../components/submitted_review.jsx';
import { colorFromAvg } from '../components/map.jsx';

import starFull from '../images/star-full.svg';

function SubjectsTable({allSubjects, setAllSubjects, fullSize, userInfo}) {

    const max_avg = Math.max(...Object.values(allSubjects.subjects).map(a => a.average_rating))
    const min_avg = Math.min(...Object.values(allSubjects.subjects).map(a => a.average_rating))

    const max_stdev = Math.max(...Object.values(allSubjects.subjects).map(a => a.standard_deviation))
    const min_stdev = Math.min(...Object.values(allSubjects.subjects).map(a => a.standard_deviation))

    const max_count = Math.max(...Object.values(allSubjects.subjects).map(a => a.review_count))
    const min_count = Math.min(...Object.values(allSubjects.subjects).map(a => a.review_count))

    const max_date = Math.max(...Object.values(allSubjects.subjects).map(a => -1 * (new Date() - a.date)))
    const min_date = Math.min(...Object.values(allSubjects.subjects).map(a => -1 * (new Date() - a.date)))

    function sortDate(a,b) {
        const modifier = (allSubjects.current_sort !== 'date') ? 1 : -1
        if ((allSubjects.subjects[a].release_date < allSubjects.subjects[b].release_date))
            return(1 * modifier);
        if (allSubjects.subjects[a].release_date > allSubjects.subjects[b].release_date)
            return(-1 * modifier);
        return 0;
    }

    function sortRating(a,b) {
        const modifier = (allSubjects.current_sort !== 'rating') ? 1 : -1
        if (allSubjects.subjects[a].average_rating < allSubjects.subjects[b].average_rating)
            return(1 * modifier);
        if (allSubjects.subjects[a].average_rating > allSubjects.subjects[b].average_rating)
            return(-1 * modifier);
        return 0;
    }

    function sortCount(a,b) {
        const modifier = (allSubjects.current_sort !== 'count') ? 1 : -1
        if (allSubjects.subjects[a].review_count < allSubjects.subjects[b].review_count)
            return(1 * modifier);
        if (allSubjects.subjects[a].review_count > allSubjects.subjects[b].review_count)
            return(-1 * modifier);
        return 0;
    }

    function sortAlphabetical(a,b) {
        const modifier = (allSubjects.current_sort !== 'name') ? 1 : -1
        if (a < b)
            return(1 * modifier);
        if (a > b)
            return(-1 * modifier);
        return 0;
    }

    function sortStDev(a,b) {
        const modifier = (allSubjects.current_sort !== 'stdev') ? 1 : -1
        if (allSubjects.subjects[a].standard_deviation < allSubjects.subjects[b].standard_deviation)
            return(1 * modifier);
        if (allSubjects.subjects[a].standard_deviation > allSubjects.subjects[b].standard_deviation)
            return(-1 * modifier);
        return 0;
    }

    return(
        <div 
            className= {'subject-table-container'.concat((fullSize) ? '' : ' subject-table-container-compressed')}
            style={{backgroundColor:userInfo.color_scheme.main_accent}}
        >
            <div className='subject-table-header-row'>
                <div className={'clickable header-cell'.concat((fullSize) ? ' subject-col' : ' subject-col-compressed')}
                    onClick= {() => {
                        allSubjects.subjects_order.sort(sortAlphabetical)
                        setAllSubjects({...allSubjects, current_sort:(allSubjects.current_sort !== 'name') ? 'name' : 'name-reversed'})
                }}><b>Subject</b></div>

                <div className={'clickable header-cell'.concat((fullSize) ? ' avg-col' : ' avg-col-compressed')}
                    onClick= {() => {
                        console.log('sorting rating')
                        allSubjects.subjects_order.sort(sortRating)
                        setAllSubjects({...allSubjects, current_sort:(allSubjects.current_sort !== 'rating') ? 'rating' : 'rating-reversed'})
                }}><b>Rating</b></div>

                <div 
                    className={'clickable header-cell'.concat((fullSize) ? ' count-col' : ' count-col-compressed')}
                    onClick= {() => {
                        allSubjects.subjects_order.sort(sortCount)
                        setAllSubjects({...allSubjects, current_sort:(allSubjects.current_sort !== 'count') ? 'count' : 'count-reversed'})
                    }}
                ><b>Takes</b></div>

                <div 
                    className={'clickable header-cell'.concat((fullSize) ? ' stdev-col' : ' stdev-col-compressed')}
                    onClick= {() => {
                        allSubjects.subjects_order.sort(sortStDev)
                        setAllSubjects({...allSubjects, current_sort:(allSubjects.current_sort !== 'stdev') ? 'stdev' : 'stdev-reversed'})
                    }}
                ><b>Polarization</b></div>
                
                <div 
                    className={'clickable header-cell'.concat((fullSize) ? ' date-col' : ' date-col-compressed')}
                    onClick= {() => {
                        allSubjects.subjects_order.sort(sortDate)
                        setAllSubjects({...allSubjects, current_sort:(allSubjects.current_sort !== 'date') ? 'date' : 'date-reversed'})
                    }}
                ><b>Date</b></div>
            </div>

            {allSubjects.subjects_order.map((subject, index) => {
                return(
                    <div className={'subject-table-row'} key={subject}>
                        <Link className={'subject-cell link'.concat((fullSize) ? ' subject-col' : ' subject-col-compressed').concat(((index % 2) === 0)? '' : ' blue-row')}
                        to={'/subject/'.concat(allSubjects.subjects[subject].url_name)}>
                            <div>{allSubjects.subjects[subject].title.concat((allSubjects.subjects[subject].review_status === 'submitted') ? ' ✓' : '')}</div>
                        </Link>
                        <p 
                            className={'subject-cell'.concat((fullSize) ? ' avg-col' : ' avg-col-compressed')}
                            style={{backgroundColor:colorFromAvg(allSubjects.subjects[subject].average_rating, max_avg, min_avg, 0.5)}}
                        >
                            {(allSubjects.subjects[subject].average_rating) ? <img src={starFull} alt='label-star-full' className='subject-table-star'/> : null}
                            {(allSubjects.subjects[subject].average_rating)? allSubjects.subjects[subject].average_rating.toFixed(2) : '-' }
                        </p>
                        <p 
                            className={'subject-cell'.concat((fullSize) ? ' count-col' : ' count-col-compressed')}
                            style={{backgroundColor:colorFromAvg(allSubjects.subjects[subject].review_count, max_count, min_count, 0.5)}}
                        >
                            {allSubjects.subjects[subject].review_count}</p>
                        <p 
                            className={'subject-cell'.concat((fullSize) ? ' stdev-col' : ' stdev-col-compressed')}
                            style={{backgroundColor:colorFromAvg(allSubjects.subjects[subject].standard_deviation, max_stdev, min_stdev, 0.5)}}
                        >
                            {(allSubjects.subjects[subject].standard_deviation >= 0) ? allSubjects.subjects[subject].standard_deviation.toFixed(2) : '-'
                        }</p>
                        <p 
                            className={'subject-cell'.concat((fullSize) ? ' date-col' : ' date-col-compressed')}
                            style={{backgroundColor:colorFromAvg(-1 * (new Date() - allSubjects.subjects[subject].date), max_date, min_date, 0.5)}}
                        >
                            {DateStringFormatted({submitted_date_str:allSubjects.subjects[subject].release_date})}</p>
                    </div>
                )
            })}

        </div>
    )
}

function SubjectsPage({fullSize, allSubjects, setAllSubjects, setPageInfo, userInfo}) {

    React.useEffect(() => {
        setPageInfo({header_text:'Subjects', show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    return (
        <div 
            className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        >
            {(allSubjects.updated_at) ?
                <SubjectsTable
                    allSubjects = {allSubjects}
                    setAllSubjects = {setAllSubjects}
                    fullSize = {fullSize}
                    userInfo = {userInfo}
                />
            : null }
        </div>
    ) 
}

export {SubjectsPage}