import React from 'react';
import {Routes, Route, useNavigate} from "react-router-dom";
import { App as CapacitorApp} from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
//import { SplashScreen } from '@capacitor/splash-screen';
import '@ionic/react/css/core.css';
//import { AppLauncher } from '@capacitor/app-launcher';

//images
import splashIcon from './images/push_icon.svg';

//components
import './App.css';
import { Header } from './components/header.jsx';
import { RightContent } from './components/right_content.jsx';
import { LeftContent } from './components/left_content.jsx';
import { supabase } from './components/supabase_auth_initialize.js';

//pages
import {UserHomePage} from './pages/user_home_page.jsx';
import {SubjectPage} from './pages/subject_page.jsx';
import {SignUpPage} from './pages/signup_page.jsx';
import {HomePage} from './pages/home_page.jsx';
import {ConfirmEmailPage} from './pages/confirm_email_page.jsx';
import {SettingsPage} from './pages/settings_page.jsx';
import {UserPage} from './pages/user_page.jsx';
import {FollowingPage} from './pages/following_page.jsx';
import {SubjectsPage} from './pages/subjects_page.jsx';
import {AboutPage} from './pages/about_page.jsx';
import {ErrorPage} from './pages/error_page.jsx';
import {TopPage} from './pages/top_page.jsx';
import {RecoverPasswordPage} from './pages/recover_password_page.jsx';
import {SearchPage} from './pages/search_page.jsx';
import {TermsPage} from './pages/terms_page';
import { PrivacyPolicyPage } from './pages/privacy_policy_page.jsx';
//import { GuessPage } from './pages/guessing_page.jsx';

function AppUrlListener() {
  const navigate = useNavigate();
  React.useEffect(() => {
    CapacitorApp.addListener('appUrlOpen', (event) => {
      alert('hit url in app')
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split('dropatake.com').pop();
      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
};



// Hide the splash (you should do this on app launch)
//SplashScreen.hide();

//links to dropatake.com open in the app
/*const checkCanOpenUrl = async () => {
  const { value } = await AppLauncher.canOpenUrl({ url: 'com.dropatake' });

  console.log('Can open url: ', value);
};

const openPortfolioPage = async () => {
  await AppLauncher.openUrl({ url: 'com.getcapacitor.myapp://page?id=portfolio' });
};*/

//so that back button works on android
CapacitorApp.addListener('backButton', ({canGoBack}) => {
  if(!canGoBack){
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});

//determines which platform is being used
const platform = Capacitor.getPlatform();

const default_colors = {
  main_accent:  '#99d9ea',
  secondary_accent: '#568ea6',
  base_color: '#303030',//'#662614'
  background_color_1: '#99d9ea',
  background_color_2: '#f18202'
}

/*async function getRandomTable(setRandomTableData) {
  console.log('getting random table data')
  const { data:random_table, error:random_table_error } = await supabase.rpc('get_random_subject_table')
  if (random_table_error || (random_table.length < 1)) {
      console.log(random_table_error)
  } else {
      random_table.sort((a,b) => {
          if (a.average_rating < b.average_rating)
              return(1);
          else {
              return(-1);
          }
      })
      setRandomTableData(random_table)
  }
}*/

async function checkLogIn({userInfo, setUserInfo, setLoadingEverything}) {

  const { data:user_info, error:user_info_error } = await supabase.rpc('get_user_info_header_2')
  if (user_info_error || !user_info) {
    console.log(user_info_error)
    setUserInfo({username:null, tokens:0, user_id:null, loading:true, us_state:null, has_more:true, color_scheme:default_colors, device:userInfo.device? {fcm_token:userInfo.device.fcm_token }: {}})
  } else {
    //get the user's devices
    setUserInfo({...user_info,
      username:user_info.username,
      user_id:user_info.user_id,
      tokens:user_info.tokens,
      has_more:true,
      loading:true,
      has_notifications:user_info.has_notifications,
      us_state:user_info.us_state,
      notifications:[],
      color_scheme:(user_info.color_scheme)? user_info.color_scheme : default_colors,
      /*guess_info: {
        option_1:{
          url_name: user_info.option_1_url_name,
          title: user_info.option_1_title,
          subject_id: user_info.option_1_subject_id,
        }, 
        option_2:{
          url_name: user_info.option_2_url_name,
          title: user_info.option_2_title,
          subject_id: user_info.option_2_subject_id
        },
        current_guess_streak: user_info.current_guess_streak,
        record_guess_streak: user_info.record_guess_streak
      }*/
    })

    //reset default colors so hopefully no flickering
    /*if (user_info.color_scheme) {
      default_colors.main_accent = user_info.main_accent
      default_colors.secondary_accent = user_info.secondary_accent
      default_colors.background_color_1 = user_info.background_color_1
      default_colors.background_2 = user_info.background_color_2
      default_colors.base_color = user_info.default_colors
    }*/
  } 
  setLoadingEverything(false)
}

async function addFcmToken({req_fcm_token, setUserInfo}) {

  const { data:device_info, error:fcm_info_error } = await supabase.rpc('add_fcm_token', {req_fcm_token:req_fcm_token})
  if (fcm_info_error) {
    console.log(fcm_info_error)
  } else {
    setUserInfo(userInfo => ({...userInfo, device:{
      fcm_token:req_fcm_token, 
      subject_notifications:device_info.subject_notifications, 
      mention_notifications:device_info.mention_notifications,
      comment_notifications:device_info.comment_notifications,
      swipe_left:device_info.swipe_left,
      swipe_right:device_info.swipe_right,
    }}))
  } 
}

async function getBestReviewsLastWeek(setBestReviewList) {
  const { data:reviews, error:reviews_error } = await supabase.rpc('get_best_takes_last_week_2')
  if (reviews_error || (reviews.length < 1)) {
      console.log(reviews_error)
  } else {
      reviews.forEach((review) => {
          review.show_comments = false;
          review.comment_count = (review.comment_count) ? review.comment_count : 0
          review.comments_list = [];
      })
      setBestReviewList(reviews)
  }
}

async function getAllSubjects(setAllSubjects, loadingAllSubjects, setLoadingAllSubjects) {

  if (!loadingAllSubjects) {
    setLoadingAllSubjects(true)

    const { data:subjects, error:subject_error } = await supabase.rpc('get_subject_table_2')
    console.log('getting all subjects')

    //check if there was an error
    if (subject_error) {
        console.log(subject_error)
    } else if (subjects){ //if not get together the stuff for the page

      //set subject order
      const subjects_order = subjects.sort((a,b) => {
        if ((a.release_date < b.release_date))
            return(1);
        if (a.release_date > b.release_date)
            return(-1);
        return 0;
      }).map((subject) => subject.title)

      //get mapping of subject titles to subject urls
      const subjects_map = {}
      if (subjects) {
          subjects.forEach((subject) => {
              subjects_map[subject.title] = subject
        })
      }

      //add date object
      subjects.forEach((subject) => {
        subject.date = new Date(subject.release_date)
      })

      setAllSubjects({
        subjects:subjects_map, 
        updated_at:new Date(), 
        today: subjects_order[0], 
        yesterday: subjects_order[1], 
        subjects_order: subjects_order,
        current_sort: 'date'
      })
    }
  }
}

function App() {
  const [userInfo, setUserInfo] = React.useState({username:null, tokens:0, user_id:null, loading:true, has_more:true, device:null, color_scheme:default_colors})
  const [pageInfo, setPageInfo] = React.useState({header_text:null, show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
  const [loadingEverything, setLoadingEverything] = React.useState(true)
  
  //save here to keep home page loaded: reduces api calls and looks better
  const [allSubjects, setAllSubjects] = React.useState({subjects:{}, subjects_order:[]})
  const [loadingAllSubjects, setLoadingAllSubjects] = React.useState(false)
  const [bestReviewList, setBestReviewList] = React.useState([])
  let navigate = useNavigate()

  //stored here instead of header so any swipe will pop up the menu
  const [popupChoice, setPopupChoice] = React.useState(null)

  const touch_start = React.useRef(null);
  const touch_end = React.useRef(null);

  //make stuff shrink
  const [fullSize, setFullSize] = React.useState(window.matchMedia('(min-width: 1000px)').matches)
  const matches = window.matchMedia('(min-width: 1000px)')
  matches.addEventListener('change', (e) => {
      setFullSize(e.matches)
  })

  //check whether subject data is up to date
  const release_date_cutoff = new Date((new Date()).setDate((new Date()).getDate() - 1))
  
  if (allSubjects.today && release_date_cutoff > allSubjects.subjects[allSubjects.today].date) {
    console.log('todays subject expired: refreshing all subjects')
    getAllSubjects(setAllSubjects, loadingAllSubjects, setLoadingAllSubjects)
  }

  React.useEffect(() => {
    getAllSubjects(setAllSubjects, loadingAllSubjects, setLoadingAllSubjects)
  }, []);

  // handle login changes
  React.useEffect(() => {
    const {data: authListener} = supabase.auth.onAuthStateChange(
        (event, session) =>{
            if (session) {
              checkLogIn({userInfo:userInfo, setUserInfo:setUserInfo, setLoadingEverything:setLoadingEverything})
              setPopupChoice(null)
            } else {
              setUserInfo({
                username:null, 
                user_id:null, 
                tokens:false, 
                loading:true, 
                us_state:null, 
                has_more:true, 
                notifications:[],
                has_notifications:false,
                color_scheme:default_colors,
                device:{
                  swipe_left: platform === 'android' ? 'go_back' : null,
                  swipe_right: platform === 'android' ? 'open_menu' : 'go_back',
                  fcm_token: (userInfo.device && userInfo.device.fcm_token)? userInfo.device.fcm_token : null
                }
              })
              setLoadingEverything(false)
              setPopupChoice(null)
              
            }
        }
    );
    return () => {
        authListener.subscription.unsubscribe();
    };
  }, []);


  React.useEffect(() => {
    if ((platform === 'android' || platform === 'ios') && userInfo.user_id && (!userInfo.device)) {

      function register() {
        console.log('trying to register')
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
        PushNotifications.addListener('registration',
            (token) => {
                addFcmToken({req_fcm_token:String(token.value), setUserInfo:setUserInfo})
            }
        );
      
        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
            (error) => {
                //alert(JSON.stringify(error))
                console.log('Error on registration: ' + JSON.stringify(error));
            }
        );
        
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
          () => setUserInfo(userInfo => ({...userInfo, has_notifications:true, has_more:true, notifications:[]}))
        );
    
        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
          (action) => {
            //console.log(action.notification.data.navigate_to)
            navigate(action.notification.data.navigate_to)
          }
        );

      }

      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.checkPermissions().then((res) => {
        
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              console.log('didnt register')
            }
            else {
              register()
            }
          });
        }
        else {
          register()
        }
      })
    }
  }, [userInfo])

  return (
    <div 
      className='App' 
      onTouchStart={(e) => {
        console.log('start')
        touch_end.current = null;
        if (pageInfo.allowSwipe) {
          touch_start.current= {x:e.targetTouches[0].clientX, y:e.targetTouches[0].clientY}
        }
      }} 
      onTouchMove={(e) => touch_end.current = {x:e.targetTouches[0].clientX, y:e.targetTouches[0].clientY}} 
      onTouchEnd={() => {
        if ( 
          !fullSize //is not full size
          && touch_start.current !== null //not a click (reset to null on all touch ends)
          && touch_end.current !== null
          && (platform === 'android' || platform === 'ios') // not on the web
          && pageInfo.allowSwipe
          && (Math.abs(touch_end.current.y - touch_start.current.y) < (0.3 * Math.abs(touch_end.current.x - touch_start.current.x)))//not vertical swipe
        ) {

          if (((touch_end.current.x - touch_start.current.x) > 200)) { //if swipe to right
            if (userInfo.device.swipe_right === 'open_menu') {
              setPopupChoice('menu')
            } else if (userInfo.device.swipe_right === 'go_back') {
              window.history.back();
            }
            
          } else if (((touch_end.current.x - touch_start.current.x) < -200) && popupChoice !== null) {//if swipe to left and popup
            setPopupChoice(null)
          } else if (((touch_end.current.x - touch_start.current.x) < -200)) { //if swipe to left and not popup
            if (userInfo.device.swipe_left === 'open_menu') {
              setPopupChoice('menu')
            } else if (userInfo.device.swipe_left === 'go_back') {
              window.history.back();
            }
          }
        }
        //reset for next click/swipe
        touch_start.current = null
        touch_end.current = null
      }
    }
    >
      {platform === 'android' || platform === 'ios'? <AppUrlListener/> : null}
      {(loadingEverything)?
        <div className='menu-popup-overlay'>
          <img alt='splash-icon' className='splash-loading-icon' src={splashIcon}/>
        </div>
      :
   
        <div className='main-container'>     
          <Header 
            userInfo={userInfo} 
            setUserInfo={setUserInfo} 
            pageInfo={pageInfo} 
            fullSize={fullSize} 
            popupChoice={popupChoice}
            setPopupChoice={setPopupChoice}
            allSubjects={allSubjects}
          />
          <div className={'main-content'.concat((fullSize)? '' : ' main-content-compressed')}>
              <LeftContent userInfo={userInfo} fullSize={fullSize} allSubjects={allSubjects} setPopupChoice={setPopupChoice}/>     
                <Routes>
                  <Route path='/signup' element={<SignUpPage fullSize={fullSize} userInfo={userInfo} setPageInfo={setPageInfo}/>}/>
                  <Route path='/error' element={<ErrorPage userInfo={userInfo} setPageInfo={setPageInfo}/>}/>
                  <Route path='/about' element={<AboutPage userInfo={userInfo} setPageInfo={setPageInfo} fullSize={fullSize}/>}/>
                  <Route path='/confirm_email' element={<ConfirmEmailPage userInfo={userInfo} setPageInfo={setPageInfo}/>}/>
                  <Route path='/following' element={<FollowingPage userInfo={userInfo} fullSize={fullSize} allSubjects={allSubjects} setPageInfo={setPageInfo}/>}/>
                  <Route path='/' element={<HomePage 
                    userInfo={userInfo} 
                    fullSize={fullSize} 
                    setPageInfo={setPageInfo}
                    getBestReviewsLastWeek={getBestReviewsLastWeek}
                    setBestReviewList={setBestReviewList}
                    bestReviewList={bestReviewList}
                    allSubjects={allSubjects}
                    setAllSubjects={setAllSubjects}
                  />}/>
                  <Route path='/recover_password' element={<RecoverPasswordPage userInfo={userInfo} fullSize={fullSize} setPageInfo={setPageInfo}/>}/>
                  <Route path='/search' element={<SearchPage userInfo={userInfo} fullSize={fullSize} setPageInfo={setPageInfo} allSubjects={allSubjects}/>}/>
                  <Route path='/settings' element={<SettingsPage fullSize={fullSize} userInfo={userInfo} setUserInfo={setUserInfo} setPageInfo={setPageInfo}/>}/>
                  <Route path='/terms_and_conditions' element={<TermsPage userInfo={userInfo} setPageInfo={setPageInfo}/>}/>
                  <Route path='/privacy_policy' element={<PrivacyPolicyPage userInfo={userInfo} setPageInfo={setPageInfo}/>}/>
                  <Route path='/top' element={<TopPage userInfo={userInfo} fullSize={fullSize} setPageInfo={setPageInfo} allSubjects={allSubjects}/>}/>
                  <Route path='/user/:username' element={<UserPage userInfo={userInfo} fullSize={fullSize} allSubjects={allSubjects} setPageInfo={setPageInfo}/>}/>
                  <Route path='/subjects' element={<SubjectsPage 
                    allSubjects={allSubjects} 
                    setAllSubjects={setAllSubjects} 
                    userInfo={userInfo} 
                    fullSize={fullSize} 
                    setPageInfo={setPageInfo}
                  />}/>
                  <Route path='/user_home' element={<UserHomePage fullSize={fullSize} allSubjects={allSubjects} userInfo={userInfo} setUserInfo={setUserInfo} setPageInfo={setPageInfo}/>}/> 
                  {/*<Route path='/guess' element={<GuessPage fullSize={fullSize} userInfo={userInfo} setPageInfo={setPageInfo}/>}/>{/**/}
                  <Route path='/subject/:subject_url_name/:username?/:comment_id?' element={<SubjectPage 
                    fullSize={fullSize} 
                    setPageInfo={setPageInfo} 
                    userInfo={userInfo} 
                    setUserInfo={setUserInfo} 
                    popupChoice={popupChoice}
                    setPopupChoice={setPopupChoice}
                    allSubjects={allSubjects}
                    setAllSubjects={setAllSubjects}
                  />}/>
                  <Route path='*' element={<ErrorPage userInfo={userInfo} setPageInfo={setPageInfo}/>}/>
                </Routes>
              <RightContent userInfo={userInfo}/>
          </div>
        </div>
    
      }
    </div>

  )
}

export default App;

