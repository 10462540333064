import React from 'react';

import {supabase} from '../components/supabase_auth_initialize.js'
import {SendToHome} from '../components/send_to_home.jsx';
import { ReviewTile } from '../components/review_tile.jsx';

function ReviewTileList({reviewList, userInfo, setReviewList, fullSize, allSubjects, lastReview}) {

    return (
        <div className={'review-list'.concat((fullSize) ? '' : ' review-list-compressed')}>
            <div className='following-list-spacer'></div>
            {(reviewList.length > 0) ? 
                reviewList.map((review, index) => {
                    return(
                        <ReviewTile 
                            review={review} 
                            fullSize={fullSize} 
                            userInfo={userInfo}
                            allSubjects={allSubjects}
                            add_ref={(index + 1 === reviewList.length) ? lastReview : null} 
                            updateFunc = {(review) => {
                                reviewList[index] = {...reviewList[index], 
                                    like_count:review.like_count,
                                    dislike_count:review.dislike_count,
                                    vote:review.vote,
                                    show_comments:review.show_comments,
                                    comments_list:review.comments_list,
                                    comment_count:review.comment_count,
                                    hidden_by_user:review.hidden_by_user,
                                    comment_like_count:review.comment_like_count,
                                    comment_dislike_count:review.comment_dislike_count,
                                    comment_vote:review.comment_vote
                                }
                                setReviewList([...reviewList])
                            }}
                            key={index}
                        />
                    ) 
                })
            :
                null
            }
        </div>
    )
}

async function getReviews({reviewList, userInfo, setLoading, setHasMore}) {
    console.log('getting_reviews')

    const already_have_reviews = []
    const already_have_comments = []
    reviewList.forEach((review) => {
        if (review.comment_id) {
            already_have_comments.push(review.comment_id)
        } else {
            already_have_reviews.push(review.review_id)
        }
    })

    //read in all reviews
    const { data:reviews, error:reviews_error } = await supabase.rpc('get_following_reviews_comments_4', {
        already_have_reviews: already_have_reviews,
        already_have_comments: already_have_comments,
    })
    if (reviews_error) {
        console.log(reviews_error)
    } else if (reviews.length < 1){
        console.log('set has more to false')
        setHasMore(false)
    } else {
        reviews.forEach((review) => {
            review.show = 1;
            review.following = userInfo.username === review.username ? 'same' : 'dont_show'
            review.show_comments = false;
            review.comment_count = (review.comment_count) ? review.comment_count : 0
            review.comments_list = (review.comment_text)? [{
                comment_text:review.comment_text, 
                username:review.commenter_username, 
                created_at:review.comment_created_at
            }]: [];
            if (review.comment_text) {
                review.comment_count = review.comment_count - 1
            }
            review.list_id = String(review.review_id) + '-' + String(review.commenter_username)
        })
        
        const review_list_ids = reviewList.map((review) => {return(review.list_id)})

        reviews.forEach((review) => {
            if (!review_list_ids.includes(review.list_id)) {
                reviewList.push(review)
            } 
        })
        setLoading(false)
    }
}

function FollowingPage({fullSize, userInfo, setPageInfo, allSubjects}) {

    const [reviewList, setReviewList] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [hasMore, setHasMore] = React.useState(true)

    React.useEffect(() => {
        setPageInfo({header_text:'Following', show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    // stuff for infinite scrolling
    const observer = React.useRef()
    const lastReview = React.useCallback((node) => {
        //once its loaded
        if (!loading) {
            if (observer.current) {observer.current.disconnect()}
            observer.current = new IntersectionObserver(entries => {
                //check if the last review is on the screen
                if (entries[0].isIntersecting && hasMore) {
                    console.log('intersecting')
                    setLoading(true)
                    getReviews({reviewList:reviewList, setLoading:setLoading, userInfo:userInfo, setHasMore:setHasMore})
                }
            })
            if (node) {observer.current.observe(node)}
        }
    }, [loading, hasMore, reviewList , setLoading, setHasMore])

    
    React.useEffect(() => {
        getReviews({reviewList:reviewList, setLoading:setLoading, userInfo:userInfo, setHasMore:setHasMore})
    }, [])

    return (
        <div className={(fullSize) ? 'center-content center-content-subject' : 'center-content'}
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
            + userInfo.color_scheme.background_color_2 + ')'}}
        >
            <SendToHome/>
            <ReviewTileList reviewList={reviewList} allSubjects={allSubjects} userInfo={userInfo} setReviewList={setReviewList} fullSize={fullSize} lastReview={lastReview}/>
        </div>
    ) 
}

export {FollowingPage}