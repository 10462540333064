import React from 'react';
import {Link} from 'react-router-dom';

import {DateStringFormatted} from './submitted_review.jsx';
import {FollowButton} from './follow_button.jsx';

import starFull from '../images/star-full.svg';
import referenceLogo from '../images/reference_logo.svg';
import likeIcon from'../images/like.svg';
import dislikeIcon from '../images/dislike.svg';
import timeIcon from '../images/time.svg';
import hotIcon from '../images/hot.svg';
import {MapIcon} from './map.jsx';
import followIcon from '../images/following.svg';

var current_datetime = new Date();
const release_date_cutoff = new Date(current_datetime.setDate(current_datetime.getDate() - 1))

function toTimestamptz(in_date) {
    const iso_str = in_date.toISOString()
    const date_str = iso_str.split('T')[0]
    const time_str = iso_str.split('T')[1].replace('Z', '')
    return(
        date_str + ' ' + time_str
    )
}

function SummaryStats({summary_info, userInfo, full_size=true}) {

    return(
        <div style={{backgroundColor:userInfo.color_scheme.base_color}}
        className={'summary-details-container'.concat((full_size)? '' : ' summary-details-container-compressed')}>
            <div>Takes: <b>{summary_info.review_count}</b></div>     
            {(summary_info.rating_percentile) ? <p></p>  : null}
            <div>Average Rating: &nbsp;
                <img src={starFull} alt='label-star-full' className='distribution-star'/>
                <b>{summary_info.average_rating.toFixed(2)}</b>
            </div>
            {(summary_info.rating_percentile !== null && summary_info.rating_percentile <= .5) ?
                <div className='rating-percentile'>Top <b>{(Math.max(summary_info.rating_percentile, 0.01) * 100).toFixed(0)}%</b> of subjects</div>
            : (summary_info.rating_percentile !== null && summary_info.rating_percentile > .5) ?
                <div className='rating-percentile'>Bottom <b>{((1 - summary_info.rating_percentile) * 100).toFixed(0)}%</b> of subjects</div>
            :
                null
            }
            {(summary_info.stdev_percentile) ? <p></p>  : null}
            <div>Polarization: <b>{summary_info.standard_deviation.toFixed(2)}</b></div>
            {(summary_info.stdev_percentile !== null && summary_info.stdev_percentile <= .5) ?
                <div className='rating-percentile'>Top <b>{(Math.max(summary_info.stdev_percentile, 0.01) * 100).toFixed(0)}%</b> of subjects</div>
            : (summary_info.rating_percentile !== null && summary_info.stdev_percentile > .5) ?
                <div className='rating-percentile'>Bottom <b>{((1 - summary_info.stdev_percentile) * 100).toFixed(0)}%</b> of subjects</div>
            :
              null
            }
        </div>
    )
}

function SummaryDetails({summary_info, setPopupChoice, showMapFunc, user_info, full_size=true}) {
    //showMapFunc={setShowMap}
    return (
        <div style={{backgroundColor:user_info.color_scheme.base_color}}
        className={'summary-details-container'.concat((full_size)? '' : ' summary-details-container-compressed')}>
            <div className='title-summary-details'>{summary_info.title}</div>
                {(full_size || ((summary_info.review_status !== 'submitted') && 
                ((new Date(summary_info.release_date) > release_date_cutoff) || (summary_info.unlocked_at))) )? 
                    <div>
                        <div className='summary-row-reference summary-row-reference-compressed'>
                            <b>{DateStringFormatted({submitted_date_str:summary_info.release_date})}</b>
                            <Link to={summary_info.reference} target="_blank" rel="noopener noreferrer">
                                <img src={referenceLogo}  className='reference-logo-summary-table clickable' alt='reference-logo'/>
                            </Link>
                        </div>
                        <div className='summary-row-reference'>
                            {(summary_info.review_status === 'submitted' || new Date(summary_info.release_date) < release_date_cutoff) ?
                                <div className='map-button clickable' onClick={() => showMapFunc(true)}
                                    style={{color:user_info.color_scheme.base_color}}
                                >
                                    Map
                                    {/*<img className='map-logo-summary-table' src={MapIcon} alt='map-icon'/>  */}
                                    <MapIcon userInfo={user_info}/>
                                </div>
                            :
                                null
                            }
                            {//display differently depending on whether the subject is available and stuff
                            (user_info.username === null) ?
                                <div>Log in to submit</div>
                            :(summary_info.review_status === 'submitted') ?
                                <div onClick={() => setPopupChoice('submitted')} className='subject-submission-status clickable'
                                style={{color:user_info.color_scheme.base_color}}>Submitted ✓</div>
                            : ((new Date(summary_info.release_date) > release_date_cutoff || summary_info.unlocked_at) && user_info) ?
                                <div onClick={() => setPopupChoice('submit')} className='subject-submission-status clickable'
                                style={{color:user_info.color_scheme.base_color}}>drop a take!</div>
                            : ((new Date(summary_info.release_date) > release_date_cutoff) || (summary_info.unlocked_at)) ?
                                <div>drop a take!</div>
                            :
                                <div className={'subject-submission-status'.concat((user_info.tokens >= 5) ? ' clickable' : '')}
                                    style={{color:user_info.color_scheme.base_color}}
                                    onClick={(user_info.tokens >= 5) ? () => setPopupChoice('unlock') : null}>
                                        {user_info.tokens >= 5? 'Unlock': 'Expired'}
                                    </div>
                            }
                        </div>
                    </div>
                :
                    <div className='summary-info-compressed'>
                        <div className='summary-column'>
                            {//display differently depending on whether the subject is available and stuff
                            (user_info === null) ?
                                <div className='subject-submission-status' style={{color:user_info.color_scheme.base_color}}>Log in to submit</div>
                            :(summary_info.review_status === 'submitted') ?
                                <div onClick={() => setPopupChoice('submitted')} className='subject-submission-status clickable'
                                    style={{color:user_info.color_scheme.base_color}}
                                >Submitted ✓</div>
                            : ((new Date(summary_info.release_date) > release_date_cutoff || summary_info.unlocked_at) && user_info) ?
                                <div onClick={() => setPopupChoice('submit')} className='subject-submission-status clickable'
                                    style={{color:user_info.color_scheme.base_color}}
                                >drop a take!</div>
                            : ((new Date(summary_info.release_date) > release_date_cutoff) || (summary_info.unlocked_at)) ?
                                <div>drop a take!</div>
                            :
                                <div className={'subject-submission-status'.concat((user_info.tokens >= 5) ? ' clickable' : '')}
                                    onClick={(user_info.tokens >= 5) ? () => setPopupChoice('unlock') : null}
                                    style={{color:user_info.color_scheme.base_color}}
                                    >{user_info.tokens >= 5? 'Unlock': 'Expired'}</div>
                            }

                            {(summary_info.review_status === 'submitted' || new Date(summary_info.release_date) < release_date_cutoff) ?
                                <div className='map-button map-button-compressed clickable' onClick={() => showMapFunc(true)}
                                    style={{color:user_info.color_scheme.base_color}}>
                                    Map
                                    {/*<img className='map-logo-summary-table' src={MapIcon} alt='map-icon'/>  */}
                                    <MapIcon userInfo={user_info}/>
                                </div>
                            :
                                null
                            }

                            <div className='summary-row-reference summary-row-reference-compressed'>
                                <div><b>{DateStringFormatted({submitted_date_str:summary_info.release_date})}</b></div>
                                <Link to={summary_info.reference} target="_blank" rel="noopener noreferrer">
                                    <img src={referenceLogo} className='reference-logo-summary-table clickable ' alt='reference-logo'/>
                                </Link>
                            </div>

                              
                        </div>
                        <div className='summary-column'>

                            <div>Takes: <b>{summary_info.review_count}</b></div>     
                            {(summary_info.rating_percentile !== null) ? <div className='summary-column-spacer'></div>  : null}
                            <div>Average Rating: &nbsp;
                            <img src={starFull} alt='label-star-full' className='distribution-star'/>
                                <b>{summary_info.average_rating.toFixed(2)}</b>
                            </div>
                            {(summary_info.rating_percentile !== null && summary_info.rating_percentile <= .5) ?
                                <div className='rating-percentile'>Top <b>{(Math.max(summary_info.rating_percentile, 0.01) * 100).toFixed(0)}%</b> of subjects</div>
                            : (summary_info.rating_percentile !== null && summary_info.rating_percentile > .5) ?
                                <div className='rating-percentile'>Bottom <b>{((1 - summary_info.rating_percentile) * 100).toFixed(0)}%</b> of subjects</div>
                            :
                                null
                            }
                            {(summary_info.stdev_percentile !== null) ? <div className='summary-column-spacer'></div>  : null}
                            <div>Polarization: <b>{summary_info.standard_deviation.toFixed(2)}</b></div>
                            {(summary_info.stdev_percentile !== null && summary_info.stdev_percentile <= .5) ?
                                <div className='rating-percentile'>Top <b>{(Math.max(summary_info.stdev_percentile, 0.01) * 100).toFixed(0)}%</b> of subjects</div>
                            : (summary_info.rating_percentile !== null && summary_info.stdev_percentile > .5) ?
                                <div className='rating-percentile'>Bottom <b>{((1 - summary_info.stdev_percentile) * 100).toFixed(0)}%</b> of subjects</div>
                            :
                            null
                            }     
                        </div>
                    </div>
                }

            {(summary_info.review_status !== 'submitted' && new Date(summary_info.release_date) > release_date_cutoff) ?
                <div>
                    <div className='join-todays-converstion'>{summary_info.review_count} people have dropped their take.</div>
                    <div className='join-todays-conversation'>Drop yours to join the conversation!</div>
                    
                </div>
            :
                null
            }
        </div>
    )
}

function RatingDistribution({summary_info,  filter, filterFunc, userInfo, full_size=true}) {

    function RatingBar({rating_group}) {
        const info = summary_info.mapped_counts[rating_group]
        return(
            <div 
                className='rating-bar'
                onClick={() => {
                    if (info.count > 0) {
                        if (filter === info.rating) {
                            filterFunc(null);
                        } else {
                            filterFunc(info.rating)
                        }
                    }
                }}
            >
                <img src={starFull} alt='label-star-full' className='distribution-label-star'/>
                <div className='rating-label'>{info.rating}</div>
                {(info.count === 0) ?
                    null
                :
                    <div 
                        className={'rating-rectangle clickable'.concat((filter === info.rating) ? ' rating-rectangle-selected':'')}

                        style={{width:`calc((100% - 100px) * ${info.count / summary_info.max_count})`}}/>
                }
                
                <div className='bar-review-count clickable'>{
                    (info.count > 0) ?
                        ((info.count / summary_info.review_count)*100).toFixed(1).concat('%')
                    :
                        null
                }</div>
            </div>
        )
    }

    return(
        <div style={{backgroundColor:userInfo.color_scheme.base_color}}
        className={'rating-dist-container'.concat((full_size)? '' : ' rating-dist-container-compressed')}>
            {(summary_info) ? 
                [...Array(10).keys()].map(i => i + 1).map((rating_group) => {
                    return(
                        <RatingBar rating_group={rating_group} key={rating_group}/>
                    )
                })
            :
                null
            }
        </div>
    )
}

function summarize(summary) {
    summary.review_count = (
        summary.count_0half + summary.count_1 + summary.count_1half + summary.count_2 + summary.count_2half 
        + summary.count_3 + summary.count_3half + summary.count_4 + summary.count_4half + summary.count_5
    )
    summary.star_count = (
        summary.count_0half*0.5 + summary.count_1*1 + summary.count_1half*1.5 + summary.count_2*2 + summary.count_2half*2.5
        + summary.count_3*3 + summary.count_3half*3.5 + summary.count_4*4 + summary.count_4half*4.5 + summary.count_5*5
    )
    summary.max_count = Math.max(
        summary.count_0half, summary.count_1, summary.count_1half, summary.count_2, summary.count_2half,
        summary.count_3, summary.count_3half, summary.count_4, summary.count_4half, summary.count_5
    )
    summary.average_rating = summary.star_count / summary.review_count;
    summary.standard_deviation = Math.sqrt(
        ((summary.count_0half*(0.5 - summary.average_rating)**2)
        + (summary.count_1*(1 - summary.average_rating)**2)
        + (summary.count_1half*(1.5 - summary.average_rating)**2)
        + (summary.count_2*(2 - summary.average_rating)**2)
        + (summary.count_2half*(2.5 - summary.average_rating)**2)
        + (summary.count_3*(3 - summary.average_rating)**2)
        + (summary.count_3half*(3.5 - summary.average_rating)**2)
        + (summary.count_4*(4 - summary.average_rating)**2)
        + (summary.count_4half*(4.5 - summary.average_rating)**2)
        + (summary.count_5*(5 - summary.average_rating)**2)) 
        / summary.review_count
    )

    summary.mapped_counts = {
        10:{rating:0.5, count:summary.count_0half},
        9:{rating:1, count:summary.count_1},
        8:{rating:1.5, count:summary.count_1half},
        7:{rating:2, count:summary.count_2},
        6:{rating:2.5, count: summary.count_2half},
        5:{rating:3, count: summary.count_3},
        4:{rating:3.5, count: summary.count_3half},
        3:{rating:4, count: summary.count_4},
        2:{rating:4.5, count: summary.count_4half},
        1:{rating:5, count: summary.count_5},
    }
}

function SortTileTop({selection, setSelection, userInfo}) {
    const [fullSize, setFullSize] = React.useState(window.matchMedia('(min-width: 1000px)').matches)
    //when viewport is smaller than 1000px get rid of right side banner
    const matches = window.matchMedia('(min-width: 1000px)')
    matches.addEventListener('change', (e) => {
        setFullSize(e.matches)
    })

    return(
        <div className={'sort-with-date'.concat((fullSize)? '' : ' sort-with-date-compressed')} 
        style={{backgroundColor:userInfo.color_scheme.base_color}}>
            <div className='sort-with-date-col-types'>
                <div>
                    <div className={'sort-option'.concat((selection.type === 'good') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                    onClick={() => setSelection({...selection, type:'good', id: 'good-' + selection.time + '-' + String(selection.following_only)})}>
                        <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                            src={likeIcon} alt='like-icon'/>  
                        <div className='sort-text'><b>Good</b></div>
                    </div>
                    <div className={'sort-option'.concat((selection.type === 'bad') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                    onClick={() => setSelection({...selection, type:'bad', id: 'bad-' + selection.time + '-' + String(selection.following_only)})}>
                        <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                            src={dislikeIcon} alt='dislike-icon'/>  
                        <div className='sort-text'><b>Bad</b></div>
                    </div>
                    <div className={'sort-option'.concat((selection.type === 'hot') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                    onClick={() => setSelection({...selection, type:'hot', id: 'hot-' + selection.time + '-' + String(selection.following_only)})}>
                        <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                            src={hotIcon} alt='hot-icon'/>  
                        <div className='sort-text'><b>Hot</b></div>
                    </div>
                </div>

                <div className={'sort-option '.concat((selection.following_only) ? ' sort-option-selected' : '').concat((fullSize) ? ' following-option' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, 
                        following_only:!selection.following_only, 
                        id: selection.type  + '-' + selection.time + '-' + String(!selection.following_only)
                })}>
                    <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                        src={followIcon} alt='following-icon'/>  
                    <div className='sort-text'><b>Following</b></div>
                </div>

            </div>
            <div className='sort-with-date-col'>
                <div className={'sort-option'.concat((selection.time === 'day') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, time:'day', id: selection.type + '-day-' + String(selection.following_only)})}>
                    <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                        src={timeIcon} alt='time-icon'/>  
                    <div className='sort-text'><b>1 Day</b></div>
                </div>
                <div className={'sort-option'.concat((selection.time === 'week') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, time:'week', id: selection.type + '-week-' + String(selection.following_only)})}>
                    <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                        src={timeIcon} alt='time-icon'/>  
                    <div className='sort-text'><b>1 Week</b></div>
                </div>
                <div className={'sort-option'.concat((selection.time === 'month') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, time:'month', id: selection.type + '-month-' + String(selection.following_only)})}>
                    <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                        src={timeIcon} alt='time-icon'/>  
                    <div className='sort-text'><b>1 Month</b></div>
                </div>
                <div className={'sort-option'.concat((selection.time === 'year') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, time:'year', id: selection.type + '-year-' + String(selection.following_only)})}>
                    <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                        src={timeIcon} alt='time-icon'/>  
                    <div className='sort-text'><b>1 Year</b></div>
                </div>
                <div className={'sort-option'.concat((selection.time === 'all_time') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, time:'all_time', id: selection.type + '-all_time-' + String(selection.following_only)})}>
                    <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                        src={timeIcon} alt='time-icon'/>  
                    <div className='sort-text'><b>All Time</b></div>
                </div>
            </div>
        </div>
    )
}

function SortTileSubject({selection, setSelection, userInfo}) {
    const [fullSize, setFullSize] = React.useState(window.matchMedia('(min-width: 1000px)').matches)
    //when viewport is smaller than 1000px get rid of right side banner
    const matches = window.matchMedia('(min-width: 1000px)')
    matches.addEventListener('change', (e) => {
        setFullSize(e.matches)
    })

    return(
        <div style={{backgroundColor:userInfo.color_scheme.base_color}}
        className={'sort-container'.concat(fullSize? '' : ' sort-container-compressed')}>
            <div 
                className={'sort-option'.concat((selection.type === 'good') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, type:'good', id: 'good-' + selection.rating + '-' + String(selection.following_only)})}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={likeIcon} alt='like-icon'/>  
                <div className='sort-text'><b>Good</b></div>
            </div>
            <div 
                className={'sort-option'.concat((selection.type === 'bad') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, type:'bad', id: 'bad-' + selection.rating +'-' + String(selection.following_only)})}>
            <img 
                className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                src={dislikeIcon} alt='dislike-icon'/>  
                <div className='sort-text'><b>Bad</b></div>
            </div>
            <div className={'sort-option'.concat((selection.type === 'hot') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, type:'hot', id: 'hot-' + selection.rating + '-' + String(selection.following_only)})}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={hotIcon} alt='hot-icon'/>  
                <div className='sort-text'><b>Hot</b></div>
            </div>
            <div className={'sort-option'.concat((selection.type === 'new') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, type:'new', id: 'new-' + selection.rating + '-' + String(selection.following_only)})}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={timeIcon} alt='time-icon'/>  
                <div className='sort-text'><b>New</b></div>
            </div>
            <div className={'sort-option'.concat((selection.type === 'old') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, type:'old', id: 'old-' + selection.rating + '-' + String(selection.following_only)})}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={timeIcon} alt='time-icon'/>  
                <div className='sort-text'><b>Old</b></div>
            </div>
            
            <div className={'sort-option'.concat(selection.follow_only ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => setSelection({...selection, follow_only:!selection.follow_only, id: selection.type + '-' + selection.rating + '-' + String(!selection.follow_only)})}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={followIcon} alt='follow-icon'/>  
                <div className='sort-text'><b>Following</b></div>
            </div>      
        </div>
    )
}

function SortTileUser({sort_list, userInfo, setSortList}) {
    const [fullSize, setFullSize] = React.useState(window.matchMedia('(min-width: 1000px)').matches)
    //when viewport is smaller than 1000px get rid of right side banner
    const matches = window.matchMedia('(min-width: 1000px)')
    matches.addEventListener('change', (e) => {
        setFullSize(e.matches)
    })

    const [selection, setSelection] = React.useState('new')

    function sortNew() {
        sort_list.sort((a,b) => {
            if (a.submitted_at < b.submitted_at)
                return(1);
            else {
                return(-1);
            }
        })
        sort_list.forEach((review) => {review.show=1})
        setSelection('new')
        setSortList([...sort_list])
    }

    function sortOld() {
        sort_list.sort((a,b) => {
            if (a.submitted_at > b.submitted_at)
                return(1);
            else {
                return(-1);
            }
        })
        sort_list.forEach((review) => {review.show=1})
        setSelection('old')
        setSortList([...sort_list])
    }

    function sortGood() {
        sort_list.sort((a,b) => {
            if (a.good_bad_score < b.good_bad_score)
                return(1);
            else {
                return(-1);
            }
        })
        sort_list.forEach((review) => {
            if (review.good_bad_score > 0) {
                review.show = 1
            } else {
                review.show = 0
            }
        })
        setSelection('good')
        setSortList([...sort_list])
    }

    function sortBad() {
        sort_list.sort((a,b) => {
            if (a.good_bad_score > b.good_bad_score)
                return(1);
            else {
                return(-1);
            }
        })
        sort_list.forEach((review) => {
            if (review.good_bad_score < 0) {
                review.show = 1
            } else {
                review.show = 0
            }
        })
        setSelection('bad')
        setSortList([...sort_list])
    }

    function sortHot() {
        sort_list.sort((a,b) => {
            if (a.hot_score < b.hot_score)
                return(1);
            else {
                return(-1);
            }
        })
        sort_list.forEach((review) => {
            if (review.hot_score > 0) {
                review.show = 1
            } else {
                review.show = 0
            }
        })
        setSelection('hot')
        setSortList([...sort_list])
    }

    return(
        <div style={{backgroundColor:userInfo.color_scheme.base_color}}
        className={'sort-container'.concat(fullSize? '' : ' sort-container-compressed')}>
            <div 
                className={'sort-option'.concat((selection === 'good') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => sortGood()}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={likeIcon} alt='like-icon'/>  
                <div className='sort-text'><b>Good</b></div>
            </div>
            <div 
                className={'sort-option'.concat((selection === 'bad') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => sortBad()}>
            <img 
                className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                src={dislikeIcon} alt='dislike-icon'/>  
                <div className='sort-text'><b>Bad</b></div>
            </div>
            <div className={'sort-option'.concat((selection === 'hot') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => sortHot()}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={hotIcon} alt='hot-icon'/>  
                <div className='sort-text'><b>Hot</b></div>
            </div>
            <div className={'sort-option'.concat((selection === 'new') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => {sortNew()}}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={timeIcon} alt='time-icon'/>  
                <div className='sort-text'><b>New</b></div>
            </div>
            <div className={'sort-option'.concat((selection === 'old') ? ' sort-option-selected' : '').concat((fullSize) ? '' : ' sort-option-compressed')}
                onClick={() => sortOld()}>
                <img className={'sort-icon'.concat((fullSize) ? '' : ' sort-icon-compressed')}
                    src={timeIcon} alt='time-icon'/>  
                <div className='sort-text'><b>Old</b></div>
            </div>           
        </div>
    )
}

function UsernameTile({fullSize, viewUserInfo, userInfo, setViewUserInfo, summaryInfo}) { 
    return(
        <div style={{backgroundColor:userInfo.color_scheme.base_color}}
        className={'summary-details-container'.concat((fullSize)? '' : ' summary-details-container-compressed')}>
            <div className='title-summary-details'>
                {viewUserInfo.username}
                {viewUserInfo.same_user ? null :
                    <FollowButton userInfo={userInfo} review={viewUserInfo} 
                    setFunc={(review) => setViewUserInfo({...review})}
                    dark={true}
                />}
            </div>
    
            <div>Takes: <b>{summaryInfo.review_count}</b></div>     
            {(summaryInfo.rating_percentile) ? <p></p>  : null}
            <div>Average Rating: &nbsp;
                <img src={starFull} alt='label-star-full' className='distribution-star'/>
                <b>{summaryInfo.average_rating.toFixed(2)}</b>
            </div>
            <div>Polarization: <b>{summaryInfo.standard_deviation.toFixed(2)}</b></div>
            <p></p>
            <div className='summary-details-line'><b>{(viewUserInfo.pct_since_joined * 100).toFixed(1)}%</b> completed since joining</div>
            <div className='summary-details-line'><b>{(viewUserInfo.pct_overall * 100).toFixed(1)}%</b> completed overall</div>
            <div className='summary-details-line'>Joined <b>{DateStringFormatted({submitted_date_str:viewUserInfo.created_at})}</b></div>
            {(viewUserInfo.correlation && !viewUserInfo.same_user) ?
                <div className='summary-details-line'>Similarity Rating: <b>{(viewUserInfo.correlation > 0)? '+' : ''}{viewUserInfo.correlation.toFixed(2)}</b></div>
            :
                null
            }
        </div>
    )
}
export {SummaryStats, SummaryDetails, RatingDistribution, summarize, SortTileUser, SortTileSubject, SortTileTop, toTimestamptz, UsernameTile};

