import React from 'react';

import {supabase} from '../components/supabase_auth_initialize.js'
import { toTimestamptz } from '../components/summary.jsx';
import {SortTileTop} from '../components/summary.jsx';
import { ReviewTile } from '../components/review_tile.jsx';

var current_datetime = new Date();
const time_cutoffs = {
    'day': toTimestamptz(new Date(current_datetime.setDate(current_datetime.getDate() - 1))),
    'week': toTimestamptz(new Date(current_datetime.setDate(current_datetime.getDate() - 7))),
    'month': toTimestamptz(new Date(current_datetime.setMonth(current_datetime.getMonth() - 1))),
    'year': toTimestamptz(new Date(current_datetime.setYear(current_datetime.getYear() - 1))),
    'all_time': toTimestamptz(new Date(current_datetime.setFullYear(current_datetime.getFullYear() - 100))),
}

function ReviewTileList({reviewList, userInfo, fullSize, lastReview, reviews, selection, setReviews, allSubjects}) {
    return (
        <div className={'review-list'.concat((fullSize) ? '  review-list-padding' : ' review-list-compressed')}>
            <div className='following-list-spacer'></div>

            {(reviewList.length > 0) ? 
                reviewList.map((review, index) => {
                    return(
                        <ReviewTile 
                            userInfo={userInfo}
                            review={review} 
                            fullSize={fullSize} 
                            allSubjects={allSubjects}
                            add_ref={(index + 1 === reviewList.length) ? lastReview : null} 
                            updateFunc= {(review) => {
                                reviews[selection.id].list[index] = {...reviewList[index], 
                                    like_count:review.like_count,
                                    dislike_count:review.dislike_count,
                                    vote:review.vote,
                                    show_comments:review.show_comments,
                                    comments_list:review.comments_list,
                                    comment_count:review.comment_count,
                                    following:review.following,
                                    hidden_by_user:review.hidden_by_user,
                                }
                                setReviews({...reviews})
                            }}
                            key={review.review_id}
                        />
                    )
                })
            :
                null
            }
        </div>
    )
}

async function getReviews({reviews, selection, setLoading, setReviews}) {
    console.log('getting reviews')

    //if the selection hasn't been gotten yet, get it
    if (!reviews[selection.id]) {
        reviews[selection.id] = {list:[], has_more:true}
    }
    
    //read in all reviews
    const { data:new_reviews, error:reviews_error } = await supabase.rpc('get_top_' + selection.type, {
        already_have_indices: reviews[selection.id].list.map((review) => review.review_id),
        follow_only: selection.following_only,
        cutoff_date: time_cutoffs[selection.time]
    })

    if (reviews_error) {
        console.log(reviews_error)
    } else {
        //prevent it from hitting the api if it's already gotten everything
        //console.log(new_reviews.length)
        if (new_reviews.length < 20){
            console.log('set has more to false')
            reviews[selection.id].has_more = false
        } 

        //add info that doesn't need to me gotten by the database
        new_reviews.forEach((review) => {
            review.show_comments = false;
            review.comment_count = (review.comment_count) ? review.comment_count : 0
            review.comments_list = []
        })
        
        
        //add to the list if the review isn't already there
        const review_list_ids = reviews[selection.id].list.map((review) => {return(review.review_id)})
        new_reviews.forEach((review) => {
            if (!review_list_ids.includes(review.review_id)) {
                reviews[selection.id].list.push(review)
                console.log('added to list')
            } 
        })

        //loop through review lists
        Object.keys(reviews).forEach((selection_id) => {
            reviews[selection_id].list.forEach((old_review, index) => {
                //loop through new reviews and update where necessary
                new_reviews.forEach((new_review) => {
                    if (new_review.review_id === old_review.review_id) {
                        reviews[selection_id].list[index] = new_review
                    }
                })
            })
        })

        setReviews({...reviews})
        setLoading(false)
    }
}

function TopPage({fullSize, setPageInfo, allSubjects, userInfo}) {

    const [reviews, setReviews] = React.useState({})
    const [loading, setLoading] = React.useState(true)
    const [selection, setSelection] = React.useState({type:'good', time:'week', id:'good-week-false', following_only:false})

    React.useEffect(() => {
        setPageInfo({header_text:'Top Takes', show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    // stuff for infinite scrolling
    const observer = React.useRef()
    const lastReview = React.useCallback((node) => {
        //once its loaded
        if (!loading) {
            if (observer.current) {observer.current.disconnect()}
            observer.current = new IntersectionObserver(entries => {
                //check if the last review is on the screen
                if (entries[0].isIntersecting && reviews[selection.id].has_more) {
                    console.log('intersecting')
                    setLoading(true)
                    getReviews({reviews:reviews, selection:selection, setLoading:setLoading, setReviews})
                }
            })
            if (node) {observer.current.observe(node)}
        }
    }, [loading, selection])


    React.useEffect(() => {
        console.log(selection.id)
        if (!reviews[selection.id]) {
            getReviews({reviews:reviews, selection:selection, setLoading:setLoading, setReviews:setReviews})
        }
    }, [selection])

    return (
        <div className={(fullSize) ? 'center-content center-content-subject' : 'center-content'}
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
            + userInfo.color_scheme.background_color_2 + ')'}}
        >
            <SortTileTop selection={selection} setSelection={setSelection} userInfo={userInfo}/>
            <ReviewTileList 
                userInfo={userInfo}
                reviewList={(reviews[selection.id]) ? reviews[selection.id].list : []} 
                setReviews={setReviews} 
                fullSize={fullSize} 
                lastReview={lastReview}
                reviews={reviews} 
                selection={selection}
                allSubjects={allSubjects}
            />
        </div>
    ) 
}

export {TopPage}