import React from 'react';
import {useParams} from 'react-router-dom'
import {Navigate} from 'react-router-dom';

import {supabase} from '../components/supabase_auth_initialize.js'
import {RatingDistribution, summarize, SortTileUser, UsernameTile} from '../components/summary.jsx';
import { ReviewTile } from '../components/review_tile.jsx';

function ReviewTileList({reviewList, userInfo, allSubjects, fullSize, ratingFilter, reviewRenderIndex, add_ref}) {
    const viewList = []
    reviewList.forEach((review, index) => {
        if (review.show  && ((ratingFilter === null ) || (review.rating === ratingFilter))) {
            viewList.push(review)
        }
    })
 
    return (
        <div className={'review-list'.concat((fullSize) ? '' : ' review-list-compressed')}>
            {(reviewList.length > 0) ? 
                viewList.map((review, index) => {
                    if (index <= reviewRenderIndex.idx) {
                        return(
                            <ReviewTile 
                                userInfo={userInfo}
                                review={review} 
                                fullSize={fullSize} 
                                allSubjects={allSubjects}
                                add_ref={(index === reviewRenderIndex.idx)? add_ref : null}
                                updateFunc = {(review) => {
                                    //console.log('running update function')
                                    reviewList[index] = {...reviewList[index], 
                                        like_count:review.like_count,
                                        dislike_count:review.dislike_count,
                                        vote:review.vote,
                                        show_comments:review.show_comments,
                                        comments_list:review.comments_list,
                                        comment_count:review.comment_count,
                                        hidden_by_user:review.hidden_by_user
                                    }
                                    //setReviewList([...reviewList])
                                }}
                                key={review.subject_id + reviewRenderIndex.rand}
                            />
                        )
                    } else {
                        return(null)
                    }
                })
            :
                null
            }
        </div>
    )
}

async function getUserInfo({username, setSendToError, setViewUserInfo}) {
    //alert('getting user info')
    console.log('getting user info')
    //read in user details
    const { data:summary, error:summary_error } = await supabase.rpc('get_user_summary', { req_username: username })
    if (summary_error) {
        console.log(summary_error)
        setSendToError(true)
    } else if (summary) {
        setViewUserInfo(summary)
        //alert('got user info')
    } else {
        console.log('not summary returned for user')
        setSendToError(true)
    }
}

async function getReviews({username, setReviewList, userInfo, setRatingDistribution}) {
    //read in all reviews
    console.log('getting reviews')
    const { data:reviews, error:reviews_error } = await supabase.rpc('get_user_public_reviews', { req_username: username })
    if (reviews_error || (reviews.length < 1)) {
        console.log(reviews_error)
    } else {
        reviews.forEach((review) => {
            review.show = 1;
            review.show_comments = false;
            review.comment_count = (review.comment_count) ? review.comment_count : 0
            review.comments_list = [];
            review.following = (userInfo.username === username)? 'same' : 'dont_show'
        })

        const rating_distribution = {
            count_0half : reviews.filter(x => x.rating===0.5).length,
            count_1half : reviews.filter(x => x.rating===1.5).length,
            count_2half : reviews.filter(x => x.rating===2.5).length,
            count_3half : reviews.filter(x => x.rating===3.5).length,
            count_4half : reviews.filter(x => x.rating===4.5).length,
            count_1 : reviews.filter(x => x.rating===1).length,
            count_2 : reviews.filter(x => x.rating===2).length,
            count_3 : reviews.filter(x => x.rating===3).length,
            count_4 : reviews.filter(x => x.rating===4).length,
            count_5 : reviews.filter(x => x.rating===5).length
        }
        summarize(rating_distribution)
        setRatingDistribution(rating_distribution)

        setReviewList(reviews)
    }
}


function UserPage({fullSize, userInfo, setPageInfo, allSubjects}) {

    const username = useParams().username;
    const [reviewList, setReviewList] = React.useState([]);
    const [viewUserInfo, setViewUserInfo] = React.useState(null);
    const [ratingDistribution, setRatingDistribution] = React.useState();
    const [ratingFilter, setRatingFilter] = React.useState(null);
    const [reviewRenderIndex, setReviewRenderIndex] = React.useState({idx:20, rand:Math.random()})

    //if the user doesn't exist navigate to error page
    const [sendToError, setSendToError] = React.useState(username === 'deleted');

    React.useEffect(() => {
        if (viewUserInfo) {
            setPageInfo({header_text:viewUserInfo.username, show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
        }
    }, [viewUserInfo]);

    // stuff for infinite scrolling
    const observer = React.useRef()
    const lastReview = React.useCallback((node) => {
        if (observer.current) {observer.current.disconnect()}
        observer.current = new IntersectionObserver(entries => {
            //check if the last review is on the screen
            if (entries[0].isIntersecting) {
                console.log('intersecting')
                setReviewRenderIndex({...reviewRenderIndex , idx: reviewRenderIndex.idx + 20})
            }
        })
        if (node) {observer.current.observe(node)}
    }, [reviewRenderIndex])

    React.useEffect(() => {
        setReviewRenderIndex({idx:20, rand:Math.random()})
    }, [reviewList])

    React.useEffect(() => {
        console.log('ran useeffect for getting reviews')
        getUserInfo({username:username, setSendToError:setSendToError, setViewUserInfo:setViewUserInfo})
        getReviews({username:username, setReviewList:setReviewList, userInfo:userInfo, setRatingDistribution:setRatingDistribution})
    }, [username])

    /*React.useEffect(() => {
        if (reviewList && viewUserInfo) {
            console.log('recounting')
         
            const rating_distribution = {
                count_0half : reviewList.filter(x => x.rating===0.5).length,
                count_1half : reviewList.filter(x => x.rating===1.5).length,
                count_2half : reviewList.filter(x => x.rating===2.5).length,
                count_3half : reviewList.filter(x => x.rating===3.5).length,
                count_4half : reviewList.filter(x => x.rating===4.5).length,
                count_1 : reviewList.filter(x => x.rating===1).length,
                count_2 : reviewList.filter(x => x.rating===2).length,
                count_3 : reviewList.filter(x => x.rating===3).length,
                count_4 : reviewList.filter(x => x.rating===4).length,
                count_5 : reviewList.filter(x => x.rating===5).length,
            }
            summarize(rating_distribution)
            setRatingDistribution(rating_distribution)
        }
    }, [reviewList, userInfo])*/


    return (
        <div 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        className={(fullSize) ? 'center-content center-content-subject' : 'center-content center-content-subject-compressed' }>
            {(sendToError) ? <Navigate to='/error'/> : null} 
            {(viewUserInfo && ratingDistribution) ? //container with all the summary information
                <div className={'summary-container'.concat((fullSize)? '' : ' summary-container-compressed')}>
                    <UsernameTile fullSize={fullSize} userInfo={userInfo} viewUserInfo={viewUserInfo} setViewUserInfo={setViewUserInfo} summaryInfo={ratingDistribution}/>
                
                    <div className='sort-and-dist'>
                        {fullSize ? null : <SortTileUser userInfo={userInfo} sort_list={reviewList} setSortList={setReviewList}/>}
                        <RatingDistribution 
                            summary_info={ratingDistribution} 
                            filter={ratingFilter} 
                            filterFunc={setRatingFilter}
                            full_size={fullSize}
                            userInfo={userInfo}
                        />
                    </div>
                </div>
            :
                null
            }
            <div className={fullSize ? 'reviews-and-sort-container' : 'review-list-compressed'}>
                {fullSize ? <SortTileUser userInfo={userInfo} sort_list={reviewList} setSortList={setReviewList}/> : null}
                <ReviewTileList
                    userInfo={userInfo}
                    reviewList={reviewList}
                    setReviewList={setReviewList}
                    fullSize={fullSize} 
                    ratingFilter={ratingFilter}
                    reviewRenderIndex={reviewRenderIndex}
                    add_ref={lastReview}
                    allSubjects={allSubjects}
                />
            </div>
        </div>
    ) 
}

export {UserPage}