import React from 'react';
import {Link, useNavigate} from 'react-router-dom';

import {supabase} from './supabase_auth_initialize.js'
//import { userSignOut } from './header.jsx';

import subjectsIcon from '../images/subjects.svg';
import followingIcon from '../images/following.svg';
import homeIcon from '../images/home.svg';
import settingsIcon from '../images/settings.svg';
import aboutIcon from '../images/about.svg';
import todayYesterdayIcon from '../images/today-yesterday.svg';
import searchIcon from '../images/search.svg';
import topIcon from '../images/top.svg';
import signOutIcon from '../images/sign-out.svg';

function NavigationMenu({isPopup, in_user, allSubjects, setPopupChoice}) {
    const [authUser, setAuthUser] = React.useState(in_user);
    //const [signOutPopup, setSignOutPopup] = React.useState(false);

    let navigate = useNavigate()

    async function checkLogIn() {
        const {data:check_user, error:check_user_error } = await supabase.auth.getSession()
        if ((check_user_error) || (!check_user.session)) { 
            setAuthUser(null)
        } else {
            setAuthUser(check_user.session.user);
        }
    }

    React.useEffect(() => {
        checkLogIn()
        const {data: authListener} = supabase.auth.onAuthStateChange(
            (event, session) =>{
                switch (event) {
                    case 'SIGNED_IN':
                        setAuthUser(session?.user);
                        break;
                    case 'SIGNED_OUT':
                        setAuthUser(null);
                        break;
                    default:
                }
            }
        );
        return () => {
            authListener.subscription.unsubscribe();
        };
    }, []);

    return(
        <div className='navigation-with-terms'> 
            {/*(signOutPopup)?
                <div className='popup-overlay' onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                        return false;
                    }
                    setSignOutPopup(false)}}>
                    <div className='unlock-prompt' style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                        <p><b>Sign Out?</b></p>
                        <p className='header-sign-out-button clickable' 
                        style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                        onClick={() => {
                            setSignOutPopup(false);
                            userSignOut(() => null, userInfo);
                            } }> Confirm </p>
                    </div>  
                </div>
            :
               null */}
            {authUser ?
                <div className={'navigation-container'.concat((isPopup)? ' navigation-container-compressed' : '')}>
                    <Link 
                        to='/following' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={followingIcon} className='subjects-icon' alt='subjects-icon'/>
                        <div>Following</div> 
                    </Link>
                    <Link 
                        to='/user_home' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={homeIcon} className='subjects-icon' alt='home-icon'/>
                        <div>My Takes</div>
                    </Link>
                    <div
                        className={'link clickable navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {
                            if (allSubjects.today) {
                                navigate('/subject/' + allSubjects.subjects[allSubjects.today].url_name) 
                            }
                            if (setPopupChoice) {setPopupChoice(null)}
                        }}
                    >
                        <img src={todayYesterdayIcon} className='subjects-icon' alt='today-yesterday-icon'/>
                        <div>Today</div>  
                    </div>
                    <Link 
                        to='/top' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')} 
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={topIcon} className='subjects-icon' alt='full-star'/>
                        <div>Top</div>
                    </Link>
                    <Link 
                        to='/subjects' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={subjectsIcon} className='subjects-icon' alt='subjects-icon'/>
                        <div>Subjects</div>
                    </Link>
                    <Link 
                        to='/search' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={searchIcon} className='subjects-icon' alt='subjects-icon'/>
                        <div>Search</div>
                    </Link>
                    <Link 
                        to='/about' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={aboutIcon} className='subjects-icon' alt='subjects-icon'/>
                        <div>About</div>
                    </Link>
                    <Link 
                        to='/settings' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={settingsIcon} className='subjects-icon' alt='settings-icon'/>
                        <div>Settings</div>
                    </Link>
                    <div 
                        className={'clickable navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        //onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                        onClick={() => {
                            //if (isPopup) {
                            setPopupChoice('signout')
                            /*} else {
                                setSignOutPopup(true)
                            }*/
                        }}
                    >
                        <img src={signOutIcon} className='subjects-icon' alt='settings-icon'/>
                        <div>Sign Out</div>
                    </div>
                </div>
            :
                <div className={'navigation-container'.concat((isPopup)? ' navigation-container-compressed' : '')}>
                    <div
                        className={'link clickable navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {
                            if (allSubjects.today) {
                                navigate('/subject/' + allSubjects.subjects[allSubjects.yesterday].url_name) 
                            }
                        }}
                    >
                        <img src={todayYesterdayIcon} className='subjects-icon' alt='today-yesterday-icon'/>
                        <div>Yesterday</div>  
                    </div>
                    <Link 
                        to='/top' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={topIcon} className='subjects-icon' alt='full-star'/>
                        <div>Top</div>
                    </Link>
                    <Link 
                        to='/subjects' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={subjectsIcon} className='subjects-icon' alt='subjects-icon'/>
                        <div>Subjects</div>
                    </Link>
                    <Link 
                        to='/search' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={searchIcon} className='subjects-icon' alt='subjects-icon'/>
                        <div>Search</div>
                    </Link>
                    <Link 
                        to='/about' 
                        className={'link navigation-option'.concat((isPopup)? ' navigation-option-compressed' : '')}
                        onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                    >
                        <img src={aboutIcon} className='subjects-icon' alt='subjects-icon'/>
                        <div>About</div>
                    </Link>
                </div>
            }
            <div className='navigation-terms-container'>
                <div><u><Link 
                    className='navigation-terms-link' 
                    to='/terms_and_conditions'
                    onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                >terms and conditions</Link></u></div>
                <div><u><Link 
                    className='navigation-terms-link' 
                    to='/privacy_policy'
                    onClick={() => {if (setPopupChoice) {setPopupChoice(null)} }}
                >privacy policy</Link></u></div>
            </div>
        </div>
    )
}

export{NavigationMenu}