import React from 'react';
import { Mention, MentionsInput } from 'react-mentions';

import {saveReview, submitReview, unlockReview} from  '../components/change_review_status.js';
import { ReviewTile } from './review_tile.jsx';
import { ClickableStars, getSuggestedMentions } from './submitted_review.jsx';
import loadingIcon from '../images/loading-dark.svg';

import unlockIcon from '../images/unlock.svg';

function UnlockPopup({subjectInfo, setSubjectInfo, userInfo, setUserInfo, setPopupChoice}) {

    return(
        <div className='popup-overlay' onClick={(event) => {
            if (event.target !== event.currentTarget) {
                return false;
            }
            setPopupChoice(null)
            }}>
            <div className='unlock-prompt' style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                <p>Unlock <b>{subjectInfo.title}</b>?</p>
                <div 
                    className='unlock-prompt-button clickable' 
                    onClick={() => unlockReview({
                        review_info:subjectInfo, 
                        setReviewFunc:setSubjectInfo, 
                        setUserFunc:setUserInfo, 
                        setPopupChoice:setPopupChoice
                    })}
                    style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                >
                    <b>-5</b><img src={unlockIcon} className='unlock-icon-popup' alt='unlock-icon'/>
                </div>
            </div>           
        </div> 
    )
}

function SubmittedPopup({fullSize, userInfo, setPopupChoice, subjectInfo, allSubjects, updateFunc}) {
    return(
        <div className='popup-overlay' onClick={(event) => {
            if (event.target !== event.currentTarget) {
                return false;
            }
            setPopupChoice(null)}}>
            <div className={'review-tile-popup'.concat((fullSize)? '' : ' review-tile-popup-compressed')}>
                <ReviewTile 
                    userInfo={userInfo}
                    review={subjectInfo}
                    fullSize={fullSize} 
                    updateFunc= {updateFunc}
                    allSubjects= {allSubjects}
                />
            </div>
        </div> 
    )
}


function PopupReviewPopup({fullSize, userInfo, setPopupChoice, popupReview, allSubjects, updateFunc}) {
    return(
        <div className='popup-overlay' onClick={(event) => {
            if (event.target !== event.currentTarget) {
                return false;
            }
            setPopupChoice(false)}}>
            <div className={'review-tile-popup'.concat((fullSize)? '' : ' review-tile-popup-compressed')}>
                <ReviewTile 
                    review={popupReview} 
                    fullSize={fullSize} 
                    userInfo={userInfo}
                    updateFunc={updateFunc}
                    allSubjects={allSubjects}
                />
            </div>
        </div> 
    )
}

function SubmitPopup({userInfo, setUserInfo, setPopupChoice, fullSize, subjectInfo, setSubjectInfo, 
    setSubmitLoading, submitLoading, setPageInfo, followupFunc}) {

    return(
        <div className='popup-overlay' onClick={(event) => {
            if (event.target !== event.currentTarget) {
                return false;
            }
            setPopupChoice(null)}}>
            <div className={'review-tile-popup'.concat((fullSize)? '' : ' review-tile-popup-compressed')}
                style = {{backgroundColor:userInfo.color_scheme.base_color}}
            >
                <div className='subject-tile-entry-row'>
                    <div  className='submit-popup-title'>{subjectInfo.title}</div>
                    <ClickableStars 
                        userInfo={userInfo} 
                        review_info={subjectInfo} 
                        setPageInfo={setPageInfo}
                        setStateFunc={(review) => setSubjectInfo(review)}/>
                    <MentionsInput 
                        placeholder="What's your take?"
                        maxLength = '500'
                        className='add-review-input'
                        value={subjectInfo.review_text}
                        onChange={(e) => {
                            setSubjectInfo({...subjectInfo, review_text: e.target.value,});
                        }}
                        spellCheck={false}
                        forceSuggestionsAboveCursor={true}
                        style = {{
                            input:{
                                border: 0,
                                outline: 0,
                                padding: "5px 0px 0px 5px",
                            },
                            suggestions: {
                                list: {
                                    position:"fixed",
                                    backgroundColor: "whitesmoke",
                                    border: "1px solid #c1c1c1",
                                    fontSize: 15,
                                    borderRadius:'3px',
                                },
                                item: {
                                    padding: "5px 15px",
                                    borderBottom: "1px solid #c1c1c1",
                                    "&focused": {
                                        backgroundColor: "#cee4e5",
                                    },
                                },
                            }
                        }}
                    >
                        <Mention 
                            data={getSuggestedMentions} 
                            displayTransform={(id, display) => "@" + (display)}
                            style={{backgroundColor: "#cee4e5", borderRadius: "5px"}}
                        ></Mention>
                    </MentionsInput>

                    <div className='button-row-popup' >
                        {(subjectInfo.rating !== '') ?
                            <button className= 'add-review-button' 
                                style={{backgroundColor:userInfo.color_scheme.main_accent}} 
                                onClick={() => {
                                    if (subjectInfo.rating !== '') {
                                        saveReview({review_info:subjectInfo, setSavedFunc:setSubjectInfo})
                                    }
                                }}
                            >Save</button>
                        : null}
                        
                        {(subjectInfo.rating !== '') ? 
                            <button className= 'add-review-button' style={{backgroundColor:userInfo.color_scheme.main_accent}} onClick={() => {
                                if (subjectInfo.rating !== '') {
                                    setSubmitLoading(true)
                                    submitReview({
                                        review_info:subjectInfo, 
                                        setReviewFunc:setSubjectInfo, 
                                        setUserFunc:setUserInfo, 
                                        followupFunc:followupFunc
                                    })
                                }
                            }}>
                                {submitLoading? <img src={loadingIcon} className='loading-icon' alt='loading-icon'/> : null}
                                Submit
                            </button>
                        : null}
                    </div>
                </div>
            </div>                      
        </div> 
    )
}

export {UnlockPopup, SubmittedPopup, PopupReviewPopup, SubmitPopup};