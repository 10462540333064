import React from 'react';

import {Navigate} from 'react-router-dom';
import {supabase} from '../components/supabase_auth_initialize.js';
import { TermsAndConditions } from '../components/terms.jsx';

import loadingIcon from '../images/loading.svg';

//compnonent to be called in for sign in section
function SignUpPage({fullSize, setPageInfo, userInfo}) {
    const [showTerms, setShowTerms] = React.useState(false);

    //set up objects for logging in or signing in
    const [authUser, setAuthUser] = React.useState(null);
    const [signUpEmail, setSignUpEmail] = React.useState('');
    const [signUpPassword, setSignUpPassword]  = React.useState('');
    const [signUpPasswordConfirm, setSignUpPasswordConfirm] = React.useState('');
    const [signUpError, setSignUpError] = React.useState('');
    const [signedUp, setSignedUp] = React.useState(null);
    const [termsChecked, setTermsChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    
    React.useEffect(() => {
        setPageInfo({header_text:'Sign Up', show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    async function checkLogIn() {
        const {data:check_user, error:check_user_error } = await supabase.auth.getSession()
        if ((check_user_error) || (!check_user.session)) { 
            console.log('not logged in')
            setAuthUser(null)
        } else {
            setAuthUser(check_user.session.user);
        }
    }

    React.useEffect(() => {
        checkLogIn()
        const {data: authListener} = supabase.auth.onAuthStateChange(
            (event, session) =>{
                switch (event) {
                    case 'SIGNED_IN':
                        setAuthUser(session?.user);
                        break;
                    case 'SIGNED_OUT':
                        setAuthUser(null);
                        break;
                    default:
                }
            }
        );
        return () => {
            authListener.subscription.unsubscribe();
        };
    }, []);

    //lets you sign up
    async function signUpSupabase(e) {
        setLoading(true)
        e.preventDefault();

        if (signUpEmail.length === 0) {
            setSignUpError('Email address is required')
        } else if (signUpPassword.length < 8) {
            setSignUpError('Password must contain 8 or more characters')
        } else if (signUpPassword.match(/[a-z]/) === null) {
            setSignUpError('Password must contain at least 1 lowercase letter')
        } else if (signUpPassword.match(/[A-Z]/) === null) {
            setSignUpError('Password must contain at least 1 uppercase letter')
        } else if (signUpPassword.match(/[0-9]/) === null) {
            setSignUpError('Password must contain at least 1 number')
        } else if (signUpPassword.match(/[!@#$%=^&*()_+-/=[\]{};\\':"|<>?,./`~]/) === null) {
            setSignUpError('Password must contain at least 1 special character')
            ///[!@#\$%\^&\*\(\)_\+-/=\[\]\{\};\\'\:"|<>\?,\./`~]/
        } else if (signUpPassword !== signUpPasswordConfirm) {
            setSignUpError('Password confirmation must match')
        } else if (!termsChecked) {
            setSignUpError('You must accept terms and conditions to proceed')
        }
        else {
            const { data:signup_response, error } = await supabase.auth.signUp({
                email: signUpEmail,
                password: signUpPassword,
                options: {
                    emailRedirectTo: 'https://dropatake.com/settings' // you will have to make the project part dynamic in whichever way the framework you are using allows you to do this.
                }
            })
            if (error) {
                console.log(error)
                setSignUpError('User account could not be created')
            } else {
                console.log(signup_response)
                setSignedUp(true)
            }
        }
        setLoading(false)
    }

    return(
        authUser ?
            <Navigate to="/user_home" />
        : signedUp ?
            <Navigate to="/confirm_email" />
        :    
        <div 
            className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        >
                {(showTerms)?
                    <div className='popup-overlay' onClick={(event) => {
                        if (event.target !== event.currentTarget) {
                            return false;
                        }
                        setShowTerms(false)}}>
                        <div className='terms-popup'>
                            <TermsAndConditions/>
                        </div>
                    </div>
                :
                    null
                }
                <form className='signup-block' onSubmit={(loading)? null : signUpSupabase}>
                    <div className={'prompt-row'.concat((fullSize) ? '' : ' prompt-row-compressed')}>
                        <b className={'entry-prompt'.concat((fullSize) ? '' : ' entry-prompt-compressed')}>Email Address:</b>
                        <input 
                            className='signup-input'
                            type='email'
                            placeholder='email@example.com' 
                            value={signUpEmail} 
                            onChange={(e) => setSignUpEmail(e.target.value)}>
                        </input>
                    </div>
                    
                    <div className={'prompt-row'.concat((fullSize) ? '' : ' prompt-row-compressed')}>
                        <b className={'entry-prompt'.concat((fullSize) ? '' : ' entry-prompt-compressed')}>Password:</b>
                        <input 
                            className='signup-input'
                            type='password' 
                            placeholder='password' 
                            value={signUpPassword}
                            onChange={(e) => setSignUpPassword(e.target.value)}
                        ></input>
                    </div>
                    <div className={'prompt-row'.concat((fullSize) ? '' : ' prompt-row-compressed')}>
                        <b className={'entry-prompt'.concat((fullSize) ? '' : ' entry-prompt-compressed')}>Confirm Password:</b>
                        <input 
                            className='signup-input'
                            type='password' 
                            placeholder='confirm password' 
                            value={signUpPasswordConfirm}
                            onChange={(e) => setSignUpPasswordConfirm(e.target.value)}
                        ></input>
                    </div>
                    <div className='signup-explain'>Password must contain at least 8 characters, a lowercase letter, 
                    an uppercase letter, a number, and a special character</div>

                    <div className='prompt-row' >
                        <input type="checkbox" checked={termsChecked} className='clickable'
                        onChange={
                            () => {if (termsChecked) {
                                setTermsChecked(false)
                            } else {
                                setTermsChecked(true)
                            }}
                        }/>
                        I agree to the &nbsp;<b onClick={() => setShowTerms(true)} className='clickable'>Terms and Conditions</b>
                    </div>
                    
                    <button type='submit' style={{backgroundColor:userInfo.color_scheme.secondary_accent}} className='login-button'>
                        {loading? <img src={loadingIcon} className='loading-icon' alt='loading-icon'/> : null}
                        Sign Up
                    </button>
                    <div className='signin-error'>
                        {signUpError !== '' ?
                            <div>{signUpError}</div>
                            :
                            <div></div>
                        }
                    </div>
                </form>
            </div>
        )  
};

export {SignUpPage};

