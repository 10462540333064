
import React from 'react';
//import {Navigate} from 'react-router-dom';

//import {supabase} from './supabase_auth_initialize.js'
import {NavigationMenu} from './navigation_menu.jsx'


function LeftContent({fullSize, allSubjects, userInfo, setPopupChoice}) {

    return(
        <div style={{backgroundColor:userInfo.color_scheme.base_color}}>
            {(fullSize) ?
                <NavigationMenu 
                    onClickFunc={() => null}
                    allSubjects={allSubjects}
                    setPopupChoice={setPopupChoice}
                />
            :
                null
            }
        </div>
    )
    
}

export{LeftContent}