import {supabase} from '../components/supabase_auth_initialize.js'
import React from 'react';

import followIcon from '../images/follow.svg';
import followingIcon from '../images/following.svg';

function FollowButton({review, userInfo, dark=false, setFunc}) {
    //included so only the button renders automatically, but not the whole feed
    const [following, setFollowing] = React.useState((review) ? review.following : false)
    const [unfollowPopupStatus, setUnfollowPopupStatus] = React.useState(false)

    async function followToggle() {
        const { data:follow_response, error:follow_error } = await supabase.rpc('toggle_follow_user', {req_user_id:review.user_id})//review.user_id})
        if (follow_error) {
            console.log(follow_error)
        } else {
            console.log(follow_response)
            review.following = (follow_response==='followed') ? 'following' : null
            setFunc(review)
            setFollowing(review.following)
        }
    }

    return( 
         <div>
            {(unfollowPopupStatus) ?
                <div className='popup-overlay' onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                        return false;
                    }
                    setUnfollowPopupStatus(false)}}>
                    <div className='unfollow-prompt'  style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                        <p>Unfollow <b>{review.username}</b>?</p>
                        <button 
                            style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                            className='header-sign-out-button' onClick={() => {
                                followToggle();
                                setUnfollowPopupStatus(false)
                            }}>
                            Confirm
                        </button>
                    </div>
                </div>
            :
            null}
            {(userInfo.username === null) ? 
                null
            : (review.following && review.following !== 'same') ?
                /*<div 
                    className={'follow-button clickable'.concat((dark)? ' follow-button-dark' : '') }
                    style={{backgroundColor:userInfo.color_scheme.main_accent}}
                    onClick={() => setUnfollowPopupStatus(true)}>Following
                </div>*/
                <img 
                    src={followingIcon} 
                    alt='following-icon' 
                    onClick={() => setUnfollowPopupStatus(true)}
                    className='following-icon-title'
                />
            :
                <div 
                    className={'follow-button clickable'.concat((dark)? ' follow-button-dark' : '') }
                    style={{backgroundColor:userInfo.color_scheme.main_accent}}
                    onClick={followToggle}>
                        <img 
                            src={followIcon} 
                            alt='following-icon' 
                            className='follow-icon-title'
                        />
                </div>
                /*<div 
                    className={'follow-button clickable'.concat((dark)? ' follow-button-dark' : '') }
                    style={{backgroundColor:userInfo.color_scheme.main_accent}}
                    onClick={followToggle}>Follow
                </div>*/
            }
        </div>
    )
}

export {FollowButton};