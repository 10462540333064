import React from 'react';
import {useNavigate, Link} from 'react-router-dom';
//import {useParams} from 'react-router-dom'

import {supabase} from './supabase_auth_initialize.js'
import { NavigationMenu } from './navigation_menu.jsx';
//import signOut from '../images/sign-out.svg';
import { toTimestamptz } from './summary.jsx';
import { SubmittedDate } from './submitted_review.jsx';

import unlockIcon from '../images/unlock.svg';
import headerImage from '../images/star-half.svg';
import starFull from '../images/star-full.svg';
import menuIcon from '../images/menu.svg';
import bellIcon from '../images/bell.svg';
import bellActiveIcon from '../images/bell-active.svg';
import refresh from '../images/refresh.svg';

async function deactivateFcmToken(req_fcm_token) {
    //alert(req_fcm_token)
    const { error:fcm_info_error } = await supabase.rpc('deactivate_fcm_token', {req_fcm_token:req_fcm_token})
    if (fcm_info_error) {
        console.log(fcm_info_error)
    } 
}

//component to be called in for sign in section
function SignIn({setPopupChoice, userInfo}) {

    //set up objects for logging in or signing in
    const [signInEmail, setSignInEmail] = React.useState('');
    const [signInPassword, setSignInPassword]  = React.useState('')
    const [signInError, setSignInError] = React.useState('');

    //signs in or returns proper error message
    async function signInSupabase(e) {
        e.preventDefault();
        const { error } = await supabase.auth.signInWithPassword({
            email: signInEmail,
            password: signInPassword,
        }) 
        if (error) {
            setSignInError(error.message)
        } else {
            setSignInError('');
            setPopupChoice(null)
            window.location.reload();
        }
    };

    return(
        <div className='signin-container'>
            <form className='signin-block' onSubmit={signInSupabase} style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                <div className='login-header'>Log In</div>
                <div className='prompt-row'>
                    <p className='entry-prompt'>Email Address:</p>
                    <input 
                        className='signup-input'
                        type='email'
                        placeholder='email@example.com' 
                        value={signInEmail}
                        onChange={(e) => setSignInEmail(e.target.value)}
                    ></input>
                </div>
                <div className='prompt-row'>
                    <p className='entry-prompt'>Password:</p>
                    <input 
                        className='signup-input'
                        type='password' 
                        placeholder='password' 
                        value={signInPassword}
                        onChange={(e) => setSignInPassword(e.target.value)}
                    ></input>
                </div>
                <button type='submit' className='login-button' style={{backgroundColor:userInfo.color_scheme.secondary_accent}}>Log In</button>
                <div className='signin-error'>
                    {signInError !== '' ?
                        <div>{signInError}</div>
                        :
                        <div></div>
                    }
                </div>
                <div className='login-to-signup'>
                    Don't have an account? <b>
                        <Link 
                            onClick={() => setPopupChoice(null)}
                            className='link' 
                            to='/signup'
                        >Sign up </Link>
                    </b>
                </div>
                <div className='login-to-signup'>
                    <b><Link onClick={() => setPopupChoice(null)} className='link' to='/recover_password'>Forgot your password?</Link></b>
                </div>
            </form>
        </div>
    )  
};

async function getNotifications({setPopupChoice, userInfo, setUserInfo}) {
    const {data:notification_list, error:notification_error } = await supabase.rpc('get_notifications_3', {
        before_date: (userInfo.notifications.length === 0)? toTimestamptz(new Date()) : userInfo.notifications[userInfo.notifications.length-1].notification_time
    })
    if (notification_error) { 
        console.log(notification_error)
    } else {
       

        const notification_ids = userInfo.notifications.map((notification) => {return(
            String(notification.username) + String(notification.subject_title)
        )})
    
        notification_list.forEach((notification) => {
            if (!notification_ids.includes(String(notification.username) + String(notification.subject_title))) {
                userInfo.notifications.push(notification)
            } 
        })

        setUserInfo(userInfo => ({...userInfo, 
            has_notifications:false, 
            loading:false, 
            has_more:!(notification_list.length < 15),
            notifications:userInfo.notifications
        }))

        //setNotifications([...notifications])
        setPopupChoice('notification')
    }
}

function SignedInTopRow({userInfo}) {
    return(
        <div className='signed-in-top-row'>
            <Link className='link signed-in-username clickable' to={'/user/'+userInfo.username} >{userInfo.username}</Link>
        </div>
    )
}

function SignedInBottomRow({userInfo, setUserInfo, getNotifications, setPopupChoice, fullSize}) {
    return(
        <div className={(fullSize)? 'unlocks-container-header' : 'unlocks-container-compressed'}>
            {(fullSize)? 
                null 
            :
                <Link to='/'> <img src={headerImage} className='header-image-compressed' alt='header-logo'/> </Link>
            }
            {(fullSize)? 
                null 
            :
                <img src={refresh} className='header-refresh' alt='refresh' onClick={() => window.location.reload()}/>
            }

            <div className='unlock-icon-in-header'>
                <img src={unlockIcon} className='unlock-icon-header' alt='unlock-icon' onClick={() => setPopupChoice('droplet')}/>
                <div className='header-token-count' onClick={() => setPopupChoice('droplet')}><b>{userInfo.tokens.toFixed(0)} </b></div>
            </div>
            
            <img 
                src={userInfo.has_notifications? bellActiveIcon : bellIcon} 
                className='notification-icon' 
                alt='notification-icon'
                onClick={() => {
                    if (userInfo.notifications.length === 0) {
                        getNotifications({
                            /*setNotifications:setNotifications, 
                            notifications:notifications,*/
                            setPopupChoice:setPopupChoice,
                            userInfo:userInfo,
                            setUserInfo:setUserInfo
                        })
                        
                    } else {
                        setPopupChoice('notification')
                    }
                    setUserInfo(userInfo => ({...userInfo, has_notifications:false}))
                }}
            />
        </div>
    )
}

function NotificationList({userInfo, fullSize, lastReview, setPopupChoice}) {
    return(
        <div className={'notification-container'.concat((fullSize)? ' notification-container-fullsize' : '')}>
            {userInfo.notifications.map((notification, index) => {
                return(
                    <Link
                        className={'notification-row link'.concat((index % 2 === 0)? ' notification-row-grey' : '') }
                        onClick={() => setPopupChoice(null)}
                        to={
                            (notification.news_text)? 
                                notification.url_name 
                            :(notification.review_username && notification.comment_id)?
                                '/subject/' + notification.url_name + '/' + notification.review_username + '/' + String(notification.comment_id) 
                            :(notification.review_username)?
                                '/subject/' + notification.url_name + '/' + notification.review_username
                            :
                                '/subject/' + notification.url_name
                            }
                        key={index}
                        ref={(index + 1 === userInfo.notifications.length)? lastReview : null}
                    >
                        <div  className='notification-subrow'>
                            {(notification.comment_username && !notification.is_mention)?
                                <div className='notification-text'>
                                    <b>{notification.comment_username}</b> 
                                    &nbsp;commented on your take on&nbsp;
                                    <b>{notification.subject_title}</b>
                                </div>
                            : (notification.comment_username)?
                                <div className='notification-text'>
                                    <b>{notification.comment_username}</b>
                                    &nbsp;mentioned you in their comment on&nbsp;
                                    <b>{notification.review_username}</b>
                                    's take on&nbsp;
                                    <b>{notification.subject_title}</b>
                                </div>
                            : (notification.review_username)?
                                <div className ='notification-text'>
                                    <b>{notification.review_username}</b>
                                    &nbsp;mentioned you in their take on&nbsp;
                                    <b>{notification.subject_title}</b>
                                </div>
                            : notification.news_text ?
                                <div className='notification-text'>
                                    {notification.news_text}
                                </div>

                            :
                                <div className='notification-text'>
                                    New Subject: &nbsp;
                                    <b>{notification.subject_title}</b>
                                </div>
                            }
                        </div>
                        <div className='notification-subrow'>{SubmittedDate({submitted_date_str:notification.notification_time})}</div>
                    </Link>
                )
            })}
        </div>  
    )
}

/*----------------------------------------------------------------------------*/
//signs user out of account
async function userSignOut({userInfo, navigate, pageInfo}) {
    const { error } = await supabase.auth.signOut({scope:'local',});
    if (error) {
        console.log(error)
    } else {
        if (userInfo.device && userInfo.device.fcm_token) {
            deactivateFcmToken(userInfo.device.fcm_token)
        }
        if (pageInfo.logout_redirect) {
            navigate(pageInfo.logout_redirect)
        } else {
            window.location.reload();
        }
    }
};

/*----------------------------------------------------------------------------*/

const Header = ({fullSize, userInfo, setUserInfo, pageInfo, popupChoice, allSubjects, setPopupChoice}) => {
    //console.log('rendering header')

    let navigate = useNavigate()
    
    // stuff for infinite scrolling
    const observer = React.useRef()
    const lastReview = React.useCallback((node) => {
        //once its loaded
        if (!userInfo.loading) {
            if (observer.current) {observer.current.disconnect()}
            observer.current = new IntersectionObserver(entries => {
                //check if the last review is on the screen
                if (entries[0].isIntersecting && userInfo.has_more) {
                    console.log('intersecting')
                    setUserInfo(userInfo => ({...userInfo, loading:true}))
                    getNotifications({
                        /*setNotifications:setNotifications, 
                        notifications:notifications,*/
                        setPopupChoice:setPopupChoice,
                        userInfo:userInfo,
                        setUserInfo:setUserInfo,
                    })
                }
            })
            if (node) {observer.current.observe(node)}
        }
    }, [userInfo])

    const HeaderTitle = () => {

        return (
            <div className='header-center-container'>
                {(pageInfo.header_text) ?
                 
                    (pageInfo.rating !== null) ? 
                        <div className='header-title'>
                            <img src={starFull} className='header-star' alt='star-full'/>
                            <div className='header-rating header-font'>{pageInfo.rating}</div>  
                            <div className='header-title-text header-font'>{pageInfo.header_text}</div>
                        </div> 
                    :
                    <div className='header-title'>
                        <div className='header-title-text header-font'>{pageInfo.header_text}</div>
                    </div>
                :
                    null
        
                }
            </div>
        )
    }

    return (
        <div className={'header-container'.concat((fullSize) ? '' : ' header-container-compressed')} style={{backgroundColor:userInfo.color_scheme.main_accent}}>

            {(popupChoice === 'login')?
                <div className='popup-overlay' onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                        return false;
                    }
                    setPopupChoice(null)}}>
                    <SignIn setPopupChoice={setPopupChoice} userInfo={userInfo}/>
                </div>
            :
               null 
            }

            {(popupChoice === 'droplet')?
                <div className='popup-overlay' onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                        return false;
                    }
                    setPopupChoice(null)}}>
                    <div className='unlock-prompt' style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                        <p>You recieve 1 droplet <img src={unlockIcon} className='unlock-icon-header' alt='unlock-icon'/> each time you drop a take. </p>
                        <p>You can spend 5 droplets <img src={unlockIcon} className='unlock-icon-header' alt='unlock-icon'/> to unlock an expired subject.</p>
                    </div>  
                </div>
            :
               null 
            }

         
            <div className={((popupChoice === 'menu') && !fullSize)?'menu-popup-overlay': 'hidden'}
                onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                        return false;
                    }
                    setPopupChoice(null)}}>
                    
                <div className='popup-menu'>
                    <NavigationMenu 
                        userInfo={userInfo}
                        setPopupChoice={setPopupChoice} 
                        isPopup={true} 
                        in_user={userInfo.username}
                        allSubjects={allSubjects}
                    />
                </div>
            </div>
            
            {(popupChoice === 'signout')?
                <div className='popup-overlay' onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                        return false;
                    }
                    setPopupChoice(null)}}>
                    <div className='unlock-prompt' style={{backgroundColor:userInfo.color_scheme.main_accent}}>
                        <p><b>Sign Out?</b></p>
                        <p className='header-sign-out-button clickable' 
                        style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                        onClick={() => {
                            setPopupChoice(null);
                            userSignOut({userInfo:userInfo, pageInfo:pageInfo, navigate:navigate});
                            } }> Confirm </p>
                    </div>  
                </div>
            :
               null 
            }

            {(popupChoice === 'notification')?
                <div className='menu-popup-overlay' onClick={(event) => {
                    if (event.target !== event.currentTarget) {
                        return false;
                    }
                    setPopupChoice(null)}}>
                    <NotificationList setPopupChoice={setPopupChoice} userInfo={userInfo}/*notifications={notifications}*/ fullSize={fullSize} lastReview={lastReview}/>
                </div>
            :
                null 
            }

            {(fullSize)? null : <img src={menuIcon} className='menu-icon' alt='menu-icon' onClick={() => setPopupChoice('menu')}/>}

            {(fullSize)? 
                <Link to='/'> <img src={headerImage} className='header-image' alt='header-logo'/> </Link>
            :
                null
            }

            {(fullSize)? <HeaderTitle/> : null}
            
            {(pageInfo.show_login === true) ?
                (userInfo.username && fullSize) ?
                    <div className='signed-in-container'>
                        <SignedInTopRow userInfo={userInfo} setPopupChoice={setPopupChoice}/>
                        <SignedInBottomRow
                            /*notifications={notifications}
                            setNotifications={setNotifications}*/
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            getNotifications={getNotifications}
                            setPopupChoice={setPopupChoice}
                            fullSize={fullSize}
                        />
                    </div>
                : userInfo.username ?
                    <div className='signed-in-container signed-in-container-compressed'>
                        <SignedInBottomRow
                            /*notifications={notifications}
                            setNotifications={setNotifications}*/
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            getNotifications={getNotifications}
                            setPopupChoice={setPopupChoice}
                            fullSize={fullSize}
                        />
                        <SignedInTopRow userInfo={userInfo} setPopupChoice={setPopupChoice}/>
                    </div>

                : (userInfo.username === null && fullSize) ?
                    <div className='signed-in-container'>  
                        <div 
                            className='send-to-login-button' 
                            onClick={() => setPopupChoice('login')}
                            style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                        >Log In / Sign Up</div>
                    </div>
                : (userInfo.username === null)?
                    <div className='signed-in-container signed-in-container-compressed'>  
                        <Link to='/'> <img src={headerImage} className='header-image-compressed' alt='header-logo'/> </Link>
                        <div 
                            className='send-to-login-button' 
                            style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                            onClick={() => setPopupChoice('login')}
                        >Log In / Sign Up</div>
                    </div>
                :
                <div className='signed-in-container'></div>
            :
                <div className='signed-in-container'></div>
            }
        </div>
    );
};


export {Header, userSignOut};