import React from 'react';

import TakeLogo from '../images/star-half.svg';

function ConfirmEmailPage({setPageInfo, userInfo}) {
    React.useEffect(() => {
        setPageInfo({header_text:'Confirm Email', show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);
    return (
        <div className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
            + userInfo.color_scheme.background_color_2 + ')'}}>
            <div className='error-container' 
            style={{backgroundColor: userInfo.color_scheme.main_accent}}>
                <p>Please check your email to confirm your registration.</p>
                <img src={TakeLogo}  className='logo-error' alt='take_logo'/>
                <p>Be sure to check your spam folder if the confirmation email does not appear.</p>
            </div>
        </div>
    ) 
}

export {ConfirmEmailPage}