import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://odzmjshhwcnprejjecgx.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9kem1qc2hod2NucHJlamplY2d4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDY3NTEzMDIsImV4cCI6MjAyMjMyNzMwMn0.6rjE4zNg2zinm1C8nDb7OUKelmZghqc_qAeygvPTeF4'

export const supabase = createClient(supabaseUrl, supabaseKey, {
//    localStorage: AsyncStorage,
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
});
