import React from 'react';
import {Navigate} from 'react-router-dom';

import {supabase} from './supabase_auth_initialize.js'


const SendToHome = () => {
    //checked if logged in 
    //console.log('rendered send to login')
    const [authUser, setAuthUser] = React.useState(true);

    React.useEffect(() => {
        async function checkLogIn() {
            const {data:check_user, error:check_user_error } = await supabase.auth.getSession()
            if ((check_user_error) || (!check_user.session)) { 
                setAuthUser(null)
            } else {
                setAuthUser(check_user.session.user);
            }
        }
        checkLogIn()

        const {data: authListener} = supabase.auth.onAuthStateChange(
            (event, session) =>{
                switch (event) {
                    case 'SIGNED_IN':
                        setAuthUser(true);
                        break;
                    case 'SIGNED_OUT':
                        setAuthUser(false);
                        break;
                    default:
                }
            }
        );
        return () => {
            authListener.subscription.unsubscribe();
        };
    }, []);

    return(
        authUser ? 
            null
        :
            <Navigate to='/'/>
            
    )
}

export {SendToHome}