import React from 'react';


function RightContent({userInfo}) {
    const [visible, setVisible] = React.useState(window.matchMedia('(min-width: 1200px)').matches)

    //when viewport is smaller than 1000px get rid of right side banner
    const matches = window.matchMedia('(min-width: 1200px)')
    matches.addEventListener('change', (e) => {
        setVisible(e.matches)
    })

    return(
        <div style={{backgroundColor:userInfo.color_scheme.base_color}}
        className={
            (visible) ?
                'right-content'
            :
                null
        }>
        </div>
    )
}
export {RightContent};