import React from 'react';
import { Link } from 'react-router-dom';

import {supabase} from '../components/supabase_auth_initialize.js'
import {state_name_mapping} from '../components/map.jsx';

const re = new RegExp("^#[a-fA-F0-9]{6}$");

async function updatePushNotificationSettings(update_dict) {
    const { error:update_error } = await supabase.rpc('update_push_notification_settings', update_dict)
    if (update_error) {
        alert(update_error)
    } 
}

async function updateSwipeSettings(update_dict) {
    const { error:update_error } = await supabase.rpc('update_swipe_settings', update_dict)
    if (update_error) {
        alert(update_error)
    } 
}

async function sendDeletionRequest() {
    const { error:delete_error } = await supabase.rpc('create_deletion_request')
    if (delete_error) {
        console.log(delete_error)
    } else {
        await supabase.auth.signOut().then(() => {
            console.log('sign out successful')
            window.location.reload()
        })
    }
}

const built_color_schemes = {
    none: {
        name:'none'
    },
    default: {
        name: 'default',
        main_accent:  '#99d9ea',
        secondary_accent: '#568ea6',
        base_color: '#303030',//'#662614'
        background_color_1: '#99d9ea',
        background_color_2: '#f18202'
    },
    groovy_swamp: {
        name: 'groovy swamp',
        base_color: "#6e0057",
        main_accent: "#c2ffd6",
        secondary_accent: "#c254ac",
        background_color_1: "#2bff70",
        background_color_2: "#c1009a"
    },
    mountain_hawk: {
        name: "mountain hawk",
        base_color:"#61280c",
        main_accent:"#ffffff",
        secondary_accent:"#f2b500",
        background_color_1:"#f5f5f5",
        background_color_2:"#f2b500"
    },
    easter_suprise: {
        name: "easter suprise",
        base_color:"#4f40be",
        main_accent:"#ffecec",
        secondary_accent:"#66ff81",
        background_color_1:"#ffd066",
        background_color_2:"#66ff81"
    },
    corny_camo: {
        name: "corny camo",
        base_color:"#3b3924",
        main_accent:"#f0ffdf",
        secondary_accent:"#b5888a",
        background_color_1:"#639361",
        background_color_2:"#253b24"
    },
    vaporwack : {
        name: 'vaporwack',
        base_color:"#99156f",
        main_accent:"#b1aaff",
        secondary_accent:"#7979ff",
        background_color_1:"#ff2800",
        background_color_2:"#56008e"
    },
    
    /*snoop_scheme : {
        name:'snoop scheme',
        base_color: "#c95FE5", main_accent: "#efeede", secondary_accent: "#f1b3c4", background_color_1: "#c95FE5", background_color_2: "#eab2a1"
    },*/

    buckeye_blast: {
        name: 'buckeye blast',
        base_color:"#BB0000",
        main_accent:"#d7d7d7",
        secondary_accent:"#ff5a5a",
        background_color_1:"#666666",
        background_color_2:"#666666"
    },
    freedom_classic : {
        name: 'freedom classic',
        base_color:"#0b0e71",
        main_accent:"#ffffff",
        secondary_accent:"#ff5454",
        background_color_1:"#99d9ea",
        background_color_2:"#ff0000"
    },
    dirt_and_dust : {
        name: 'dirt and dust',
        base_color:"#613a05",
        main_accent:"#dacf94",
        secondary_accent:"#966b30",
        background_color_1:"#ffc04e",
        background_color_2:"#573410"
    },
    night_light : {
        name: 'night light',
        base_color:"#000000",
        main_accent:"#79ffa5",
        secondary_accent:"#17ff63",
        background_color_1:"#000000",
        background_color_2:"#000000"
    },
    mystic_mountain_blueberry : {
        name: 'mystic mountain blueberry',
        base_color: "#6400b3", 
        main_accent: "#c1c6ff", 
        secondary_accent: "#d66db5", 
        background_color_1: "#3ecad9", 
        background_color_2: "#0025ff"
    }
}

function SettingsPage({fullSize, userInfo, setUserInfo, setPageInfo}) {

    //const [authUsername, setAuthUsername] = React.useState('');
    //const [authEmail, setAuthEmail] = React.useState('')
    //const [changedEmail, setChangedEmail] = React.useState(null);

    const [inputUsername, setInputUsername] = React.useState('');
    const [usernameValid, setUsernameValid] = React.useState(null);
    const [showUsernamePopup, setShowUsernamePopup] = React.useState(false);

    const [changePassword, setChangePassword]  = React.useState('');
    const [changePasswordConfirm, setChangePasswordConfirm] = React.useState('');
    const [passwordError, setPasswordError] = React.useState('')
    const [showPasswordPopup, setShowPasswordPopup] = React.useState(false)
    const [unsavedColorScheme, setUnsavedColorScheme] = React.useState(userInfo.color_scheme)
    const [usState, setUSState] = React.useState(userInfo.us_state)

    /*const [changeEmail, setChangeEmail] = React.useState('');
    const [changeEmailConfirm, setChangeEmailConfirm] = React.useState('');
    const [showEmailPopup, setShowEmailPopup] = React.useState('')
    const [emailError, setEmailError] = React.useState('')*/

    const [showDeletePopup, setShowDeletePopup] = React.useState(false)

    React.useEffect(() => {
        setPageInfo({header_text:'Settings', show_login:true, logout_redirect:'/', rating:null, allowSwipe:true})
    }, []);

    React.useEffect(() => {
        setUnsavedColorScheme(userInfo.color_scheme)
        setUSState(userInfo.us_state)
    }, [userInfo.us_state])

    async function validateUsername(show_popup) {
        //e.preventDefault();
        if (inputUsername.length < 4 || inputUsername.length > 12) {
            setUsernameValid('invalid')
        } else {
            const { data:username_response, error:username_response_error } = await supabase.rpc('validate_username', {req_username:inputUsername})
            if (username_response_error) {
                console.log(username_response_error)
            } else if (username_response) {
                setUsernameValid(username_response)
                if (show_popup && username_response === 'valid') {
                    setShowUsernamePopup(true)
                }
            }
        }
    }

    async function saveColorScheme() {
        const req_color_scheme = {
            main_accent:  re.test(unsavedColorScheme.main_accent)? unsavedColorScheme.main_accent : userInfo.main_accent,
            secondary_accent: re.test(unsavedColorScheme.secondary_accent)? unsavedColorScheme.secondary_accent : userInfo.secondary_accent,
            base_color: re.test(unsavedColorScheme.base_color)? unsavedColorScheme.base_color : userInfo.base_color,
            background_color_1: re.test(unsavedColorScheme.background_color_1)? unsavedColorScheme.background_color_1: userInfo.background_color_1,
            background_color_2: re.test(unsavedColorScheme.background_color_2)? unsavedColorScheme.background_color_2 : userInfo.background_color_2,
        }
        const { error:color_response_error } = await supabase.rpc('save_color_scheme', {req_color_scheme:req_color_scheme})
        if (color_response_error) {
            console.log(color_response_error)
        } else {
            setUnsavedColorScheme(req_color_scheme)
            //setUserInfo((previous) => ({...previous, color_scheme:req_color_scheme}))
            setUserInfo(userInfo => ({...userInfo, color_scheme:req_color_scheme}))
        }
    }

    async function changeUsername() {
        const { data:username_response, error:username_response_error } = await supabase.rpc('change_username', {req_username:inputUsername})
        if (username_response_error) {
            console.log(username_response_error)
        } else if (username_response === 'changed_username') {
            setUserInfo(userInfo => ({...userInfo, username:inputUsername}))
            setInputUsername('')
            setUsernameValid(null)
        }
        setShowUsernamePopup(false)
    }

    //lets you sign up
    async function validatePassword() {
        //e.preventDefault();

        if (changePassword.length < 8) {
            setPasswordError('Password must contain 8 or more characters')
        } else if (changePassword.match(/[a-z]/) === null) {
            setPasswordError('Password must contain at least 1 lowercase letter')
        } else if (changePassword.match(/[A-Z]/) === null) {
            setPasswordError('Password must contain at least 1 uppercase letter')
        } else if (changePassword.match(/[0-9]/) === null) {
            setPasswordError('Password must contain at least 1 number')
        } else if (changePassword.match(/[!@#$%^&*()_+-/=[\]{};\\':"|<>?,./`~]/) === null) {
            setPasswordError('Password must contain at least 1 special character')
            ///[!@#\$%\^&\*\(\)_\+-/=\[\]\{\};\\'\:"|<>\?,\./`~]/
        } else if (changePassword !== changePasswordConfirm) {
            setPasswordError('Password confirmation must match')
        }
        else {
            setShowPasswordPopup(true)  
        }
    }

    /*async function validateEmail() {
        //e.preventDefault();
        if (changeEmail.toLowerCase()
                .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) === null) {
            setEmailError('Email is not valid')
        } else if (changeEmail !== changeEmailConfirm) {
            setEmailError('Email confirmation must match')
        } else {
            setShowEmailPopup(true)  
        }
    }*/


    async function changePasswordSupabase() {
        const { change_password_error } = await supabase.auth.updateUser({ password: changePassword })
        if (change_password_error) {
            console.log(change_password_error)
        } else {
            setChangePassword('')
            setChangePasswordConfirm('')
        }
        setShowPasswordPopup(false)
    }

    async function changeUSState(setUSState, req_us_state) {
        const { change_state_error } = await supabase.rpc('change_us_state', { req_us_state: req_us_state })
        if (change_state_error) {
            console.log(change_state_error)
        } else {
            userInfo.us_state = req_us_state
            setUSState(req_us_state)
        }
    }

    /*async function changeEmailSupabase() {
        const { change_password_error } = await supabase.auth.updateUser({ email: changeEmail})
        if (change_password_error) {
            console.log(change_password_error)
        } else {
            setChangeEmail('')
            setChangeEmailConfirm('')
        }
        setShowEmailPopup(false)
    }*/

    return(
        <div 
            className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        >
            <div className='settings-container'>
                {/*changedEmail ?
                    <Navigate to="/confirm_email" />
                :
                    null
                */}
                {//popup for changing username
                (showUsernamePopup)?
                    <div className='popup-overlay' onClick={(event) => {
                        if (event.target !== event.currentTarget) {
                            return false;
                        }
                        setShowUsernamePopup(false)}}>
                            <div className='unlock-prompt' style={{backgroundColor: userInfo.color_scheme.main_accent}}>
                            <p>Change username to <b>{inputUsername}</b>?</p>
                            <button className='auth-button' onClick={() => changeUsername() }
                                style={{backgroundColor: userInfo.color_scheme.secondary_accent}}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                :
                    null 
                }
                {(showDeletePopup)?
                    <div className='popup-overlay' onClick={(event) => {
                        if (event.target !== event.currentTarget) {
                            return false;
                        }
                        setShowDeletePopup(false)}}>
                            <div className='unlock-prompt' style={{backgroundColor: userInfo.color_scheme.main_accent}}>
                            <p><b>Delete your account? </b></p>
                            <p>This action cannot be undone</p>
                            <button className='auth-button' 
                                onClick={() => 
                                    sendDeletionRequest()
                                }
                                style={{backgroundColor: userInfo.color_scheme.secondary_accent}}
                            >
                                Delete my account
                            </button>
                        </div>
                    </div>
                :
                    null 
                }
                {(showPasswordPopup)? //popup for changing password
                    <div className='popup-overlay' onClick={(event) => {
                        if (event.target !== event.currentTarget) {
                            return false;
                        }
                        setShowPasswordPopup(false)}}>
                            <div className='unlock-prompt'>
                            <p>Update Password?</p>
                            <button className='auth-button' onClick={() => changePasswordSupabase() }>
                                Confirm
                            </button>
                        </div>
                    </div>
                :
                    null 
                }

                {/*CHANGE USERNAME ------------------------------------*/}
                <b className='settings-header'>Change Username</b>
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <input 
                        className='signup-input username-input'
                        placeholder='username' 
                        value={inputUsername}
                        onChange={(e) => {
                            setInputUsername(e.target.value.replace(/[^a-zA-Z0-9_]/, ''))
                        }}
                        pattern='[a-zA-Z0-9_]*'
                        maxLength='12'
                    ></input>
                    <div className='username-buttons'>
                        <div 
                            className='validate-username-button' 
                            onClick={() => {validateUsername(true)}}
                            style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                        >Change</div>
                    </div>
                    {(usernameValid)?
                            (usernameValid === 'valid') ?
                                <div className='username-validation valid'>Available</div>
                            : 
                                <div className='username-validation invalid'>Unavailable</div>
                        :
                            <div className='username-validation'/>
                        }
                </div>
                <div className='signup-explain'>Current Username: {userInfo.username}</div>
                <div className='signup-explain'>Username must be contain between 4 and 12 characters</div>

                {/*SET US STATE ---------------------------------------------------------------- */}
                <b className='settings-header'>Set Location</b>
                {(userInfo)? 
                    <div className='settings-prompt-row'>
                        <div className='entry-prompt'> US State:</div>
                        <select className='signup-input' value={usState}  onChange={(e) => {
                            changeUSState(setUSState, e.target.value)
                        }}>
                            {Object.keys(state_name_mapping).map((state_code) => {
                                return(
                                    <option value={state_code} key={state_code} >{state_name_mapping[state_code]}</option>
                                )
                            })}
                        </select>
                    </div>
                :
                    null
                }

                {/*CHANGE COLOR SCHEME ------------------------------------------*/}
                <b className='settings-header'>Change Color Scheme</b>
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <div className='entry-prompt'>Base Color:</div>
                    <input 
                        maxLength='7'
                        className='signup-input'
                        value={unsavedColorScheme.base_color}
                        onChange={(e) => setUnsavedColorScheme((previous) => ({...previous, base_color:e.target.value.replace(/[^a-fA-F0-9#]/, '')}))}
                    ></input>
                </div>
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <div className='entry-prompt'>Main Accent:</div>
                    <input 
                        maxLength='7'
                        className='signup-input'
                        value={unsavedColorScheme.main_accent}
                        onChange={(e) => setUnsavedColorScheme((previous) => ({...previous, main_accent:e.target.value.replace(/[^a-fA-F0-9#]/, '')}))}
                    ></input>
                </div>
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <div className='entry-prompt'>Secondary Accent:</div>
                    <input 
                        maxLength='7'
                        className='signup-input'
                        value={unsavedColorScheme.secondary_accent}
                        onChange={(e) => setUnsavedColorScheme((previous) => ({...previous, secondary_accent:e.target.value.replace(/[^a-fA-F0-9#]/, '')}))}
                    ></input>
                </div>
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <div className='entry-prompt'>Background Color 1:</div>
                    <input 
                        maxLength='7'
                        className='signup-input'
                        value={unsavedColorScheme.background_color_1}
                        onChange={(e) => setUnsavedColorScheme((previous) => ({...previous, background_color_1:e.target.value.replace(/[^a-fA-F0-9#]/, '')}))}
                    ></input>
                </div>
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <div className='entry-prompt'>Background Color 2:</div>
                    <input 
                        maxLength='7'
                        className='signup-input'
                        pattern='[#fF0-9]*'
                        value={unsavedColorScheme.background_color_2}
                        onChange={(e) => setUnsavedColorScheme((previous) => ({...previous, background_color_2:e.target.value.replace(/[^a-fA-F0-9#]/, '')}))}
                    ></input>
                </div>
                
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <div className='entry-prompt'>Change to Prebuilt:</div>
                    <select className='signup-input' 
                        defaultValue={'None'} 
                        onChange={(e) => {
                            console.log(e.target.value)
                            if (e.target.value !== 'none') {
                                setUnsavedColorScheme(built_color_schemes[e.target.value])
                            }
                        }}
                    >
                        {Object.keys(built_color_schemes).map((scheme_key) => {
                            return(
                                <option value={scheme_key} key={scheme_key} >{built_color_schemes[scheme_key].name}</option>
                            )
                        })}
                    </select>
                </div>
                <div 
                    style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                    className='change-password-button' 
                    onClick= {saveColorScheme}
                >Change</div>
                <div className='signup-explain'>Color values are defined in six digit <b><Link className='link' 
                    to='https://en.wikipedia.org/wiki/Web_colors' target="_blank" rel="noopener noreferrer">hexidecimal codes</Link></b>
                </div>


                {/*PUSH NOTIFICATIONS----------------------------------*/}
                {(userInfo.device)?<b className='settings-header'>Push Notifications</b> : null}
                {(userInfo.device)? //push notification settings should only appear on android
                    <div> 
                        <div className='prompt-row' >
                            <input type="checkbox" checked={userInfo.device.subject_notifications} className='clickable'
                            onChange={
                                () => {
                                    updatePushNotificationSettings({
                                        req_fcm_token:userInfo.device.fcm_token,
                                        req_mention_notifications:userInfo.device.mention_notifications,
                                        req_comment_notifications:userInfo.device.comment_notifications,
                                        req_subject_notifications:   !userInfo.device.subject_notifications
                                    })
                                    setUserInfo(userInfo => ({...userInfo, device:{
                                        fcm_token:userInfo.device.fcm_token,
                                        mention_notifications:userInfo.device.mention_notifications,
                                        comment_notifications:userInfo.device.comment_notifications,
                                        subject_notifications:   !userInfo.device.subject_notifications,
                                        swipe_left: userInfo.device.swipe_left,
                                        swipe_right: userInfo.device.swipe_right
                                    }}))
                                }
                            }/>
                            <div className='settings-check-text'>Daily Subjects</div>
                        </div>
                        <div className='prompt-row' >
                            <input type="checkbox" checked={userInfo.device.comment_notifications} className='clickable'
                            onChange={
                                () => {
                                    updatePushNotificationSettings({
                                        req_fcm_token:userInfo.device.fcm_token,
                                        req_mention_notifications:userInfo.device.mention_notifications,
                                        req_comment_notifications:   !userInfo.device.comment_notifications,
                                        req_subject_notifications:userInfo.device.subject_notifications
                                    })
                                    setUserInfo(userInfo => ({...userInfo, device:{
                                        fcm_token:userInfo.device.fcm_token,
                                        mention_notifications:userInfo.device.mention_notifications,
                                        comment_notifications:   !userInfo.device.comment_notifications,
                                        subject_notifications:userInfo.device.subject_notifications,
                                        swipe_left: userInfo.device.swipe_left,
                                        swipe_right: userInfo.device.swipe_right
                                    }}))
                                }
                            }/>
                            <div className='settings-check-text'>Comments</div>
                        </div>
                        <div className='prompt-row' >
                            <input type="checkbox" checked={userInfo.device.mention_notifications} className='clickable'
                            onChange={
                                () => {
                                    updatePushNotificationSettings({
                                        req_fcm_token:userInfo.device.fcm_token,
                                        req_mention_notifications:   !userInfo.device.mention_notifications,
                                        req_comment_notifications:userInfo.device.comment_notifications,
                                        req_subject_notifications:userInfo.device.subject_notifications
                                    })
                                    setUserInfo(userInfo => ({...userInfo, device:{
                                        fcm_token:userInfo.device.fcm_token,
                                        mention_notifications:   !userInfo.device.mention_notifications,
                                        comment_notifications:userInfo.device.comment_notifications,
                                        subject_notifications:userInfo.device.subject_notifications,
                                        swipe_left: userInfo.device.swipe_left,
                                        swipe_right: userInfo.device.swipe_right
                                    }}))
                                }
                            }/>
                            <div className='settings-check-text'>Mentions</div>
                        </div>
                    </div>
                :
                    null
                }

                {/*SWIPE ACTIONS-----------------------------------------*/}
                {(userInfo.device)?<b className='settings-header'>Swipe Actions</b> : null}
                {(userInfo.device)? //push notification settings should only appear on android
                    <div> 
                        <div className='settings-prompt-row'>
                            <div className='entry-prompt'>Swipe Left:</div>

                            <select className='signup-input' value={userInfo.device.swipe_left}  onChange={(e) => {
                                updateSwipeSettings({
                                    req_fcm_token:userInfo.device.fcm_token,
                                    req_swipe_left: e.target.value,
                                    req_swipe_right: userInfo.device.swipe_right
                                })
                                setUserInfo(userInfo => ({...userInfo, device:{
                                    fcm_token:userInfo.device.fcm_token,
                                    mention_notifications:userInfo.device.mention_notifications,
                                    comment_notifications:userInfo.device.comment_notifications,
                                    subject_notifications:userInfo.device.subject_notifications,
                                    swipe_left: e.target.value,
                                    swipe_right: userInfo.device.swipe_right
                                }}))
                            }}>
                                <option value='go_back'>Go Back</option>
                                <option value='open_menu'>Open Menu</option>
                                <option value='none'>None</option>
                            </select>
                        </div>
                        <div className='settings-prompt-row'>
                            <div className='entry-prompt'>Swipe Right:</div>

                            <select className='signup-input' value={userInfo.device.swipe_right}  onChange={(e) => {
                                updateSwipeSettings({
                                    req_fcm_token:userInfo.device.fcm_token,
                                    req_swipe_left: userInfo.device.swipe_left,
                                    req_swipe_right: e.target.value
                                })
                                setUserInfo(userInfo => ({...userInfo, device:{
                                    fcm_token:userInfo.device.fcm_token,
                                    mention_notifications:userInfo.device.mention_notifications,
                                    comment_notifications:userInfo.device.comment_notifications,
                                    subject_notifications:userInfo.device.subject_notifications,
                                    swipe_left: userInfo.device.swipe_left,
                                    swipe_right: e.target.value
                                }}))
                            }}>
                                <option value='go_back'>Go Back</option>
                                <option value='open_menu'>Open Menu</option>
                                <option value='none'>None</option>
                            </select>
                        </div>
                        {/*<div className='prompt-row' >
                            <div className='settings-check-text'>Swipe Right</div>
                            <input type="checkbox" checked={userInfo.device.comment_notifications} className='clickable'
                            onChange={
                                () => {
                                    updatePushNotificationSettings({
                                        req_fcm_token:userInfo.device.fcm_token,
                                        req_mention_notifications:userInfo.device.mention_notifications,
                                        req_comment_notifications:   !userInfo.device.comment_notifications,
                                    })
                                    setUserInfo(userInfo => ({...userInfo, device:{
                                        fcm_token:userInfo.device.fcm_token,
                                        mention_notifications:userInfo.device.mention_notifications,
                                        comment_notifications:   !userInfo.device.comment_notifications,
                                        subject_notifications:userInfo.device.subject_notifications,
                                        swipe_left: userInfo.device.swipe_left,
                                        swipe_right: userInfo.device.swipe_right
                                    }}))
                                }
                            }/>
                        </div>*/}
                    </div>
                :
                    null
                }

                {/*CHANGE PASSWORD------------------------------------------*/}
                <b className='settings-header'>Change Password</b>
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <div className='entry-prompt'>New Password:</div>
                    <input 
                        className='signup-input'
                        type='password' 
                        placeholder='password' 
                        value={changePassword}
                        onChange={(e) => setChangePassword(e.target.value)}>
                    </input>
                </div>
                <div className={'settings-prompt-row'.concat((fullSize) ? '' : ' settings-prompt-row-compressed')}>
                    <div className='entry-prompt'>Confirm Password:</div>
                    <input 
                        className='signup-input'
                        type='password' 
                        placeholder='confirm password' 
                        value={changePasswordConfirm}
                        onChange={(e) => setChangePasswordConfirm(e.target.value)}
                    ></input>
                </div>
                <div 
                    style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                    className='change-password-button' 
                    onClick={() => validatePassword()}
                >Change</div>
                <div className='signup-explain'>Password must contain at least 8 characters, a lowercase letter,an uppercase letter
                , a number, and a special character </div>
                <div className='signin-error'>
                    {passwordError !== '' ?
                        <div>{passwordError}</div>
                        :
                        <div></div>
                    }
                </div>
                
                {/*DELETE ACCOUNT ----------------------------------------*/}
                <b className='settings-header'>Delete Account</b>
                <div 
                    style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                    className='change-password-button' 
                    onClick= {() => {
                        setShowDeletePopup(true)
                    }}
                >Delete Account</div>

                <p></p>
                

                {//selected={(userInfo && userInfo.us_state === state_code) ? 'selected' : ''}
                /*<b className='settings-header'>Change Email</b>
                <div className='settings-prompt-row'>
                    <div className='entry-prompt'>New Email:</div>
                    <input 
                        className='signup-input'
                        type='email' 
                        placeholder='email@example.com' 
                        value={changeEmail}
                        onChange={(e) => setChangeEmail(e.target.value)}>
                    </input>
                </div>
                <div className='settings-prompt-row'>
                    <div className='entry-prompt'>Confirm Email:</div>
                    <input 
                        className='signup-input'
                        type='email' 
                        placeholder='email@example.com' 
                        value={changeEmailConfirm}
                        onChange={(e) => setChangeEmailConfirm(e.target.value)}
                    ></input>
                </div>
                <button className='change-password-button' onClick={() => validateEmail()}>Change</button>
                <div className='signup-explain'>Current email: {authEmail}</div>
                <div className='signin-error'>
                    {emailError !== '' ?
                        <div>{emailError}</div>
                        :
                        <div></div>
                    }
                </div>*/}
                
            </div>
        </div>
    )
}

export {SettingsPage}