import React from 'react';
import {Link} from 'react-router-dom';

import { ReviewTile } from '../components/review_tile.jsx';
import { supabase } from '../components/supabase_auth_initialize.js';
import { colorFromAvg } from '../components/map.jsx';

import starFull from '../images/star-full.svg';
import refresh from '../images/refresh.svg';

async function refreshTodaysRating({allSubjects, setAllSubjects}) {
    console.log('getting todays subject rating')

    const { data:rating, error:error } = await supabase.rpc('get_subject_rating', {req_url_name:allSubjects.subjects[allSubjects.today].url_name})
    //check if there was an error
    if (error) {
      console.log(error)
    } else if (rating){ //if not get together the stuff for the page
        const today_updated = {...allSubjects.subjects[allSubjects.today], average_rating:rating}
        allSubjects.subjects[allSubjects.today] = today_updated
        setAllSubjects({...allSubjects, updated_at:new Date()})
    }
}

function RandomTable({getRandomTable, randomTableData, userInfo, fullSize, allSubjects}) {
    const random_table_data = (randomTableData.length > 0) ? randomTableData : Array(10).fill({title:''})
    const [sort, setSort] = React.useState('rating')

    //get bounding parameters for setting color
    const max_avg = Math.max(...randomTableData.map(a => a.average_rating))
    const min_avg = Math.min(...randomTableData.map(a => a.average_rating))
    const max_count = Math.max(...randomTableData.map(a => a.review_count))
    const min_count = Math.min(...randomTableData.map(a => a.review_count))

    function sortRating(a,b) {
        const modifier = (sort !== 'rating') ? 1 : -1
        if (a.average_rating < b.average_rating)
            return(1 * modifier);
        if (a.average_rating > b.average_rating)
            return(-1 * modifier);
        return 0;
    }

    function sortCount(a,b) {
        const modifier = (sort !== 'count') ? 1 : -1
        if (a.review_count < b.review_count)
            return(1 * modifier);
        if (a.review_count > b.review_count)
            return(-1 * modifier);
        return 0;
    }

    function sortAlphabetical(a,b) {
        const modifier = (sort !== 'name') ? 1 : -1
        if (a.title < b.title)
            return(1 * modifier);
        if (a.title > b.title)
            return(-1 * modifier);
        return 0;
    }

    return(
        <div className='random-subjects-table' style={{backgroundColor:userInfo.color_scheme.main_accent}}>
            <div className='random-table-title'>
                <b>10 Random Subjects</b>
                <img 
                    src={refresh} 
                    alt='refresh' 
                    className='random-table-refresh clickable'
                    onClick={getRandomTable}
                />
            </div>
            <div className='subject-table-header-row'>
                <div 
                    className={'header-cell subject-col-random'}
                    onClick={() => {
                        randomTableData.sort(sortAlphabetical)
                        setSort(sort !== 'name' ? 'name' : 'name-reversed')
                    }}
                >
                    <b>Subject</b>
                </div>
                <div 
                    className={'header-cell avg-col-random'}
                    onClick={() => {
                        randomTableData.sort(sortRating)
                        setSort(sort !== 'rating' ? 'rating' : 'rating-reversed')
                    }}
                >
                    <b>Rating</b>
                </div>
                <div 
                    className={'header-cell count-col-random'}
                    onClick={() => {
                        randomTableData.sort(sortCount)
                        setSort(sort !== 'count' ? 'count' : 'count-reversed')
                    }}
                >
                    <b>Takes</b>
                </div>
            </div>
            
            {random_table_data.map((subject, index) => {
                return(
                    <div className={'subject-table-row'} key={index}>
                        <p className={'subject-col-random cell-random'.concat((fullSize) ? ' ' : ' subject-col-compressed').concat((index % 2) === 0? '' : ' blue-row')}>
                            <Link className='link' to={'/subject/'.concat(subject.url_name)}>
                                {subject.title.concat((subject.review_status === 'submitted') ? ' ✓' : '')}
                            </Link>
                        </p>
                        <p 
                            className={'avg-col-random cell-random'.concat((fullSize) ? ' ' : ' avg-col-compressed').concat((index % 2) === 0 && !subject.average_rating? '' : ' blue-row')}
                            style={{backgroundColor:subject.title? colorFromAvg(subject.average_rating, max_avg, min_avg, 0.5) : null}}
                        >
                            {(subject.average_rating) ? <img src={starFull} alt='label-star-full' className='subject-table-star'/> : null}
                            {(subject.average_rating)? subject.average_rating.toFixed(2) : '-' }
                        </p>
                        <p 
                            className={'count-col-random cell-random'.concat((fullSize) ? ' ' : ' count-col-compressed').concat((index % 2) === 0 && !subject.average_rating? '' : ' blue-row')}
                            style={{backgroundColor: subject.title? colorFromAvg(subject.review_count, max_count, min_count, 0.5) : null}}
                        >
                            {(subject.review_count)? subject.review_count : '-'}</p>
                    </div>
                )
            })}
        </div>
    )
}

function ReviewTileList({fullSize, userInfo, bestReviewList, allSubjects, setBestReviewList}) {
    return (
        <div className={'review-list'.concat((fullSize) ? '' : ' review-list-compressed')}>
            <div 
                className='best-takes-title'
                style = {{backgroundColor:userInfo.color_scheme.base_color}}
            ><b>10 Best Takes from the Last Week</b></div>
            {(bestReviewList.length > 0) ? 
                bestReviewList.map((review, index) => {
                    return(
                        <ReviewTile 
                            review={review} 
                            fullSize={fullSize} 
                            max_width={'500px'}
                            allSubjects={allSubjects}
                            updateFunc= {(review) => {
                                bestReviewList[index] = {...bestReviewList[index], 
                                    like_count:review.like_count,
                                    dislike_count:review.dislike_count,
                                    vote:review.vote,
                                    show_comments:review.show_comments,
                                    comments_list:review.comments_list,
                                    comment_count:review.comment_count,
                                    following:review.following,
                                    hidden_by_user:review.hidden_by_user,
                                }
                                setBestReviewList([...bestReviewList])
                            }}
                            userInfo={userInfo}
                            key={review.url_name.concat(review.username)}
                        />
                    )
                })
            :
                null
            }
        </div>
    )
}

function HomePage({fullSize, setPageInfo, userInfo, allSubjects, setAllSubjects, bestReviewList, setBestReviewList, getBestReviewsLastWeek}) {

    const [randomTableData, setRandomTableData] = React.useState([])
        
    React.useEffect(() => {
        setPageInfo({header_text:null, show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
        getBestReviewsLastWeek(setBestReviewList)
    }, []);

    //when allSubjects loads in generate the random table
    React.useEffect(() => {
        if (allSubjects) {
            setRandomTableData([...allSubjects.subjects_order]
                .filter((subject) => subject !== allSubjects.today)
                .sort(() => 0.5 - Math.random())
                .slice(0, 10)
                .map((subject) => allSubjects.subjects[subject])
                .sort((a, b) => b.average_rating - a.average_rating)
            )
        }
    }, [allSubjects])

    //every time the page loads if its been more than 0.5min since allSubjects was updated, get the review_count
    React.useEffect(() => {
        //if its been at least 0.5 min since the data was updated
        if (allSubjects.today && allSubjects.subjects[allSubjects.today].review_status === 'submitted' && (Math.abs(new Date() - allSubjects.updated_at)/6000) > 0.5) {
            refreshTodaysRating({allSubjects:allSubjects, setAllSubjects:setAllSubjects})
        }
    }, [])

    return (
        <div className={(fullSize) ? 'center-content center-content-subject' : 'center-content'}
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
            + userInfo.color_scheme.background_color_2 + ')'}}
        >
            <div className={'home-tiles-left'.concat(fullSize? '' : ' home-tiles-left-compressed')}>
                {userInfo.username? 
                        null
                    :
                        <div 
                            className={'welcome'.concat((fullSize? '' : ' welcome-compressed'))}
                            style = {{backgroundColor:userInfo.color_scheme.base_color}}
                        >
                        Welcome to <b>drop a take</b>! Every day a new subject is released for you to drop a take on
                    </div>    
                }
               
                <div 
                    className={'welcome'.concat((fullSize? '' : ' welcome-compressed'))}
                    style = {{backgroundColor:userInfo.color_scheme.base_color}}
                >
                    <div>
                        Today's Subject: &nbsp;
                        {allSubjects.today?
                            <Link 
                                className='yesterdays-subject-title header-font'
                                to={`/subject/${allSubjects.subjects[allSubjects.today].url_name}`}
                                /*style = {allSubjects? 
                                    {backgroundColor:userInfo.color_scheme.base_color}
                                :
                                    {
                                        backgroundColor:userInfo.color_scheme.base_color,
                                        animationName: 'pulseSubjectTitle',
                                        animationDuration:'5s',
                                        animationIterationCount: 'infinite',
                                        padding:'0px 5px 0px 5px',
                                        borderRadius:'5px',
                                    }
                                }*/
                            >
                                {allSubjects.subjects[allSubjects.today].review_status === 'submitted'? <img src={starFull} alt='label-star-full' className='distribution-star'/> : null}
                                {allSubjects.subjects[allSubjects.today].review_status === 'submitted'? String(allSubjects.subjects[allSubjects.today].average_rating.toFixed(2)) + ' ': null}
                                {allSubjects.today}
                            </Link>
                        : '' }
                    </div>
                    <div className='welcome-spacer'/>
                    <div>
                        Yesterday's Subject: &nbsp;
                        {allSubjects.yesterday?
                            <Link className='yesterdays-subject-title header-font' to={`/subject/${allSubjects.subjects[allSubjects.yesterday].url_name}`}>
                                <img src={starFull} alt='label-star-full' className='distribution-star'/>
                                {allSubjects.subjects[allSubjects.yesterday].average_rating.toFixed(2)}&nbsp;
                                {allSubjects.yesterday}
                            </Link>
                        : '' }
                    </div>
                </div>
                <RandomTable 
                    userInfo={userInfo} 
                    getRandomTable={() => {
                        setRandomTableData([...allSubjects.subjects_order]
                            .filter((subject) => subject !== allSubjects.today)
                            .sort(() => 0.5 - Math.random())
                            .slice(0, 10)
                            .map((subject) => allSubjects.subjects[subject])
                            .sort((a, b) => b.average_rating - a.average_rating)  
                        )
                    }} 
                    randomTableData={randomTableData} 
                    allSubjects={allSubjects}
                    fullSize={fullSize}
                />
            </div>
            <div className={fullSize ? 'reviews-and-sort-container' : 'review-list-compressed'}>
                <ReviewTileList userInfo={userInfo} allSubjects={allSubjects} fullSize={fullSize} bestReviewList={bestReviewList} setBestReviewList={setBestReviewList}/> 
            </div>
        </div>
    ) 
}

export {HomePage}