import React from 'react';
import {Link} from 'react-router-dom';

import {supabase} from '../components/supabase_auth_initialize.js';
import { ReviewTile } from '../components/review_tile.jsx';
import {FollowButton} from '../components/follow_button.jsx';
import {DateStringFormatted} from '../components/submitted_review.jsx';

import searchIcon from '../images/search.svg';
import starFull from '../images/star-full.svg';

async function getSearchResults({results, searchQuery, setResults, reset, setLoading, setHasMore, searchType}) {
    //read in all reviews
    console.log('getting ' + searchType + ' results')

    const already_have_ids = []
    if (!reset) {
        if (searchType === 'user') {
            results.forEach((user) => {already_have_ids.push(user.user_id)})
        } else if (searchType === 'subject') {
            results.forEach((subject) => {already_have_ids.push(subject.subject_id)})
        } else if (searchType === 'review') {
            results.forEach((review) => {already_have_ids.push(review.review_id)})
        }
    }   

    const { data:search_results, error:results_error } = await supabase.rpc('search_' + searchType, {
        already_have_ids: already_have_ids,
        req_text: searchQuery.trim()
    })

    if (results_error) {
        console.log(results_error)
    } else {

        if (search_results.length < 20) {
            setHasMore(false)
        }

        if (searchType === 'review') {
            search_results.forEach((review) => {
                review.show_comments = false;
                review.comment_count = (review.comment_count) ? review.comment_count : 0
                review.comments_list = [];
            })
        }

        if (reset) {
            setResults(search_results)
        } else {
            search_results.forEach((result) =>{
                if (!already_have_ids.includes(result.user_id)) {
                    results.push(result)
                }
            })
            setResults([...results])
        }
        setLoading(false)
    }
}

function UserSearchResult({user, fullSize, userInfo, index, results, setResults, add_ref}) {

    function updateFollowing(result) {
        results[index] = {...results[index],
            following:result.following}
        setResults(results)
    }
    return(
        <div ref={add_ref} className={'username-container'.concat((fullSize)? '' : ' username-container-compressed')}
            style={{backgroundColor:userInfo.color_scheme.base_color}}
        >
            <Link to={'/user/'+user.username} className='commenter-name light-link clickable'>{user.username}</Link>
            {(user.following === 'same') ? 
                null 
            :
                <FollowButton 
                    userInfo={userInfo}
                    review={user} 
                    setFunc={() => updateFollowing({...user})}
                    dark={true}
                />
            }
        </div>
    )
}

function SubjectSearchResult({subject, userInfo, fullSize, add_ref}) {
    return(
        <div ref={add_ref} className={'summary-details-container'.concat((fullSize)? '' : ' summary-details-container-compressed')}
            style={{backgroundColor:userInfo.color_scheme.base_color}}
        >
            <div className='title-summary-details'>
                <Link className='light-link' to={'/subject/' + subject.url_name}>{subject.title}&nbsp;&nbsp;</Link>
                <img src={starFull} alt='label-star-full' className='distribution-star'/>
                <b>{subject.avg_rating.toFixed(2)}</b>
            </div>
            <div className='summary-row-reference'>
                <div><b>{DateStringFormatted({submitted_date_str:subject.release_date})}</b></div>
            </div>
        </div>
    )
}

function ResultsList({results, searchType, userInfo, fullSize, setResults, allSubjects, add_ref}) {
    return(
        <div className={'review-list'.concat((fullSize) ? '  review-list-padding' : ' review-list-compressed')}>
            <div className='following-list-spacer'></div>
            {results.map((result, index) => {
                if (searchType === 'user') {
                    return(
                        <UserSearchResult 
                            user={result} 
                            index={index} 
                            fullSize={fullSize} 
                            results={results} 
                            setResults={setResults}
                            userInfo={userInfo}
                            key={result.user_id}
                            add_ref={(index+1 === results.length)? add_ref : null}
                        />
                    )
                } else if (searchType === 'subject') {
                    return(
                        <SubjectSearchResult
                            subject={result}
                            userInfo={userInfo}
                            fullSize={fullSize}
                            key={result.subject_id}
                            add_ref={(index+1 === results.length)? add_ref : null}
                        />
                    )
                } else if (searchType === 'review') {
                    return(
                        <ReviewTile 
                            userInfo={userInfo}
                            review={result}
                            allSubjects={allSubjects}
                            add_ref={(index+1 === results.length)? add_ref : null}
                            fullSize={fullSize}
                            key={result.review_id}
                            screen_width_to_compress='700'
                            updateFunc={(review) => {
                                results[index] = {...results[index], 
                                    like_count:review.like_count,
                                    dislike_count:review.dislike_count,
                                    vote:review.vote,
                                    show_comments:review.show_comments,
                                    comments_list:review.comments_list,
                                    comment_count:review.comment_count,
                                    following:review.following,
                                    hidden_by_user:review.hidden_by_user
                                }
                                setResults([...results])
                            }}
                        />
                    )
                }
            })}
        </div>
    )
}

function SearchPage({fullSize, userInfo, allSubjects, setPageInfo}) {

    const [results, setResults] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [searchType, setSearchType] = React.useState('user')
    const [searchQuery, setSearchQuery] = React.useState('')
    const [hasMore, setHasMore] = React.useState(true)

    React.useEffect(() => {
        setPageInfo({header_text:'Search', show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    // stuff for infinite scrolling
    const observer = React.useRef()
    const lastResult = React.useCallback((node) => {
        //once its loaded
        if (!loading) {
            if (observer.current) {observer.current.disconnect()}
            observer.current = new IntersectionObserver(entries => {
                //check if the last review is on the screen
                if (entries[0].isIntersecting && hasMore) { 
                    console.log('intersecting ' + hasMore)
                    setLoading(true)
                    getSearchResults({
                        results:results, 
                        searchType:searchType,
                        searchQuery:searchQuery, 
                        setResults:setResults, 
                        setLoading:setLoading, 
                        setHasMore:setHasMore,
                    })  
                }
            })
            if (node) {observer.current.observe(node)}
        }
    }, [loading, searchType, results, hasMore, searchQuery])

    return (
        <div className={(fullSize) ? 'center-content center-content-subject' : 'center-content'}
        style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        >  
            
            <div className={'search-tiles-container'.concat((fullSize)? '' : ' search-tiles-container-compressed')}>
                <div 
                    className={'search-container'.concat((fullSize)? '' : ' search-container-compressed')}
                    style={{backgroundColor:userInfo.color_scheme.main_accent}}
                >
                    <div className='search-row'>
                        <input 
                            className='search-input'
                            style={{border:'2px solid ' + userInfo.color_scheme.secondary_accent}}
                            placeholder='Search' 
                            value={searchQuery}
                            onChange={(e) => {setSearchQuery(e.target.value)}}
                            //pattern='[a-zA-Z0-9_]*'
                            maxLength='100'
                            onKeyDown={(event) => {if (event.key === 'Enter') {
                                if (searchQuery.length > 0) {
                                    setLoading(true)
                                    setHasMore(true)
                                    getSearchResults({
                                        results:results, 
                                        searchType:searchType,
                                        searchQuery:searchQuery, 
                                        setResults:setResults, 
                                        reset:true, 
                                        setLoading:setLoading,
                                        setHasMore:setHasMore
                                    })
                                }
                            }}}
                        ></input>

                        <img 
                            style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                            src={searchIcon} 
                            className='search-button' 
                            alt='search-icon' 
                            onClick={() => {
                                if (searchQuery.length > 0) {
                                    setLoading(true)
                                    setHasMore(true)
                                    getSearchResults({
                                        results:results, 
                                        searchType:searchType,
                                        searchQuery:searchQuery, 
                                        setResults:setResults, 
                                        reset:true, 
                                        setLoading:setLoading,
                                        setHasMore:setHasMore
                                    })
                                }
                            }}
                        />
                    </div>
                    <div className='search-row'>
                        <div 
                            className='search-option'
                            style={{backgroundColor:(searchType === 'user') ? userInfo.color_scheme.secondary_accent : null}}
                            onClick={() => {
                                setResults([])
                                setHasMore(true)
                                setSearchType('user')
                            }}
                        >
                            <img className='search-icon'
                                src={searchIcon} alt='user-icon'/>  
                            <div className='sort-text'><b>User</b></div>
                        </div>
                        <div 
                            className='search-option'
                            style={{backgroundColor:(searchType === 'subject') ? userInfo.color_scheme.secondary_accent : null}}
                            onClick={() => {
                                setResults([])
                                setHasMore(true)
                                setSearchType('subject')
                            }}
                        >
                            <img className='search-icon'
                                src={searchIcon} alt='user-icon'/>  
                            <div className='sort-text'><b>Subject</b></div>
                        </div>
                        <div 
                            className='search-option'
                            style={{backgroundColor:(searchType === 'review') ? userInfo.color_scheme.secondary_accent : null}}
                            onClick={() => {
                                setResults([])
                                setHasMore(true)
                                setSearchType('review')
                            }}
                        >
                            <img className='search-icon'
                                src={searchIcon} alt='user-icon'/>  
                            <div className='sort-text'><b>Take</b></div>
                        </div>
                    </div>
                </div>   
            </div>
            <ResultsList 
                results={results} 
                searchType={searchType} 
                allSubjects={allSubjects}
                fullSize={fullSize} 
                setResults={setResults} 
                add_ref={lastResult}
                userInfo={userInfo}
            />
        </div>

    ) 
}

export {SearchPage}