import {supabase} from './supabase_auth_initialize.js'
import { cleanSubmissionWithMentions } from './submitted_review.jsx';

async function saveReview({review_info, setSavedFunc}) {

    const {data:saved_response, error:saved_error} = await supabase.rpc('save_user_review',  { 
        req_subject_id: review_info.subject_id,
        req_rating: review_info.rating,
        req_text: review_info.review_text,
     })

    if (saved_error) {
        console.log(saved_error)
    } else if (saved_response === 'saved') {
        console.log(saved_response)
        setSavedFunc({...review_info, review_status: 'saved'});
      
    } else {
        console.log(saved_response)
    }
}

async function submitReview({ review_info,  setReviewFunc, setUserFunc, followupFunc=null}) {
    const output_text = cleanSubmissionWithMentions(review_info.review_text).replace(/\n/g, '<br/>')

    const {data:submitted_response, error:submitted_error} = await supabase.rpc('submit_user_review_2',  { 
        req_subject_id: review_info.subject_id,
        req_rating: review_info.rating,
        req_text: output_text,
     })
    if (submitted_error) {
        console.log(submitted_error)
    } else if (submitted_response !== 'no_changes_made') {
        console.log(submitted_response)

        setUserFunc(userInfo => ({...userInfo, tokens: userInfo.tokens + 1}));

        setReviewFunc({...review_info,
            review_status : 'submitted',
            review_id : parseInt(submitted_response),
            submitted_at : new Date().toISOString(),
            like_count : 0,
            dislike_count : 0,
            comments_list: []
        });

        if (followupFunc) {
            followupFunc()
        }
   
    } else {
        console.log(submitted_response)
    }
}

async function unlockReview({review_info, setReviewFunc, setUserFunc, setPopupChoice=false}) {

    const {data:unlocked_response, error:unlocked_error} = await supabase.rpc('unlock_review',  {req_subject_id: review_info.subject_id})
    if (unlocked_error) {
        console.log(unlocked_error)
    } else if (('unlocked_via_update', 'unlocked_via_insert').includes(unlocked_response)) {
        
        //user_info.tokens = user_info.tokens - 5
        setUserFunc(userInfo => ({...userInfo, tokens: userInfo.tokens - 5}))

        setReviewFunc({...review_info,
            review_status : 'unlocked',
            unlocked_at : true,
            rating : '',
            review_text: '',
        });

        if (setPopupChoice) {
            setPopupChoice(null)
        }

    } else {
        console.log(unlocked_response)
    }
}


export {saveReview, submitReview, unlockReview}