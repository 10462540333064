import React from 'react';
import {Link} from 'react-router-dom';

import { HiddenReview, SubmittedReview, CommentSection } from './submitted_review';
import { FollowButton } from './follow_button';

function ReviewTile({
    review, 
    fullSize, 
    add_ref=null, 
    max_width='700px', 
    screen_width_to_compress='950', 
    updateFunc,
    allSubjects,
    userInfo,
    subject=null
}) {

    return (
        <div ref={add_ref} >
            {(review.vote === 'irrelevant') ?
                <HiddenReview review={review} updateFunc={updateFunc} />
            : (userInfo)?
                <div className='review-tile' style={{maxWidth:max_width, backgroundColor:userInfo.color_scheme.base_color}}>
                    <div className={'review-tile-title-container'.concat(fullSize? '' :  ' review-tile-title-container-compressed')}>
                        {(review.comment_text && review.mentioned_username)? //mentioned a user in a comment
                            <p className='review-tile-title-text'>
                                <Link className='review-tile-title' to={'/user/'.concat(review.commenter_username)}> {review.commenter_username} </Link>
                                <span className='on-for-title'>&nbsp;mentioned&nbsp;</span>
                                <Link className='review-tile-title' to={'/user/'.concat(review.mentioned_username)}> {review.mentioned_username} </Link>
                                <span className='on-for-title'>&nbsp;in their comment on&nbsp;</span>
                                <Link className='review-tile-title' to={'/user/'.concat(review.username)}> {review.username}'s </Link>
                                <span className='on-for-title'>&nbsp;take on&nbsp;</span>
                                <Link  className='review-tile-title' to={'/subject/'.concat(review.url_name)}> {review.title} </Link>
                            </p>
                        :(review.comment_text)? //commented on a take
                            <p className='review-tile-title-text'>
                                <Link className='review-tile-title' to={'/user/'.concat(review.commenter_username)}> {review.commenter_username} </Link>
                                <span className='on-for-title'>&nbsp;commented on&nbsp;</span>
                                <Link className='review-tile-title' to={'/user/'.concat(review.username)}> {review.username}'s </Link>
                                <span className='on-for-title'>&nbsp;take on&nbsp;</span>
                                <Link  className='review-tile-title' to={'/subject/'.concat(review.url_name)}> {review.title} </Link>
                            </p>
                        : (review.mentioned_username)? //compressed mentioned user in their take
                            <p className='review-tile-title-text'>
                                <Link className='review-tile-title' to={'/user/'.concat(review.username)}> {review.username} </Link>
                                <span className='on-for-title'>&nbsp;mentioned&nbsp;</span>
                                <Link className='review-tile-title' to={'/user/'.concat(review.mentioned_username)}> {review.mentioned_username} </Link>
                                <span className='on-for-title'>&nbsp;in their take on&nbsp;</span>
                                <Link  className='review-tile-title' to={'/subject/'.concat(review.url_name)}> {review.title} </Link>
                            </p>
                        : //not including mentions or comments or other nonsense
                            //<div className='review-tile-title-text'>
                            <p className='review-tile-title-text'>
                                {review.username?
                                    <Link className='review-tile-title' to={'/user/'.concat(review.username)}> {review.username} </Link>
                                : null}     

                                {(review.username && review.title)?
                                    <span className='on-for-title'>&nbsp;on&nbsp;</span>
                                : null}

                                {review.title?
                                    <Link  className='review-tile-title' to={'/subject/'.concat(review.url_name)}> {review.title} </Link>
                                : null }         
                            </p>
                        }
                        
                        {//follow container
                        (review.username !== userInfo.username && review.following !== 'same' && review.following !== 'dont_show') ? 
                            <div className='follow-container'>
                                <FollowButton review={review} setFunc={updateFunc} userInfo={userInfo}/> 
                            </div>
                        : null} 
                    </div>    
                    {SubmittedReview({
                        review:review, 
                        updateFunc:updateFunc,
                        screen_width_to_compress:screen_width_to_compress,
                        userInfo:userInfo,
                        allSubjects:allSubjects,
                        subject:review.title? review.title : subject
                    })}
                    {CommentSection({
                        review:review, 
                        allSubjects:allSubjects, 
                        subject:review.title? review.title : subject,
                        light_text:true, 
                        userInfo:userInfo, 
                        updateFunc:updateFunc})}
                </div>
            :
                null
            }
        </div>
    )
}

export {ReviewTile}