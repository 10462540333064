import React from 'react';

import {Navigate} from 'react-router-dom';
import {supabase} from '../components/supabase_auth_initialize.js'

function RecoverPasswordPage({fullSize, userInfo, setPageInfo}){
    
    const [recoveryStatus, setRecoveryStatus] = React.useState('began');
    const [sentEmailAddress, setSentEmailAddress] = React.useState('');

    React.useEffect(() => {
        setPageInfo({header_text:'Recover Password', show_login:true, logout_redirect:false, rating:null, allowSwipe:true})
    }, []);

    //compnonent to be called in for sign in section
    const RecoverPassword = () => {      
        console.log('this is the recover password block') 
        const [recoveryError, setRecoveryError] = React.useState(null);
        const [recoveryEmail, setRecoveryEmail] = React.useState('');

        //lets you sign up
        async function sendRecoveryEmail(e) {
            console.log('toooooootally sent a recovery email')

            e.preventDefault();

            if (recoveryEmail.length === 0) {
                setRecoveryError('Email address is required')
            } else {
                const { error:recovery_error } = await supabase.auth.resetPasswordForEmail(recoveryEmail, {})
                if (recovery_error) {
                    console.log(recovery_error)
                    setRecoveryError(recoveryError.message)
                } else {
                    setRecoveryError('')
                    setRecoveryStatus('sent_email')
                    setSentEmailAddress(recoveryEmail)
                }
                
            }  
        }

        return(
            <form className='signup-block' onSubmit={sendRecoveryEmail}>
                <div className={'prompt-row'.concat((fullSize) ? '' : ' prompt-row-compressed')}>
                    <b className={'entry-prompt'.concat((fullSize) ? '' : ' entry-prompt-compressed')}>Email Address:</b>
                    <input 
                        className='signup-input'
                        type='email'
                        placeholder='email@example.com' 
                        value={recoveryEmail} 
                        onChange={(e) => setRecoveryEmail(e.target.value)}>
                    </input>
                </div>

                <button type='submit' className='auth-button'  style={{backgroundColor:userInfo.color_scheme.secondary_accent}}
                >Send Recovery Email</button>
                <div className='signin-error'>
                    {recoveryError !== '' ?
                        <div>{recoveryError}</div>
                        :
                        <div></div>
                    }
                </div>
            </form>
        )
    }


    //compnonent to be called in for sign in section
    const EnterOtp = () => {
        //set up objects for logging in or signing in
        
        const [OTP, setOTP] = React.useState('');
        const [OTPError, setOTPError] = React.useState(null);

        //lets you sign up
        async function signInOTP(e) {
            e.preventDefault();
            console.log('email entered ' + sentEmailAddress)
            console.log('token entered ' + OTP)

            if (OTP.length < 6) {
                setOTPError('Enter Code')
            } else {
                const {data:otp_response, error:otp_error} = await supabase.auth.verifyOtp({
                    email: sentEmailAddress,
                    token: OTP,
                    type: 'recovery',
                  })
                console.log(otp_response)
                if (otp_error) {
                    console.log(otp_error)
                    setOTPError(otp_error.message)
                } else {
                    console.log('confirmed_otp')
                    setRecoveryStatus('confirmed_otp')
                    setOTPError('')
                }
            }  
        }

        return(    
            <form className='signup-block' onSubmit={(event) => signInOTP(event)}>
                <div className={'prompt-row'.concat((fullSize) ? '' : ' prompt-row-compressed')}>
                    <b className={'entry-prompt'.concat((fullSize) ? '' : ' entry-prompt-compressed')}>Enter Code:</b>
                    <input 
                        className='signup-input'
                        placeholder='Code' 
                        type='text'
                        value={OTP} 
                        maxLength='6'
                        onChange={(e) => setOTP(e.target.value.replace(/\D/g, ''))}>
                    </input>
                </div>
                <div className='signup-explain'>Please check your email your recovery code.</div>
                <div className='signup-explain'>Be sure to check your spam folder if confirmation does not appear.</div>

                <button type='submit' className='auth-button'  style={{backgroundColor:userInfo.color_scheme.secondary_accent}}>Submit</button>
                <div className='signin-error'>
                    {OTPError !== '' ?
                        <div>{OTPError}</div>
                        :
                        <div></div>
                    }
                </div>
            </form>
        )
    }

    return(
        <div 
            className='center-content' 
            style={{backgroundImage: 'linear-gradient(to bottom right, ' + userInfo.color_scheme.background_color_1 + ', '
                + userInfo.color_scheme.background_color_2 + ')'}}
        >
            {(recoveryStatus === 'began')?
                <RecoverPassword/>
            : (recoveryStatus === 'sent_email') ?
                <EnterOtp/>
            : (recoveryStatus === 'confirmed_otp') ?
                <Navigate to='/settings'/>
            : 
                null
            }
        </div>
    )
}

export {RecoverPasswordPage};

